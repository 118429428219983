import React, { Component } from 'react'
import Navbar from '../components/Navbar';
import HomeSlider from '../components/HomeSlider';
import ShopCategory from '../components/shopCategory';
import Footer from '../components/Footer';
import BrandSlider from '../components/BrandSlider';
// import SeasonSale from '../components/SeasonSale';
import ProductCard from '../components/ProductCard';
import { featuredAdvert } from '../components/images';
import CommunityHome from '../components/communityHome';
import HomeBrands from '../components/HomeBrands';
import HiringBanner from '../components/HiringBanner';
import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import SectionTitle from '../components/sectionTitle';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import { mainBannerDress, mainSliderImg } from "../components/images";


export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kaafArrival: [],
            communityData: [],
            brandData: [],
            categoriesData: [],
            bestSeller: [],
            images: []
        }
    }


    componentDidMount = async () => {


        try {

            var data = {
                "jwt": "",
                "all": " "
            }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                redirect: 'follow'
            };
            fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/home_product_lists.php", requestOptions)
                .then(async resp => {
                    const json = await resp.json()
                    console.log("data==>", json)
                    if (resp.status === 200) {
                        this.bannerFun()
                        this.setState({
                            // images: newResult,
                            kaafArrival: json[0].list,
                            communityData: json[1].community,
                            brandData: json[2].featured_brands,
                            categoriesData: json[3].categories,
                            bestSeller: json[4].best_seller,
                        })
                    }
                    else {
                        this.setState({ isLoading: false })
                        // alert("Something went wrong please try again.")
                    }
                }
                )
                .catch(errr => {
                    this.setState({ isLoading: false })
                });




        }
        catch (error) {
            console.log("home_product_lists_catch====>", error);
        }

    }


    bannerFun = () => {
        try {

            var data = {
                "jwt": "",
            }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                redirect: 'follow'
            };
            fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/view_notification.php", requestOptions)
                .then(async resp => {
                    const json = await resp.json()
                    console.log("data==>", json)
                    if (resp.status === 200) {

                        var arr = json.notifications[0].slider_image.map((i) => {
                            return (
                                `https://demo.smileitsolutions.com/kaaf/uploads/${i}`
                            )
                        })


                        this.setState({
                            // images: newResult,
                            notificationData: json.notifications[0].image,
                            images: arr,
                            isLoading: false,
                        })
                    }
                    else {
                        this.setState({ isLoading: false })
                        // alert("Something went wrong please try again.")
                    }
                }
                )
                .catch(errr => {
                    this.setState({ isLoading: false })
                });




        }
        catch (error) {
            console.log("home_product_lists_catch====>", error);
        }
    }





    onPress = (i) => {
        console.log("data==>", i)
        // to="/viewProduct" 
        localStorage.setItem("productData", JSON.stringify(i))
        this.props.history.push('/viewProduct')
    }

    view = (i) => {
   

        if (i == "picks") {
            localStorage.setItem("viewAll", JSON.stringify(i))
            this.props.history.push('/Listing' + "/KAAF_Premium")
        }
        else  if (i == "brand") {
            localStorage.setItem("viewAll", JSON.stringify(i))
            this.props.history.push('/Listing' + "/Brands")
        }
        else if (i == "member") {
            this.props.history.push('/communityList')

        }

    }


    viewBrand=(type,i)=>{
        console.log("data-view=>",type, i)
        if (type == "brand") {
            localStorage.setItem("viewAll", JSON.stringify(i))
            this.props.history.push('/Listing' + `/${i.brand}`)
        }
    }



    onPressBrand = (i) => {
        localStorage.setItem("viewAll", JSON.stringify("picks"))
        this.props.history.push('/Listing')
    }



    latestCollection = () => {
        localStorage.setItem("viewAll", JSON.stringify("best_seller"))
        this.props.history.push('/Listing' + "/Latest_Collection")
    }


    category = () => {

        // localStorage.setItem("viewAll", JSON.stringify("best_seller"))
        // viewSubcategory
        this.props.history.push('/viewcategory')
    }


    press = (i) => {
        // onClick={()=>this.view("member")}
        localStorage.setItem("communityData", JSON.stringify(i))
        // this.props.history.push({'/viewFreelancer'+i.id})
    }

    render() {
        return (
            <div>
                <Navbar />
                {/* <HomeSlider /> */}

                {/* -------------Slider--------------- */}

                <div className="slide-container">
                    <Slide arrows={false} duration={4500}  >
                        {
                            this.state.images.map((i) => {
                                return (
                                    <div className="row align-items-center">
                                        <img className="red_carpet_r" src={i} alt="" />
                                    </div>
                                )
                            })
                        }
                    </Slide>
                </div>


                {/* --------------End Slider-------------------- */}




                {/* <SeasonSale/> */}
                {/* <ProductCard title={"KAAF Picks"} /> */}
                <section className="outfit_collections pt-4 pb-2">

                    <div className="container">

                        <div className="row">
                            <SectionTitle text={"KAAF Premium"} onClick={() => this.view("picks")} />
                            {
                                this.state.kaafArrival.map((i) => {
                                    return (
                                        <div onClick={() => this.onPress(i)} style={{ paddingLeft: "10px", paddingRight: "10px" }} className="col-lg-25 col-md-4 col-sm-6 col-6">

                                            <div className="product_heading">
                                                <h3>
                                                    <a>{i.brand}</a>
                                                </h3>
                                            </div>
                                            <div className="prduct_card">
                                                <a>
                                                    <img src={`https://demo.smileitsolutions.com/kaaf/uploads/product/${i.prod_image[0]}`} alt="" />
                                                </a>
                                                {/* <span className="wishlist_icon"><i className="fa fa-heart" aria-hidden="true"></i></span> */}
                                                <div className="product_price_title">
                                                    <h3>
                                                        <a>{i.descriptions != "" ? i.descriptions : 'New arrival'}</a>
                                                        {/* <a>{i.subcat_name}</a> */}
                                                    </h3>
                                                    <span className="current_price">AED{i.discounted_price}</span>
                                                    {i.sell_price != "" && i.sell_price != null ? <span className="actual_price">AED{i.sell_price}</span> : <span className="actual_price">AED 0.0</span>}
                                                    {i.discount != "" && i.discount != null ? <span className="percent_price">{i.discount}%</span> : null}


                                                    {/* <div class="product_reviews_stars">
                                                        <div className='App'>
                                                            <Rating
                                                                ratingValue={i.ratings}
                                                                readonly={true}
                                                                size={20}
                                                                transition
                                                                allowHalfIcon
                                                            />
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }


                        </div>
                    </div>
                </section>
                {/* <CommunityHome /> */}
                <section className="shop_categories pt-4 pb-4">

                    <div className="container">

                        <div className="row">
                            <SectionTitle text={"KAAF Community"} onClick={() => this.view("member")} />
                            {
                                this.state.communityData.map((i) => {
                                    var comName = i.name.trim().replace(/\s+/g, "_");
                                    // console.log("data->",comName)
                                    return (
                                        <Link to={'/' + comName} className="col-lg-2 col-md-3 col-sm-4 col-4"  >
                                            <div >
                                                <div className="home_community_box">
                                                    <img src={`https://demo.smileitsolutions.com/kaaf/uploads/${i.image}`} alt="" />
                                                    <h3> <a href="/"> {i.name} </a></h3>
                                                </div>
                                            </div>
                                        </Link>

                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
                <div>
                    <div class="">
                        <section className="featured_advertisement pb-3">
                            <a href="/"><img src={featuredAdvert} alt="" /></a>
                        </section>
                    </div>
                </div>
                <section className="shop_categories pt-4 pb-4">
                    <div className="container">
                        <div className="row">
                            <SectionTitle text={"Featured Brands"} onClick={() => this.onPressBrand()} />
                            {
                                this.state.brandData.map((i) => {
                                    return (
                                        <div onClick={() => this.viewBrand("brand",i)}  className="col-lg-2 col-md-3 col-sm-4 col-4">
                                            <div className="home_brands_box">
                                                <img src={`https://demo.smileitsolutions.com/kaaf/uploads/${i.logo}`} alt="" />
                                                {/* <h3> <a href="/"> {i.name} </a></h3> */}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>

                <HiringBanner />
                <section className="shop_categories pt-4 pb-4">
                    <div className="container">
                        <div className="row">
                            <SectionTitle text={"Featured Category"} onClick={() => this.category()} />
                            {
                                this.state.categoriesData.map((i) => {
                                    return (
                                        <div onClick={() => this.onPressBrand()} className="col-lg-25 col-md-3 col-sm-4 col-4">
                                            <div className="category_circle">
                                                <img src={`https://demo.smileitsolutions.com/kaaf/${i.image}`} alt="" />
                                                <div class="img_bg_dark"></div>
                                                <h3> {i.name}</h3>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </section>
                {/* <ProductCard title={"Latest Collection"} /> */}
                <section className="outfit_collections pt-2 pb-4">
                    <div className="container">


                        <div className="row">
                            <SectionTitle text={"Latest Collection"} onClick={() => this.latestCollection()} />
                            {
                                this.state.bestSeller.map((i) => {
                                    return (
                                        <div onClick={() => this.onPress(i)} className="col-lg-25 col-md-4 col-sm-6 col-6">
                                            <div className="product_heading">
                                                <h3>
                                                    <a>{i.brand}</a>
                                                </h3>
                                            </div>
                                            <div className="prduct_card">
                                                <a>
                                                    <img src={`https://demo.smileitsolutions.com/kaaf/uploads/product/${i.prod_image[0]}`} alt="" />
                                                </a>
                                                {/* <span className="wishlist_icon"><i className="fa fa-heart" aria-hidden="true"></i></span> */}
                                                <div className="product_price_title">
                                                    <h3>
                                                        <a>{i.descriptions != "" ? i.descriptions : 'New arrival'}</a>
                                                        {/* <a>{i.subcat_name}</a> */}
                                                    </h3>
                                                    <span className="current_price">AED{i.discounted_price}</span>
                                                    {i.sell_price != "" && i.sell_price != null ? <span className="actual_price">AED{i.sell_price}</span> : <span className="actual_price">AED 0.0</span>}
                                                    {i.discount != "" && i.discount != null ? <span className="percent_price">{i.discount}%</span> : null}


                                                    {/* <div class="product_reviews_stars">
                                                        <div className='App'>
                                                            <Rating
                                                                ratingValue={i.ratings}
                                                                readonly={true}
                                                                size={20}
                                                                transition
                                                                allowHalfIcon
                                                            />
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }


                        </div>
                    </div>
                </section>
                {/* <BrandSlider /> */}
                <Footer />
            </div>
        )
    }
}