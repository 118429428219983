import React, { Component,Modal,Alert  } from 'react'

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { dressOne, dressTwo, dressThree, dressFour, dressFive, paymentGateway, } from '../components/images'
import swal from 'sweetalert';
import SweetAlert from 'sweetalert-react'; // eslint-disable-line import/no-extraneous-dependencies
// import 'sweetalert/dist/sweetalert.css';
export default class MyCart extends Component {
    constructor(props) {
      super(props);
      this.state = {
        _filter: false,
        viewData: '',
        list: [],
        paramName:'',
        show: false,
      }
    }



    componentDidMount = async () => {

        var name = this.props.match.params.name
    
        console.log(name)
        this.setState({paramName:name},()=>console.log("data_params==>",this.state.paramName))
    
        const productData = localStorage.getItem('productData')
        const viewData = localStorage.getItem('viewAll')
        window.scrollTo(0, 0);
        console.log("dataLocal==>", JSON.parse(viewData))
        this.setState({ p_id: JSON.parse(productData).pr_id, viewData: JSON.parse(viewData) }, () => this.ProductList())
    
      }
    
    
      ProductList = () => {
    
    
    
        try {
    
  
      var data = {
			"jwt":""
            // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9leGFtcGxlLm9yZyIsImF1ZCI6Imh0dHA6XC9cL2V4YW1wbGUuY29tIiwiaWF0IjoxMzU2OTk5NTI0LCJuYmYiOjEzNTcwMDAwMDAsImRhdGEiOnsiaWQiOiIxMTUiLCJ1c2VyX3R5cGUiOm51bGwsIm5hbWUiOm51bGx9fQ.E0RjGs700WvO3aFnTs5P2pk8j5oVOmCQWb9tpd70DyE"
            ,

		
      }
    
      
       
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "text/plain");
          var requestOptions = {
            method: 'POST',
            body: JSON.stringify(data),
            redirect: 'follow'
          };
    
          console.log("data==>", data)
          fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/list_cart.php", requestOptions)
            .then(async resp => {
              console.log("Api_resp", resp)
              const json = await resp.json()
              console.log("home_product_lists==>", json)
              if (resp.status == 200) {
                  console.log("data_SignUpApi====>", json.list)
                // const newResult = resp.data[5].map(item => { return `https://demo.smileitsolutions.com/kaaf/${item}` })
    
                  this.setState({
                    list:json[0].list,
                    isLoading: false,
                    openSearch: false
                  }, () => console.log("data_list==>", this.state.list))
                
              }

              else if (resp.status == 201) {
                this.setState({
                    list: [],
                    isLoading: false,
                }, () =>

                swal({
                    title: "Alert",
                    text: json.message,
                    icon: "error",
                    button: "Ok",
                  }))

            }

            else if (resp.status == 401) {
                this.setState({
                    isLoading: false,
                }, () =>
                swal({
                    title: "Alert",
                    text: "Please login.",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  })
                  .then((willDelete) => {
                    if (willDelete) {
                        this.props.history.push('/login')
                    //   swal("Poof! Your imaginary file has been deleted!", {
                    //     icon: "success",
                    //   });
                    } else {
                    //   swal("Your imaginary file is safe!");
                    }
                  }))
            }

              else {
                this.setState({ isLoading: false, openSearch: false }, () => alert("Invalid credentials please try again."))
              }
            })
        }
        catch (error) {
          console.log("home_product_lists_catch====>", error);
        }
      }



    // const [productCart] = useState({
    //     list: [
    //         { img: dressTwo, currentprice: '$60', actualprice: '$90', name: 'PINK DITSY FLORAL SPLIT STRAPPY MINI DRESS' },
    //         { img: dressThree, currentprice: '$70', actualprice: '$90', name: 'PINK DITSY FLORAL STRAPPY MINI DRESS' },
    //         { img: dressOne, currentprice: '$80', actualprice: '$90', name: 'PINK DITSY FLORAL SPLIT STRAPPY DRESS' },
    //         { img: dressFour, currentprice: '$90', actualprice: '$90', name: 'DITSY FLORAL SPLIT STRAPPY MINI DRESS' },
    //         { img: dressFive, currentprice: '$20', actualprice: '$90', name: 'PINK FLORAL SPLIT STRAPPY MINI DRESS' },

    //     ]
    // })

     handleRouteChange = () => {
       this.props.history.push('/checkoutAddress')
    }
render(){
    return (
        <div>
            <Navbar />
            <section className="cart_page pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="my_cart_title">
                                <h3>My Cart <span>Total 6 items</span></h3>
                            </div>
                            <div className="cart_table">

                                {
                                    this.state.list.map((i) => {
                                        return (
                                            <div className="cart_items">
                                                <div className="remove_item">
                                                    <span><i class="fa fa-trash" aria-hidden="true"></i></span>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4 col-md-2 pe-0">
                                                        <img src={i.img} alt="" />
                                                    </div>
                                                    <div className="col-8 col-md-10 pt-1">
                                                        <span className="current_price">{i.currentprice}</span>
                                                        <span className="actual_price">{i.actualprice}</span>
                                                        <h3><a href="/">{i.name}</a></h3>
                                                        <div className="cart_quantity">
                                                            <label htmlFor="cart_qty">Choose Qty:</label>
                                                            <select name="cart_qty" id="">
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                            </select>
                                                        </div>
                                                        <button><i className="fa fa-heart" aria-hidden="true"></i> Save For Later</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                              
                            </div>
                        </div>

                     







                        <div className="col-md-4">
                            <div className="checkout_amount">
                                {/* <h3>Total</h3>
                                <table>

                                    {
                                        productCart.list.map((i) => {
                                            return (
                                                <tr>
                                                    <td><p>{i.name}</p></td>
                                                    <td><span>{i.currentprice}</span></td>
                                                </tr>
                                            )
                                        })
                                    }

                                </table> */}

                                <h4>Total: <span>$456.00</span></h4>

                                <button type="submit"
                                    onClick={()=>this.handleRouteChange()} 
                                     >Checkout</button>
                                {/* <img src={paymentGateway} alt="" /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <Footer />
        </div>
    )
}
  
}
