import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export default function PaymentSuccessful() {
    return (
        <div>
            <Navbar/>
            <section className="pt-5 pb-5 cart_page">
        <div className="container">
            <div className="row">
                <div className="col-md-6 m-auto">
                    <div className="payment_status">
                        <span><i className="fa fa-check-circle" aria-hidden="true"></i></span>
                        <h3>Payment Successful!</h3>                        
                        <p>Thanks you for billing. You payment reference id is: <span>34634235756345234</span></p>                        
                    </div>
                </div>
            </div>
        </div>
    </section>
            <Footer/>
        </div>
    )
}
