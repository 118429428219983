import React, { Component } from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import SectionTitle from '../components/sectionTitle';
import { shoppingSix, rankBadge, indiaFlag, uaeFlag } from '../components/images'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Rating } from 'react-simple-star-rating';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import ProductCard from '../components/ProductCard';
// import FreelancerProductCard from '../components/FreelancerProductCard';
var setName

export default class FreelancerView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commCard: [],
            fd: false, ph: false, m: false, ma: false, b: false, ma: false, i: false,
            list: [
                { img: shoppingSix, name: 'Apoorva', category: 'Model', rank: '#1' }
            ],
            detailsCommunityName: "",
            point: "",
            detailsMemberID: "",
            detailsName: "",
            detailsMobile: "",
            detailsRank: "",
            detailsImage: "",
            countryData: [],
            country: [],
            prdData: [],
            showBox: false
        }
    }

    componentDidMount = async () => {

        var name = this.props.match.params.id

        setName = name.replace("_", " ");
        console.log(setName)

        this.memberDetails()
        // const productData = localStorage.getItem('communityData')
        window.scrollTo(0, 0);
        // console.log("dataLocal==>",JSON.parse(productData))
        // this.setState({p_id:JSON.parse(productData).pr_id,}
        // ,()=>this.LeaderBoard()
        // )

        // this.setState({
        //     detailsCommunityName:JSON.parse(productData).community_name,
        //     point:JSON.parse(productData).point,
        //     detailsMemberID:JSON.parse(productData).member_id,
        //     detailsName:JSON.parse(productData).name,
        //     detailsMobile:JSON.parse(productData).mobile,
        //     detailsRank:JSON.parse(productData).rank,
        //     detailsImage:JSON.parse(productData).image,
        //     country:JSON.parse(productData).country!=null||JSON.parse(productData).country!=""?JSON.parse(productData).country.split(","):[]
        //     }
        //     ,()=>this.LeaderBoard()
        //     )

        // var img=this.state.country!=null||this.state.country!=""?this.state.country.split(","):[] 
        // console.log("data_country==>",img)
        // this.setState({countryData:this.state.country})


    }



    memberDetails = () => {

        try {


            var data = {
                "search_string": setName,
            }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                redirect: 'follow'
            };


            fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/member_search.php", requestOptions)
                .then(async resp => {
                    const json = await resp.json()
                    console.log("data==>", json)


                    console.log("product_listing>>>>>", resp)
                    if (resp.status == 200) {
                        console.log("data_SignUpApi====>", JSON.stringify(resp))
                        this.setState({
                            detailsCommunityName: json.communiy_member[0].community_name,
                            point: json.communiy_member[0].point,
                            detailsMemberID: json.communiy_member[0].member_id,
                            detailsName: json.communiy_member[0].name,
                            detailsMobile: json.communiy_member[0].mobile,
                            detailsRank: json.communiy_member[0].rank,
                            detailsImage: json.communiy_member[0].image,
                            p_id: json.communiy_member[0].member_id,
                            detailsCC: json.communiy_member[0].mobile_cc,
                            country: json.communiy_member[0].country != null || json.communiy_member[0].country != "" ? json.communiy_member[0].country.split(",") : [],
                            send: "want to hire " + json.communiy_member[0].name + " having member id " + json.communiy_member[0].member_id

                        }
                            , () => this.LeaderBoard()
                        )


                    }
                    else {
                        this.setState({ isLoading: false }, () => alert("Invalid credentials please try again."))
                    }
                })
        }
        catch (error) {
            console.log("product_listing_catch====>", error);
        }
    }





    LeaderBoard = () => {

        try {


            var data = {
                "member_id": this.state.detailsMemberID,
                "community_name": this.state.detailsCommunityName
            }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                redirect: 'follow'
            };


            fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/product_listing.php", requestOptions)
                .then(async resp => {
                    const json = await resp.json()
                    console.log("data==>", json)


                    console.log("product_listing>>>>>", resp)
                    if (resp.status == 200) {
                        console.log("data_SignUpApi====>", JSON.stringify(resp))
                        this.setState({
                            prdData: json.list, isLoading: false
                        })

                    }
                    else {
                        this.setState({ isLoading: false }, () => alert("Invalid credentials please try again."))
                    }
                })
        }
        catch (error) {
            console.log("product_listing_catch====>", error);
        }
    }

    onPress = (i) => {
        console.log("data==>", i)
        // to="/viewProduct" 
        localStorage.setItem("productData", JSON.stringify(i))
        this.props.history.push('/viewProduct')
    }

    handleBoxToggle = () => this.setState({ showBox: !this.state.showBox });

    notify = () => {
        navigator.clipboard.writeText(this.state.detailsCC + this.state.detailsMobile)
        toast("Copied");

    }

    render() {
        return (
            <div>
                <Navbar />

                <section className="freelaancer_view_page">
                    <div className="container">
                        <div className="row">
                            <div className='col-12'>


                                {
                                    this.state.list.map((i) => {
                                        return (

                                            <div className="community_card">
                                                <div className="row">
                                                    <div className="col-md-5 col-sm-5 col-4 mr-auto">
                                                        <Link to="/viewFreelancer"><img className="freelancer_main_img" src={`https://demo.smileitsolutions.com/kaaf/uploads/${this.state.detailsImage}`} alt="" /></Link>
                                                    </div>
                                                    <div className="col-md-6 col-sm-7 col-8 pl-0">
                                                        <div className="freelancer_rank_main">
                                                            <img src={rankBadge} alt="" />
                                                            <span>{this.state.detailsRank ? `${this.state.detailsRank}` : ''}</span>
                                                        </div>
                                                        <div className="comm_flag_main_box">
                                                            {
                                                                this.state.country.map((i) => {
                                                                    return (
                                                                        <span className="comm_flag_box_two">
                                                                            <img src={`https://demo.smileitsolutions.com/kaaf/${i}`} alt="" />
                                                                        </span>
                                                                    )
                                                                })
                                                            }
                                                            {/* <img src={shoppingSix} alt="" /> */}
                                                            {/* <img src={indiaFlag} alt="" /> */}
                                                        </div>
                                                        <div to="/viewFreelancer" className="freelancer_name_main">
                                                            <h3>
                                                                <a>{this.state.detailsName}</a>
                                                            </h3>
                                                            <h4>{this.state.detailsCommunityName}</h4>
                                                        </div>
                                                        {/* <p className="freelancer_points">Points:- {this.state.point != null ? this.state.point : 0}</p> */}
                                                        <a
                                                            href={`https://wa.me/+971588001786/?text=${this.state.send}`}
                                                            // onClick={()=>this.notify() } 
                                                            className="freelancer_hire_btn_main" ><i class="fa fa-phone" aria-hidden="true"></i><span>{"Hire Now"}</span></a>


                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <ToastContainer type="info" />
                        {/* <div className="row">
                            <div className="freelancer_products">
                                <h3>Products By Freelancer</h3>
                            </div>
                        </div> */}
                        {/* <div className="row" >
                            {
                                this.state.prdData.length <= 0 ? <a >"No item found"</a> :

                                    this.state.prdData.map((i) => {
                                        return (
                                            <div onClick={() => this.onPress(i)} className="col-lg-25 col-md-4 col-sm-6 col-6" style={{ margin: "10px" }} >
                                                <div className="prduct_card">
                                                    <div className="product_heading">
                                                        <h3>
                                                            <a>{i.brand}</a>
                                                        </h3>
                                                    </div>
                                                    <a ><img src={`https://demo.smileitsolutions.com/kaaf/uploads/product/${i.prod_image[0]}`} alt="" /></a>
                                                    <span className="wishlist_icon" style={{ top: "55px" }} ><i className="fa fa-heart" aria-hidden="true"></i></span>
                                                    <div className="product_price_title">
                                                        <h3>
                                                            <a >{i.descriptions}</a>
                                                            <a >{i.subcat_name} ({i.brand})</a>
                                                        </h3>
                                                        <span className="current_price">AED{i.discounted_price}</span>
                                                        <span className="actual_price">AED{i.sell_price != "" ? i.sell_price : 0}</span>
                                                        {i.discount != "" ? <span className="percent_price">{i.discount}%</span> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                            }

                        </div> */}

                        <section className="outfit_collections pt-2 pb-2">
                            <div className="">
                                {/* <SectionTitle text={"Products By Freelancer"} onClick={() => this.view("picks")}
                                    textHeading={true}
                                /> */}

                                <div className="row" >
                                    {
                                        this.state.prdData.length <= 0 ? <a >"No item found"</a> :

                                            this.state.prdData.map((i) => {
                                                return (
                                                    <div onClick={() => this.onPress(i)} className="col-lg-25 col-md-4 col-sm-6 col-6" >
                                                        <div className="prduct_card">
                                                            <div className="product_heading">
                                                                <h3>
                                                                    <a>{i.brand}</a>
                                                                </h3>
                                                            </div>
                                                            <a ><img src={`https://demo.smileitsolutions.com/kaaf/uploads/product/${i.prod_image[0]}`} alt="" /></a>
                                                            {/* <span className="wishlist_icon" ><i className="fa fa-heart" aria-hidden="true"></i></span> */}
                                                            <div className="product_price_title">
                                                                <h3>
                                                                    <a >{i.descriptions}</a>
                                                                    {/* <a >{i.subcat_name} ({i.brand})</a> */}
                                                                </h3>
                                                                <span className="current_price">AED{i.discounted_price}</span>
                                                                <span className="actual_price">AED{i.sell_price != "" ? i.sell_price : 0}</span>
                                                                {i.discount != "" ? <span className="percent_price">{i.discount}%</span> : null}
                                                                <div class="product_reviews_stars">
                                                                    <div className='App'>
                                                                        <Rating
                                                                            ratingValue={i.ratings}
                                                                            readonly={true}
                                                                            size={20}
                                                                            transition
                                                                            allowHalfIcon
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                    }

                                </div>

                            </div>
                        </section>




                    </div>
                </section>



                <Footer />
            </div>
        )
    }

}