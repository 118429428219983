import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { dressOne, dressTwo, dressThree } from '../components/images';
import { Rating } from 'react-simple-star-rating';
export default function MyAccount() {

    const [isMyAccount, setMyAccount] = useState({
        account: true,
        orders: false,
        orderRating: false,
        payment: false,
        address: false,
        password: false
    })


    return (
        <div>
            <Navbar />

            <section className="my-account pt-4 pb-5">

                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-sm-2">
                            <div className="tab">
                                <button className={isMyAccount.account ? "tablinks active" : "tablinks"}
                                    onClick={() => setMyAccount({ ...isMyAccount, account: true, orders: false, orderRating: false, payment: false, address: false, password: false })}
                                    id="defaultOpen"><span><i className="fa fa-user" aria-hidden="true"></i></span>
                                    <span className='account_menu_text_resp'>My Account</span></button>
                                <button className={isMyAccount.orders ? "tablinks active" : "tablinks"} onClick={() => setMyAccount({ ...isMyAccount, orders: true, orderRating: false, account: false, payment: false, address: false, password: false })}>
                                    <span><i className="fa fa-shopping-bag" aria-hidden="true"></i></span>
                                    <span className='account_menu_text_resp'>My Orders</span></button>
                                <button className={isMyAccount.orderRating ? "tablinks active" : "tablinks"} onClick={() => setMyAccount({ ...isMyAccount, orders: false, orderRating: true, account: false, payment: false, address: false, password: false })}>
                                    <span><i className="fa fa-star" aria-hidden="true"></i></span>
                                    <span className='account_menu_text_resp'>Rate Products</span></button>
                                {/* <button className={isMyAccount.payment ? "tablinks active" : "tablinks"} onClick={() => setMyAccount({ ...isMyAccount, orders: false, orderRating: false, account: false, payment: true, address: false, password: false })}>
                                    <span><i className="fa fa-credit-card-alt" aria-hidden="true"></i></span>
                                    <span className='account_menu_text_resp'>Payment Method</span></button>
                                <button className={isMyAccount.address ? "tablinks active" : "tablinks"} onClick={() => setMyAccount({ ...isMyAccount, orders: false, orderRating: false, account: false, payment: false, address: true, password: false })}>
                                    <span><i className="fa fa-address-book" aria-hidden="true"></i></span>
                                    <span className='account_menu_text_resp'>Manage Address</span></button>
                                <button className={isMyAccount.password ? "tablinks active" : "tablinks"} onClick={() => setMyAccount({ ...isMyAccount, orders: false, orderRating: false, account: false, payment: false, address: false, password: true })}>
                                    <span><i className="fa fa-lock" aria-hidden="true"></i></span>
                                    <span className='account_menu_text_resp'>Change Password</span></button> */}
                                <button className={"tablinks"} onClick={() => alert("Hi I'm here")} type="submit"><span><i className="fa fa-sign-out" aria-hidden="true"></i></span>
                                    <span className='account_menu_text_resp'>Logout</span></button>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-10">

                            {isMyAccount.account ?
                                <div id="my_account" className="tabcontent" >
                                    <div className="my_profile_tab">
                                        <h2>Update Profile Details</h2>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <h4>First Name</h4>
                                                <input type="text" placeholder="First Name" />
                                            </div>
                                            <div className="col-md-6">
                                                <h4>Last Name</h4>
                                                <input type="text" placeholder="Last Name" />
                                            </div>
                                            <div className="col-md-6">
                                                <h4>Email</h4>
                                                <input type="email" placeholder="Email" />
                                            </div>
                                            <div className="col-md-6">
                                                <h4>Phone</h4>
                                                <div className="row">
                                                    <div className="col-3 pe-1">
                                                        <select name="phone_cc" required="">
                                                            <option selected="" data-countrycode="US" value="1">US (+1)</option>
                                                            <option data-countrycode="US" value="0">BV (+0)</option>
                                                            <option data-countrycode="US" value="0">GS (+0)</option>
                                                            <option data-countrycode="US" value="0">AQ (+0)</option>
                                                            <option data-countrycode="US" value="0">PN (+0)</option>
                                                            <option data-countrycode="US" value="0">TF (+0)</option>
                                                            <option data-countrycode="US" value="0">HM (+0)</option>
                                                            <option data-countrycode="US" value="1">CA (+1)</option>
                                                            <option data-countrycode="US" value="1">UM (+1)</option>
                                                            <option data-countrycode="US" value="7">KZ (+7)</option>
                                                            <option data-countrycode="US" value="20">EG (+20)</option>
                                                            <option data-countrycode="US" value="27">ZA (+27)</option>
                                                            <option data-countrycode="US" value="30">GR (+30)</option>
                                                            <option data-countrycode="US" value="31">NL (+31)</option>
                                                            <option data-countrycode="US" value="32">BE (+32)</option>
                                                            <option data-countrycode="US" value="33">FR (+33)</option>
                                                            <option data-countrycode="US" value="34">ES (+34)</option>
                                                            <option data-countrycode="US" value="36">HU (+36)</option>
                                                            <option data-countrycode="US" value="38">YU (+38)</option>
                                                            <option data-countrycode="US" value="39">VA (+39)</option>
                                                            <option data-countrycode="US" value="39">IT (+39)</option>
                                                            <option data-countrycode="US" value="40">RO (+40)</option>
                                                            <option data-countrycode="US" value="41">CH (+41)</option>
                                                            <option data-countrycode="US" value="43">AT (+43)</option>
                                                            <option data-countrycode="US" value="44">XJ (+44)</option>
                                                            <option data-countrycode="US" value="44">UK (+44)</option>
                                                            <option data-countrycode="US" value="44">XU (+44)</option>
                                                            <option data-countrycode="US" value="44">XM (+44)</option>
                                                            <option data-countrycode="US" value="44">XG (+44)</option>
                                                            <option data-countrycode="US" value="45">DK (+45)</option>
                                                            <option data-countrycode="US" value="46">SE (+46)</option>
                                                            <option data-countrycode="US" value="47">SJ (+47)</option>
                                                            <option data-countrycode="US" value="47">NO (+47)</option>
                                                            <option data-countrycode="US" value="48">PL (+48)</option>
                                                            <option data-countrycode="US" value="49">DE (+49)</option>
                                                            <option data-countrycode="US" value="51">PE (+51)</option>
                                                            <option data-countrycode="US" value="52">MX (+52)</option>
                                                            <option data-countrycode="US" value="53">CU (+53)</option>
                                                            <option data-countrycode="US" value="54">AR (+54)</option>
                                                            <option data-countrycode="US" value="55">BR (+55)</option>
                                                            <option data-countrycode="US" value="56">CL (+56)</option>
                                                            <option data-countrycode="US" value="57">CO (+57)</option>
                                                            <option data-countrycode="US" value="58">VE (+58)</option>
                                                            <option data-countrycode="US" value="60">MY (+60)</option>
                                                            <option data-countrycode="US" value="61">CX (+61)</option>
                                                            <option data-countrycode="US" value="61">AU (+61)</option>
                                                            <option data-countrycode="US" value="61">XA (+61)</option>
                                                            <option data-countrycode="US" value="62">ID (+62)</option>
                                                            <option data-countrycode="US" value="63">PH (+63)</option>
                                                            <option data-countrycode="US" value="64">NZ (+64)</option>
                                                            <option data-countrycode="US" value="65">SG (+65)</option>
                                                            <option data-countrycode="US" value="66">TH (+66)</option>
                                                            <option data-countrycode="US" value="70">RU (+70)</option>
                                                            <option data-countrycode="US" value="81">JP (+81)</option>
                                                            <option data-countrycode="US" value="82">KR (+82)</option>
                                                            <option data-countrycode="US" value="84">VN (+84)</option>
                                                            <option data-countrycode="US" value="86">CN (+86)</option>
                                                            <option data-countrycode="US" value="90">TR (+90)</option>
                                                            <option data-countrycode="US" value="91">IN (+91)</option>
                                                            <option data-countrycode="US" value="92">PK (+92)</option>
                                                            <option data-countrycode="US" value="93">AF (+93)</option>
                                                            <option data-countrycode="US" value="94">LK (+94)</option>
                                                            <option data-countrycode="US" value="95">MM (+95)</option>
                                                            <option data-countrycode="US" value="98">IR (+98)</option>
                                                            <option data-countrycode="US" value="211">SS (+211)</option>
                                                            <option data-countrycode="US" value="212">MA (+212)</option>
                                                            <option data-countrycode="US" value="212">EH (+212)</option>
                                                            <option data-countrycode="US" value="213">DZ (+213)</option>
                                                            <option data-countrycode="US" value="216">TN (+216)</option>
                                                            <option data-countrycode="US" value="218">LY (+218)</option>
                                                            <option data-countrycode="US" value="220">GM (+220)</option>
                                                            <option data-countrycode="US" value="221">SN (+221)</option>
                                                            <option data-countrycode="US" value="222">MR (+222)</option>
                                                            <option data-countrycode="US" value="223">ML (+223)</option>
                                                            <option data-countrycode="US" value="224">GN (+224)</option>
                                                            <option data-countrycode="US" value="225">CI (+225)</option>
                                                            <option data-countrycode="US" value="226">BF (+226)</option>
                                                            <option data-countrycode="US" value="227">NE (+227)</option>
                                                            <option data-countrycode="US" value="228">TG (+228)</option>
                                                            <option data-countrycode="US" value="229">BJ (+229)</option>
                                                            <option data-countrycode="US" value="230">MU (+230)</option>
                                                            <option data-countrycode="US" value="231">LR (+231)</option>
                                                            <option data-countrycode="US" value="232">SL (+232)</option>
                                                            <option data-countrycode="US" value="233">GH (+233)</option>
                                                            <option data-countrycode="US" value="234">NG (+234)</option>
                                                            <option data-countrycode="US" value="235">TD (+235)</option>
                                                            <option data-countrycode="US" value="236">CF (+236)</option>
                                                            <option data-countrycode="US" value="237">CM (+237)</option>
                                                            <option data-countrycode="US" value="238">CV (+238)</option>
                                                            <option data-countrycode="US" value="239">ST (+239)</option>
                                                            <option data-countrycode="US" value="240">GQ (+240)</option>
                                                            <option data-countrycode="US" value="241">GA (+241)</option>
                                                            <option data-countrycode="US" value="242">CG (+242)</option>
                                                            <option data-countrycode="US" value="242">CD (+242)</option>
                                                            <option data-countrycode="US" value="244">AO (+244)</option>
                                                            <option data-countrycode="US" value="245">GW (+245)</option>
                                                            <option data-countrycode="US" value="246">IO (+246)</option>
                                                            <option data-countrycode="US" value="248">SC (+248)</option>
                                                            <option data-countrycode="US" value="249">SD (+249)</option>
                                                            <option data-countrycode="US" value="250">RW (+250)</option>
                                                            <option data-countrycode="US" value="251">ET (+251)</option>
                                                            <option data-countrycode="US" value="252">SO (+252)</option>
                                                            <option data-countrycode="US" value="253">DJ (+253)</option>
                                                            <option data-countrycode="US" value="254">KE (+254)</option>
                                                            <option data-countrycode="US" value="255">TZ (+255)</option>
                                                            <option data-countrycode="US" value="256">UG (+256)</option>
                                                            <option data-countrycode="US" value="257">BI (+257)</option>
                                                            <option data-countrycode="US" value="258">MZ (+258)</option>
                                                            <option data-countrycode="US" value="260">ZM (+260)</option>
                                                            <option data-countrycode="US" value="261">MG (+261)</option>
                                                            <option data-countrycode="US" value="262">RE (+262)</option>
                                                            <option data-countrycode="US" value="263">ZW (+263)</option>
                                                            <option data-countrycode="US" value="264">NA (+264)</option>
                                                            <option data-countrycode="US" value="265">MW (+265)</option>
                                                            <option data-countrycode="US" value="266">LS (+266)</option>
                                                            <option data-countrycode="US" value="267">BW (+267)</option>
                                                            <option data-countrycode="US" value="268">SZ (+268)</option>
                                                            <option data-countrycode="US" value="269">KM (+269)</option>
                                                            <option data-countrycode="US" value="269">YT (+269)</option>
                                                            <option data-countrycode="US" value="290">SH (+290)</option>
                                                            <option data-countrycode="US" value="291">ER (+291)</option>
                                                            <option data-countrycode="US" value="297">AW (+297)</option>
                                                            <option data-countrycode="US" value="298">FO (+298)</option>
                                                            <option data-countrycode="US" value="299">GL (+299)</option>
                                                            <option data-countrycode="US" value="350">GI (+350)</option>
                                                            <option data-countrycode="US" value="351">PT (+351)</option>
                                                            <option data-countrycode="US" value="352">LU (+352)</option>
                                                            <option data-countrycode="US" value="353">IE (+353)</option>
                                                            <option data-countrycode="US" value="354">IS (+354)</option>
                                                            <option data-countrycode="US" value="355">AL (+355)</option>
                                                            <option data-countrycode="US" value="356">MT (+356)</option>
                                                            <option data-countrycode="US" value="357">CY (+357)</option>
                                                            <option data-countrycode="US" value="358">FI (+358)</option>
                                                            <option data-countrycode="US" value="359">BG (+359)</option>
                                                            <option data-countrycode="US" value="370">LT (+370)</option>
                                                            <option data-countrycode="US" value="371">LV (+371)</option>
                                                            <option data-countrycode="US" value="372">EE (+372)</option>
                                                            <option data-countrycode="US" value="373">MD (+373)</option>
                                                            <option data-countrycode="US" value="374">AM (+374)</option>
                                                            <option data-countrycode="US" value="375">BY (+375)</option>
                                                            <option data-countrycode="US" value="376">AD (+376)</option>
                                                            <option data-countrycode="US" value="377">MC (+377)</option>
                                                            <option data-countrycode="US" value="378">SM (+378)</option>
                                                            <option data-countrycode="US" value="380">UA (+380)</option>
                                                            <option data-countrycode="US" value="381">RS (+381)</option>
                                                            <option data-countrycode="US" value="385">HR (+385)</option>
                                                            <option data-countrycode="US" value="386">SI (+386)</option>
                                                            <option data-countrycode="US" value="387">BA (+387)</option>
                                                            <option data-countrycode="US" value="389">MK (+389)</option>
                                                            <option data-countrycode="US" value="420">CZ (+420)</option>
                                                            <option data-countrycode="US" value="421">SK (+421)</option>
                                                            <option data-countrycode="US" value="423">LI (+423)</option>
                                                            <option data-countrycode="US" value="500">FK (+500)</option>
                                                            <option data-countrycode="US" value="501">BZ (+501)</option>
                                                            <option data-countrycode="US" value="502">GT (+502)</option>
                                                            <option data-countrycode="US" value="503">SV (+503)</option>
                                                            <option data-countrycode="US" value="504">HN (+504)</option>
                                                            <option data-countrycode="US" value="505">NI (+505)</option>
                                                            <option data-countrycode="US" value="506">CR (+506)</option>
                                                            <option data-countrycode="US" value="507">PA (+507)</option>
                                                            <option data-countrycode="US" value="508">PM (+508)</option>
                                                            <option data-countrycode="US" value="509">HT (+509)</option>
                                                            <option data-countrycode="US" value="590">GP (+590)</option>
                                                            <option data-countrycode="US" value="591">BO (+591)</option>
                                                            <option data-countrycode="US" value="592">GY (+592)</option>
                                                            <option data-countrycode="US" value="593">EC (+593)</option>
                                                            <option data-countrycode="US" value="594">GF (+594)</option>
                                                            <option data-countrycode="US" value="595">PY (+595)</option>
                                                            <option data-countrycode="US" value="596">MQ (+596)</option>
                                                            <option data-countrycode="US" value="597">SR (+597)</option>
                                                            <option data-countrycode="US" value="598">UY (+598)</option>
                                                            <option data-countrycode="US" value="599">AN (+599)</option>
                                                            <option data-countrycode="US" value="670">TP (+670)</option>
                                                            <option data-countrycode="US" value="672">NF (+672)</option>
                                                            <option data-countrycode="US" value="672">CC (+672)</option>
                                                            <option data-countrycode="US" value="673">BN (+673)</option>
                                                            <option data-countrycode="US" value="674">NR (+674)</option>
                                                            <option data-countrycode="US" value="675">PG (+675)</option>
                                                            <option data-countrycode="US" value="676">TO (+676)</option>
                                                            <option data-countrycode="US" value="677">SB (+677)</option>
                                                            <option data-countrycode="US" value="678">VU (+678)</option>
                                                            <option data-countrycode="US" value="679">FJ (+679)</option>
                                                            <option data-countrycode="US" value="680">PW (+680)</option>
                                                            <option data-countrycode="US" value="681">WF (+681)</option>
                                                            <option data-countrycode="US" value="682">CK (+682)</option>
                                                            <option data-countrycode="US" value="683">NU (+683)</option>
                                                            <option data-countrycode="US" value="684">WS (+684)</option>
                                                            <option data-countrycode="US" value="686">KI (+686)</option>
                                                            <option data-countrycode="US" value="687">NC (+687)</option>
                                                            <option data-countrycode="US" value="688">TV (+688)</option>
                                                            <option data-countrycode="US" value="689">PF (+689)</option>
                                                            <option data-countrycode="US" value="690">TK (+690)</option>
                                                            <option data-countrycode="US" value="691">FM (+691)</option>
                                                            <option data-countrycode="US" value="692">MH (+692)</option>
                                                            <option data-countrycode="US" value="850">KP (+850)</option>
                                                            <option data-countrycode="US" value="852">HK (+852)</option>
                                                            <option data-countrycode="US" value="853">MO (+853)</option>
                                                            <option data-countrycode="US" value="855">KH (+855)</option>
                                                            <option data-countrycode="US" value="856">LA (+856)</option>
                                                            <option data-countrycode="US" value="880">BD (+880)</option>
                                                            <option data-countrycode="US" value="886">TW (+886)</option>
                                                            <option data-countrycode="US" value="960">MV (+960)</option>
                                                            <option data-countrycode="US" value="961">LB (+961)</option>
                                                            <option data-countrycode="US" value="962">JO (+962)</option>
                                                            <option data-countrycode="US" value="963">SY (+963)</option>
                                                            <option data-countrycode="US" value="964">IQ (+964)</option>
                                                            <option data-countrycode="US" value="965">KW (+965)</option>
                                                            <option data-countrycode="US" value="966">SA (+966)</option>
                                                            <option data-countrycode="US" value="967">YE (+967)</option>
                                                            <option data-countrycode="US" value="968">OM (+968)</option>
                                                            <option data-countrycode="US" value="970">PS (+970)</option>
                                                            <option data-countrycode="US" value="971">UAE (+971)</option>
                                                            <option data-countrycode="US" value="972">IL (+972)</option>
                                                            <option data-countrycode="US" value="973">BH (+973)</option>
                                                            <option data-countrycode="US" value="974">QA (+974)</option>
                                                            <option data-countrycode="US" value="975">BT (+975)</option>
                                                            <option data-countrycode="US" value="976">MN (+976)</option>
                                                            <option data-countrycode="US" value="977">NP (+977)</option>
                                                            <option data-countrycode="US" value="992">TJ (+992)</option>
                                                            <option data-countrycode="US" value="994">AZ (+994)</option>
                                                            <option data-countrycode="US" value="995">GE (+995)</option>
                                                            <option data-countrycode="US" value="996">KG (+996)</option>
                                                            <option data-countrycode="US" value="998">UZ (+998)</option>
                                                            <option data-countrycode="US" value="1242">BS (+1242)</option>
                                                            <option data-countrycode="US" value="1246">BB (+1246)</option>
                                                            <option data-countrycode="US" value="1264">AI (+1264)</option>
                                                            <option data-countrycode="US" value="1268">AG (+1268)</option>
                                                            <option data-countrycode="US" value="1284">VG (+1284)</option>
                                                            <option data-countrycode="US" value="1340">VI (+1340)</option>
                                                            <option data-countrycode="US" value="1345">KY (+1345)</option>
                                                            <option data-countrycode="US" value="1441">BM (+1441)</option>
                                                            <option data-countrycode="US" value="1473">GD (+1473)</option>
                                                            <option data-countrycode="US" value="1649">TC (+1649)</option>
                                                            <option data-countrycode="US" value="1664">MS (+1664)</option>
                                                            <option data-countrycode="US" value="1670">MP (+1670)</option>
                                                            <option data-countrycode="US" value="1671">GU (+1671)</option>
                                                            <option data-countrycode="US" value="1684">AS (+1684)</option>
                                                            <option data-countrycode="US" value="1758">LC (+1758)</option>
                                                            <option data-countrycode="US" value="1767">DM (+1767)</option>
                                                            <option data-countrycode="US" value="1784">VC (+1784)</option>
                                                            <option data-countrycode="US" value="1787">PR (+1787)</option>
                                                            <option data-countrycode="US" value="1809">DO (+1809)</option>
                                                            <option data-countrycode="US" value="1868">TT (+1868)</option>
                                                            <option data-countrycode="US" value="1869">KN (+1869)</option>
                                                            <option data-countrycode="US" value="1876">JM (+1876)</option>
                                                            <option data-countrycode="US" value="7370">TM (+7370)</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-9 ps-1">
                                                        <input type="number" placeholder="Contact Number" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button type="submit">Save Details</button>
                                            </div>
                                            <div className="col-6">

                                            </div>
                                            <div className="col-6">

                                            </div>
                                            <div className="col-6">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}


                            {isMyAccount.orders ?
                                <div id="mt_order" className="tab_content order_history_tab_content">

                                    <div className='order_history_prodct_card'>
                                        <div className='row align-items-center'>
                                            <div className='col-4 pr-0'>
                                                <img src={dressTwo} alt="" />
                                            </div>
                                            <div className='col-8'>
                                                <div className='order_card_details'>
                                                    <p className='order_status_detail'>Delivered within 10 to 15 days</p>
                                                    <h4>Abaya</h4>
                                                    <h3>AZIZIA</h3>
                                                    <p className='order_purchase_price'>271 AED</p>
                                                    <div className='order_purchase_time_date'>
                                                        <span className='order_purchase_date'>2021-12-29 </span>
                                                        <span className='order_purchase_time'> 09:36:43</span>
                                                    </div>
                                                    <p className='order_product_quantity'>Quantity: 1</p>
                                                    <p className='order_status_processing'>Processing</p>
                                                    <button type='submit'>Cancel Order</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='order_history_prodct_card'>
                                        <div className='row align-items-center'>
                                            <div className='col-4 pr-0'>
                                                <img src={dressThree} alt="" />
                                            </div>
                                            <div className='col-8'>
                                                <div className='order_card_details'>
                                                    <p className='order_status_detail'>Delivered within 10 to 15 days</p>
                                                    <h4>Abaya</h4>
                                                    <h3>AZIZIA</h3>
                                                    <p className='order_purchase_price'>271 AED</p>
                                                    <div>
                                                        <span className='order_purchase_date'>2021-12-29 </span>
                                                        <span className='order_purchase_time'> 09:36:43</span>

                                                    </div>
                                                    <p className='order_product_quantity'>Quantity: 1</p>
                                                    <p className='order_status_dispatched'>Dispatched</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='order_history_prodct_card'>
                                        <div className='row align-items-center'>
                                            <div className='col-4 pr-0'>
                                                <img src={dressOne} alt="" />
                                            </div>
                                            <div className='col-8'>
                                                <div className='order_card_details'>
                                                    <p className='order_status_detail'>Delivered within 10 to 15 days</p>
                                                    <h4>Abaya</h4>
                                                    <h3>AZIZIA</h3>
                                                    <p className='order_purchase_price'>271 AED</p>
                                                    <div><span className='order_purchase_date'>2021-12-29 </span><span className='order_purchase_time'> 09:36:43</span></div>
                                                    <p className='order_product_quantity'>Quantity: 1</p>
                                                    <p className='order_status_cancel'>Cancelled</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="my_profile_tab my_order_table">
                                        <h2>Oder History</h2>
                                        <table>
                                            <tbody><tr>
                                                <th>Sr.</th>
                                                <th>Name</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                                <th>Total</th>
                                                <th>Product</th>
                                            </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td>Jeans</td>
                                                    <td>11 Jul</td>
                                                    <td>Dellivered</td>
                                                    <td>$34</td>
                                                    <td><a href="#">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Top</td>
                                                    <td>12 Jun</td>
                                                    <td>In Process</td>
                                                    <td>$75</td>
                                                    <td><a href="#">View</a></td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td>Top</td>
                                                    <td>12 Jun</td>
                                                    <td>Cancelled</td>
                                                    <td>$75</td>
                                                    <td><a href="#">View</a></td>
                                                </tr>

                                            </tbody></table>
                                    </div> */}
                                </div>
                                : null}

                            {isMyAccount.orderRating ?
                                <div id='order_rating' className='tabcontent .order_history_tab_content'>

                                    <div className='order_history_prodct_card'>
                                        <div className='row align-items-center'>
                                            <div className='col-5 pr-0'>
                                                <img src={dressOne} alt="" />
                                            </div>
                                            <div className='col-7'>
                                                <div className='order_rating_card'>
                                                    <h4>Abaya</h4>
                                                    <h3>AZIZIA</h3>
                                                    <p className='order_purchase_price'>271 AED</p>
                                                    <div class="order_ratings_stars">
                                                        <div className='App'>
                                                            <Rating
                                                                ratingValue={3}
                                                                readonly={true}
                                                                size={20}
                                                                transition
                                                                allowHalfIcon
                                                            />
                                                        </div>
                                                    </div>
                                                    <input type="text" placeholder="Write" />
                                                    <button type='submit'>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='order_history_prodct_card'>
                                        <div className='row align-items-center'>
                                            <div className='col-5 pr-0'>
                                                <img src={dressTwo} alt="" />
                                            </div>
                                            <div className='col-7'>
                                                <div className='order_rating_card'>
                                                    <h4>Abaya</h4>
                                                    <h3>AZIZIA</h3>
                                                    <p className='order_purchase_price'>271 AED</p>
                                                    <div class="order_ratings_stars">
                                                        <div className='App'>
                                                            <Rating
                                                                ratingValue={3}
                                                                readonly={true}
                                                                size={20}
                                                                transition
                                                                allowHalfIcon
                                                            />
                                                        </div>
                                                    </div>
                                                    <input type="text" placeholder="Write" />
                                                    <button type='submit'>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='order_history_prodct_card'>
                                        <div className='row align-items-center'>
                                            <div className='col-5 pr-0'>
                                                <img src={dressThree} alt="" />
                                            </div>
                                            <div className='col-7'>
                                                <div className='order_rating_card'>
                                                    <h4>Abaya</h4>
                                                    <h3>AZIZIA</h3>
                                                    <p className='order_purchase_price'>271 AED</p>
                                                    <div class="order_ratings_stars">
                                                        <div className='App'>
                                                            <Rating
                                                                ratingValue={3}
                                                                readonly={true}
                                                                size={20}
                                                                transition
                                                                allowHalfIcon
                                                            />
                                                        </div>
                                                    </div>
                                                    <input type="text" placeholder="Write" />
                                                    <button type='submit'>Submit</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                : null}


                            {isMyAccount.payment ?

                                <div id="payment" className="tabcontent" >
                                    <div className="my_profile_tab">
                                        <h2>Manage Payment Method</h2>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <h4>Name On Card</h4>
                                                <input type="text" placeholder="Enter Name" />

                                            </div>
                                            <div className="col-md-12">

                                                <h4>Card Number</h4>
                                                <input type="text" placeholder="Card Number" />

                                            </div>

                                            <div className="col-4 pe-1">

                                                <h4>Month</h4>
                                                <select name="" id="">
                                                    <option value="">January</option>
                                                    <option value="">February</option>
                                                    <option value="">March</option>
                                                    <option value="">April</option>
                                                    <option value="">May</option>
                                                    <option value="">June</option>
                                                    <option value="">July</option>
                                                    <option value="">August</option>
                                                    <option value="">September</option>
                                                    <option value="">October</option>
                                                    <option value="">November</option>
                                                    <option value="">December</option>
                                                </select>

                                            </div>
                                            <div className="col-4 ps-1 pe-1">

                                                <h4>Year</h4>
                                                <select name="" id="">
                                                    <option value="">2021</option>
                                                    <option value="">2022</option>
                                                    <option value="">2023</option>
                                                    <option value="">2024</option>
                                                    <option value="">2025</option>
                                                    <option value="">2026</option>
                                                    <option value="">2027</option>
                                                    <option value="">2028</option>
                                                    <option value="">2029</option>
                                                    <option value="">2030</option>
                                                    <option value="">2031</option>
                                                    <option value="">2032</option>
                                                    <option value="">2033</option>

                                                </select>

                                            </div>
                                            <div className="col-4 ps-1">

                                                <h4>CVV</h4>
                                                <input type="number" placeholder="Enter CVV" />

                                            </div>
                                            <div className="col-md-12">

                                                <button type="submit">Save Card Details</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                : null}

                            {isMyAccount.address ?
                                <div id="address" className="tabcontent" >

                                    <div className="my_profile_tab">
                                        <h2>Manage Delivery Address</h2>
                                        <div className="row">
                                            <div className="col-md-12">

                                                <h4>Company Name (Optional)</h4>
                                                <input type="text" placeholder="Company Name" />

                                            </div>
                                            <div className="col-md-6">

                                                <h4>Country</h4>
                                                <select name="" id="">
                                                    <option value="">United Kingdom</option>
                                                    <option value="">United Kingdom</option>
                                                    <option value="">United Kingdom</option>
                                                    <option value="">United Kingdom</option>
                                                    <option value="">United Kingdom</option>
                                                    <option value="">United Kingdom</option>
                                                    <option value="">United Kingdom</option>
                                                </select>

                                            </div>
                                            <div className="col-md-6">

                                                <h4>State</h4>
                                                <select name="" id="">
                                                    <option value="">United Kingdom</option>
                                                    <option value="">United Kingdom</option>
                                                    <option value="">United Kingdom</option>
                                                    <option value="">United Kingdom</option>
                                                    <option value="">United Kingdom</option>
                                                </select>

                                            </div>
                                            <div className="col-md-12">

                                                <h4>Address</h4>
                                                <input type="text" placeholder="Address Line 1" />
                                                <input type="text" placeholder="Address Line 2" />

                                            </div>
                                            <div className="col-md-6">

                                                <h4>Town/City</h4>
                                                <input type="text" placeholder="Town/City" />

                                            </div>
                                            <div className="col-md-6">

                                                <h4>ZIP Code</h4>
                                                <input type="number" placeholder="ZIP Code" />

                                            </div>
                                            <div className="col-md-12">

                                                <button type="submit">Save Address</button>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                : null}

                            {isMyAccount.password ?
                                <div id="password" className="tabcontent" >
                                    <div className="my_profile_tab">
                                        <h2>Manage Password</h2>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <h4>Password</h4>
                                                <input type="text" placeholder="Password" />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <h4>Confirm Password</h4>
                                                <input type="text" placeholder="Confirm Password" />
                                            </div>
                                            <div className="col-md-12">

                                                <button type="submit">Save Password</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    )
}
