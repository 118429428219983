import React, { Component } from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { categorySampleOne, categorySampleTwo } from '../components/images'
import { Link } from 'react-router-dom';

export default class ViewSubcategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            productData: [],
            name: ''
        }
    }

    componentDidMount = async () => {
        window.scrollTo(0, 0);
        try{
            const { data } = this.props.location
            console.log("data_param==>", data)
            await this.setState({ name: data.name, productData: data.subCat })
        }
        catch (err) {
            this.props.history.push('/viewcategory')
        }
      
    }



    render() {

        return (
            <div>       
                <Navbar />
                <section className='pt-5 pb-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='subcategory_title'>
                                    <span>Category</span>
                                    <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                                    <span>{this.state.name}</span>
                                </div>
                                {
                                    this.state.productData.map((i) => {
                                        return (<div className='col-12'>
                                            <Link className='subcategory_main_page'
                                                to={{
                                                    pathname: "/viewSubcategory",
                                                    data: i // your data array of objects
                                                }}
                                            >
                                                <img src={`https://demo.smileitsolutions.com/kaaf/${i.image}`} alt='' />
                                                <div class="sub_img_bg_dark"></div>
                                                <h3>{i.name}</h3>
                                            </Link>
                                        </div>)
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        )
    }

}