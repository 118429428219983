import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'


export default function UpdatePassword() {
    return (
        <div>
            <Navbar/>
            <section className="pt-5 pb-5 cart_page">
        <div className="container">
            <div className="row">
                <div className="col-md-5 m-auto">
                    <div className="forgot_password">
                        <h3>Create New Password</h3>
                        <input type="text" placeholder="Create password" />

                        <input type="text" placeholder="Confirm password" />
                        <button type="submit">Update Password</button>
                        <p>Don't want to create? <a href="/">Login here</a></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
            <Footer/>
        </div>
    )
}
