import React, { Component } from 'react'

import Navbar from '../components/Navbar';

import Footer from '../components/Footer';

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { dressOne, dressTwo, dressThree, dressFour, dressFive, dressSix, dressSeven, dressEight, } from '../components/images'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));


const filterData = [
  {
    name: 'Color',
    listArr: ['Red', 'Black', 'Green', 'Blue', 'White', 'Orange', 'Pink']
  },
  {
    name: 'Category',
    listArr: ['Jeans', 'Top', 'Shirts', 'Pants', 'Scarf', 'Sweater']
  },
  {
    name: 'Size',
    listArr: ['UK 10', 'UK 12', 'UK 14', 'UK 16', 'UK 18', 'UK 20']
  },
  {
    name: 'Price',
    listArr: ['Below 499', '500 - 999', '1000 - 1499', '1500 - 1999']
  },
]


// const [_filter,_setFilter]=useState(false)
const classes = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));


export default class Listing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      _filter: false,
      viewData: '',
      list: [],
      paramName:''

    }
  }


  componentDidMount = async () => {

    var name = this.props.match.params.name

    console.log(name)
    this.setState({paramName:name},()=>console.log("data_params==>",this.state.paramName))

    const productData = localStorage.getItem('productData')
    const viewData = localStorage.getItem('viewAll')
    window.scrollTo(0, 0);
    console.log("dataLocal==>", JSON.parse(viewData))
    this.setState({ p_id: JSON.parse(productData).pr_id, viewData: JSON.parse(viewData) }, () => this.ProductList())

  }


  ProductList = () => {



    try {

if(this.state.paramName != "KAAF_Premium" && this.state.paramName != "Latest_Collection" ){

  var variables = {
    "all": "all",
    "search_text": '',
    // "section":"kaaf_pics/community/brands/category/best_seller"
    "shop_id":this.state.viewData.shop_id,            // newest/popular/price_htl /price_lth
    "sort_text": this.state.nf ? "newest" : this.state.pop ? "popular" : this.state.priceL ? "price_lth" : this.state.priceH ? 'price_htl' : ''
}
}
else{
  var data = {
    "all": "all",
    "search_text": '',
    // "section":"kaaf_pics/community/brands/category/best_seller"
    "section": this.state.paramName != undefined ? this.state.paramName === "KAAF_Premium" ? "kaaf_pics" : this.state.paramName === "Latest_Collection" ?'best_seller'  :"kaaf_premium"  : '',
    // newest/popular/price_htl /price_lth
    "sort_text": this.state.nf ? "newest" : this.state.pop ? "popular" : this.state.priceL ? "price_lth" : this.state.priceH ? 'price_htl' : ''

  }

}

   
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");
      var requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        redirect: 'follow'
      };

      console.log("data==>", data)
      fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/home_product_lists.php", requestOptions)
        .then(async resp => {
          // console.log("home_product_lists>>>>>", resp)
          const json = await resp.json()
          console.log("home_product_lists==>", json)
          if (resp.status == 200) {
              console.log("data_SignUpApi====>", json.list)
            // const newResult = resp.data[5].map(item => { return `https://demo.smileitsolutions.com/kaaf/${item}` })

            if (this.state.paramName === "KAAF_Premium") {
              this.setState({
                list: json.list,
                isLoading: false,
                openSearch: false
              }, () => console.log("data_KAAF_Premium==>", this.state.list))
            
            }

            else if (this.state.paramName === "Latest_Collection") {
              this.setState({
                list:  json.best_seller,
                isLoading: false,
                openSearch: false
              }, () => console.log("data_Latest_Collection==>", this.state.list))
            
            }

            else {
              this.setState({
                list: json[0].list,
                isLoading: false,
                openSearch: false
              }, () => console.log("data_list==>", this.state.list))
            }
            

            // this.setState({
            //   list:  this.state.paramName === "KAAF_Premium" ? json[0].list : this.state.paramName === "Latest_Collection" ? json[4].best_seller : this.state.paramName === "kaaf_premium" ? json.premium : json[0].list ,
            //   isLoading: false,
            //   openSearch: false
            // }, () => console.log("data_list==>", this.state.list))
          }
          else {
            this.setState({ isLoading: false, openSearch: false }, () => alert("Invalid credentials please try again."))
          }
        })
    }
    catch (error) {
      console.log("home_product_lists_catch====>", error);
    }
  }
  // search_text = () => {
  //   this.setState({ isLoading: true,productData:[] })
  //   let variables = {
  //       "all": "all",
  //       "search_text": this.state.textSearch,
  //       "section": this.state.viewData!=undefined?this.state.viewData.userData === "kaafPicks" ? "kaaf_pics" :this.state.viewData.userData === "kaaf_premium" ? "kaaf_premium" :'best_seller':'',
  //       "sort_text": this.state.nf ? "newest" : this.state.pop ? "popular" : this.state.priceL ? "price_lth" : this.state.priceH ? 'price_htl' : ''
  //   }
  //   try {
  //       ApiRequest("api/outlet/home_product_lists.php", "POST", variables)
  //           .then(resp => {
  //               console.log("home_product_lists>>>>>", resp)
  //               if (resp.status == 200) {
  //                   //   console.log("data_SignUpApi====>",resp)
  //                   // const newResult = resp.data[5].map(item => { return `https://demo.smileitsolutions.com/kaaf/${item}` })
  //                   this.setState({
  //                       productData: this.state.viewData!=undefined?this.state.viewData.userData === "kaafPicks" ? resp.data.list : this.state.viewData.userData === "bestseller" ? resp.data.best_seller:this.state.viewData.userData === "kaaf_premium" ? resp.data.premium : "":[],
  //                       isLoading: false,
  //                       openSearch: false
  //                   })
  //               }
  //               else {
  //                   this.setState({ isLoading: false, openSearch: false }, () => alert("Invalid credentials please try again."))
  //               }
  //           })
  //   }
  //   catch (error) {
  //       console.log("home_product_lists_catch====>", error);
  //   }
  // }
  onPress = (i) => {
    console.log("data==>", i)
    // to="/viewProduct" 
    localStorage.setItem("productData", JSON.stringify(i))
    this.props.history.push('/viewProduct')
  }



  render() {
    return (
      <div>
        <Navbar />


        <section className="product_listing pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className='col-md-12'>
                <div className='resp_filter_sorting_bar'>
                  <div className='row align-items-center'>
                    <div className='col-6 pr-0'>
                      <div className='filter_title_resp'>
                        <span>Filter</span>
                        <span><i className="fa fa-filter" aria-hidden="true"></i></span>
                      </div>
                    </div>
                    {/* <span className='filter_divider'></span> */}
                    <div className='col-6 pl-0'>
                      <div className='sort_product_title_resp'>
                        <select name="" id="">
                          <option selected disabled value="">Sort By</option>
                          <option selected value="">Best Selling</option>
                          <option selected value="">New Arrivals</option>
                          <option selected value="">Price High To Low</option>
                          <option selected value="">Price Low To High</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="product_filter">
                  <div className="filter_title">
                    <h3>Filter</h3>
                    <span><i className="fa fa-filter" aria-hidden="true"></i></span>
                  </div>



                  <div className={classes.root}>


                    {filterData.map((i) => {
                      return (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography className={classes.heading}>{i.name}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className="content"  >
                              {
                                i.listArr.map((item) => {
                                  return (
                                    <div>
                                      <input type="checkbox" />
                                      <label for=""> {item}</label><br />
                                    </div>


                                  )
                                })
                              }
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      )
                    })}




                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="sorting_bar sorting_resp_hide">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <h3>Showing 1-20 of 199</h3>
                    </div>
                    <div className="col-6">
                      <select name="" id="">
                        <option selected disabled value="">Sort By</option>
                        <option selected value="">Best Selling</option>
                        <option selected value="">New Arrivals</option>
                        <option selected value="">Price High To Low</option>
                        <option selected value="">Price Low To High</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="row">



                  {
 this.state.list.length==0?<a>"No item found"</a> :

                    this.state.list.map((i) => {
                      return (
                        <div onClick={() => this.onPress(i)} className="col-lg-3 col-md-4 col-sm-6 col-6">
                          <div className="prduct_card">
                            <div className="product_heading">
                              <h3>
                                <a>{i.brand}</a>
                              </h3>
                            </div>
                            <a ><img src={`https://demo.smileitsolutions.com/kaaf/uploads/product/${i.prod_image[0]}`} alt="" /></a>
                            <span className="wishlist_icon" style={{ top: "55px" }} >
                              <i className="fa fa-heart" aria-hidden="true">
                              </i></span>
                            <div className="product_price_title">
                              <h3>
                                <a>{i.descriptions != "" ? i.descriptions : 'New arrival'}</a>

                                {/* <a >{i.subcat_name} ({i.brand})</a> */}
                              </h3>
                              <span className="current_price">AED{i.discounted_price ? i.discounted_price : ' 0.0'}</span>
                              {i.sell_price != "" && i.sell_price != null ? <span className="actual_price">AED{i.sell_price}</span> : null}
                              {i.discount != "" && i.discount != null ? <span className="percent_price">{i.discount}%</span> : null}

                            </div>
                          </div>
                        </div>
                      )
                    })
                  }



                </div>

                <div className="pagination_bar">
                  {/* <button>Previous</button>
				<button>Next</button> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className='filter_options_resp'>
            {filterData.map((i) => {
              return (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className={classes.heading}>{i.name}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="content"  >
                      {
                        i.listArr.map((item) => {
                          return (
                            <div>
                              <input type="checkbox" />
                              <label for=""> {item}</label><br />
                            </div>


                          )
                        })
                      }
                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </div> */}
        </section>





        <Footer />
      </div>
    )
  }

}
