import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SectionTitle from "../components/sectionTitle";
import {
  dressOne,
  dressTwo,
  dressThree,
  dressFour,
  dressFive,
  dressSix,
  dressSeven,
  dressEight,
} from "../components/images";

export default function Wishlist() {
  const [productWishlist] = useState({
    list: [
      {
        img: dressTwo,
        currentprice: "$60",
        actualprice: "$90",
        offprice: "10%",
        name: "PINK DITSY FLORAL SPLIT STRAPPY MINI DRESS",
      },
      {
        img: dressThree,
        currentprice: "$70",
        actualprice: "$90",
        offprice: "20%",
        name: "PINK DITSY FLORAL SPLIT STRAPPY MINI DRESS",
      },
      {
        img: dressOne,
        currentprice: "$80",
        actualprice: "$90",
        offprice: "30%",
        name: "PINK DITSY FLORAL SPLIT STRAPPY MINI DRESS",
      },
      {
        img: dressFour,
        currentprice: "$90",
        actualprice: "$90",
        offprice: "40%",
        name: "PINK DITSY FLORAL SPLIT STRAPPY MINI DRESS",
      },
      {
        img: dressFive,
        currentprice: "$20",
        actualprice: "$90",
        offprice: "50%",
        name: "PINK DITSY FLORAL SPLIT STRAPPY MINI DRESS",
      },
      {
        img: dressSix,
        currentprice: "$50",
        actualprice: "$90",
        offprice: "60%",
        name: "PINK DITSY FLORAL SPLIT STRAPPY MINI DRESS",
      },
      {
        img: dressSeven,
        currentprice: "$10",
        actualprice: "$90",
        offprice: "70%",
        name: "PINK DITSY FLORAL SPLIT STRAPPY MINI DRESS",
      },
      {
        img: dressEight,
        currentprice: "$40",
        actualprice: "$90",
        offprice: "80%",
        name: "PINK DITSY FLORAL SPLIT STRAPPY MINI DRESS",
      },
    ],
  });

  return (
    <div>
      <Navbar />

      <section className="pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="row">
              <SectionTitle text={"My Wishlist"} />
            </div>

            <div className="row">
              {productWishlist.list.map((i) => {
                return (
                  <div className="col-lg-25 col-md-4 col-sm-6 col-6">
                    <div className="product_heading">
                      <h3>
                        <a>AZIZIA</a>
                      </h3>
                    </div>
                    <div className="prduct_card">
                      <a href="/">
                        <img src={i.img} alt="" />
                      </a>
                      <span className="wishlist_icon">
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </span>
                      <div className="product_price_title">
                        <h3>
                          <a href="/">{i.name}</a>
                        </h3>
                        <span className="current_price">{i.currentprice}</span>
                        <span className="actual_price">{i.actualprice}</span>
                        <span className="percent_price">{i.offprice}</span>
                        <button className="wish_cart_btn" type="submit">                          
                          Add To Cart{"  "}<i
                            className="fa fa-shopping-cart"
                            aria-hidden="true"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}
