import React from 'react'

import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SectionTitle from '../components/sectionTitle'


export default function TermsConditions() {
    return (
        <div>
            <Navbar/>
            <section className="pt-3 pb-5">
        <div className="container">
            <div className="row">
            <SectionTitle text="TERMS & CONDITIONS"/>
                <div className="col-md-10 m-auto">
                    
                    <div className="terms_privacy_content">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore molestias tempore ab, minus,
                            maxime laudantium fugit cum nisi <a href="/">ipsa consectetur</a> asperiores, mollitia inventore est. Vero
                            repellendus natus harum corrupti nulla, odio unde et! Pariatur, impedit minus tempore magni
                            repellat a quae nulla fugiat molestiae necessitatibus nihil consectetur! Itaque, soluta
                            assumenda a amet aperiam voluptate porro.</p>

                        <ul>
                            <li>Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                            <li>Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                            <li>Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                            <li>Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                            <li>Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                            <li>Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                        </ul>

                        <h1>Lorem ipsum dolor sit amet, consectetur adipisicing.</h1>

                        <ol>
                            <li>Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                            <li>Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                            <li>Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                            <li>Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                            <li>Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                            <li>Lorem, ipsum dolor sit amet consectetur adipisicing.</li>
                        </ol>

                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ipsum atque illum fuga saepe
                            adipisci dolorem necessitatibus voluptatum, voluptatibus eius laboriosam pariatur nesciunt!
                        </p>
                        <h2>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h2>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores saepe minima consequuntur odio
                            deserunt ex. Sit labore sequi, illo dolorem vitae deleniti veniam nemo molestiae corrupti
                            impedit placeat? Enim quam ullam quod nesciunt ex facere incidunt. Deserunt blanditiis quia, rem
                            asperiores reiciendis saepe architecto.</p>
                        <h3>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora.</h3>
                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Maiores saepe minima consequuntur odio
                            deserunt ex. Sit labore sequi, illo dolorem vitae deleniti veniam nemo molestiae corrupti
                            impedit placeat? Enim quam ullam quod nesciunt ex facere incidunt. Deserunt blanditiis quia, rem
                            asperiores reiciendis saepe architecto.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
            <Footer/>
        </div>
    )
}
