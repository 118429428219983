import React, { Component } from 'react'
//-----Import Script-----------//
import useScript from '../components/Script';
import ReactImageMagnify from 'react-image-magnify';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { shoppingOne, shoppingTwo, shoppingThree } from '../components/images';
import { Rating } from 'react-simple-star-rating';
import SectionTitle from '../components/sectionTitle';
import { Link } from 'react-router-dom';
import swal from 'sweetalert';
const isImage = "https://payalord.github.io/xZoom/images/gallery/preview/01_b_car.jpg"
const listArr = [
  { img: shoppingOne, name: 'Polyester' },
  { img: shoppingTwo, name: 'Satin' },
  { img: shoppingThree, name: 'Cotton' }
]

export default class ViewProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kaafArrival: [],
      communityData: [],
      brandData: [],
      categoriesData: [],
      bestSeller: [],
      p_id: '',
      brand: '',
      subcat_name: '',
      ratings: '',
      images: [],
      similarData: [],
      fabricId: '',
      isSelected: -1,
      data_type: [],
      size_desc: '',
      like:1
    }
  }


  componentDidMount = () => {
    const productData = localStorage.getItem('productData')
    window.scrollTo(0, 0);
    console.log("dataLocal==>", JSON.parse(productData))
    this.setState({ p_id: JSON.parse(productData).pr_id }, () => this.ProductDetails())

  }
  ProductDetails = () => {

    try {
      var data = {
        "pr_id": this.state.p_id,
        "jwt": "",
      }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");
      var requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        redirect: 'follow'
      };
      fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/product_detail.php", requestOptions)
        .then(async resp => {
          const json = await resp.json()
          console.log("data==>", json)
          if (resp.status == 200) {
            window.scrollTo(0, 0);
            console.log("data_SignUpApi====>", json)
            // const newResult = resp.data[5].map(item => { return `https://demo.smileitsolutions.com/kaaf/${item}` })
            var sizeDesc = {
              qnty1: 0,
              qnty2: 0,
              qnty3: 0,
              qnty4: 0,
            }
            this.setState({
              Data: json.list,
              brand: json.brand,
              customise_order: json.customise_order,
              subcat_name: json.subcat_name,
              sell_price: json.sell_price,
              size_desc: json.size_desc != null ? json.size_desc : sizeDesc,
              isLoading: false,
              images: json.prod_image,
              pImages: json.prod_image[0],
              descriptions: json.descriptions,
              designer: json.designer != "" ? json.designer : "-",
              photographer: json.photographer != "" ? json.photographer : '-',
              model_name: json.model_name != "" ? json.model_name : '-',
              makeup_artist: json.makeup_artist != "" ? json.makeup_artist : '-',
              prod_code: json.prod_code,
              cat_name: json.cat_name,
              like: json.is_wishlist == "" || json.is_wishlist == 0 ? false : true,
              similarData: json.similar_product.length != 0 ? json.similar_product.list : [],
              ratings: json.ratings,
              data_type: json.fabric_desc.list != null ? json.fabric_desc.list : [],
              discounted_price: json.discounted_price,
              discount: json.discount,
              paramsDesigner: json.designer != "" ? json.designer.trim().replace(/\s+/g, "_") : "-",
              paramsPhotographer: json.photographer != "" ? json.photographer.trim().replace(/\s+/g, "_") : "-",
              paramsModel_name: json.model_name != "" ? json.model_name.trim().replace(/\s+/g, "_") : "-",
              paramsMakeup_artist: json.makeup_artist != "" ? json.makeup_artist.trim().replace(/\s+/g, "_") : "-",





            })
            if (json.cat_name === "Appreal") {
              this.setState({ small: true, medium: false, large: false, xlarge: false, })
            }
            else if (json.cat_name === "Appreal") {
            }
          }
          else {
            this.setState({ isLoading: false }, () => alert("Something went wrong please try again."))
          }
        }
        )
        .catch(errr => {
          this.setState({ isLoading: false })
        });




      // ApiRequest("api/outlet/product_detail.php", "POST", variables)
      //     .then(resp => {
      //         console.log("leaderboard_list>>>>>", resp)
      //         if (resp.status == 200) {
      //             console.log("data_SignUpApi====>", resp)
      //             // const newResult = resp.data[5].map(item => { return `https://demo.smileitsolutions.com/kaaf/${item}` })
      //             var sizeDesc = {
      //                 qnty1: 0,
      //                 qnty2: 0,
      //                 qnty3: 0,
      //                 qnty4: 0,
      //             }
      //             this.setState({
      //                 Data: resp.data.list,
      //                 brand: resp.data.brand,
      //                 customise_order: resp.data.customise_order,
      //                 subcat_name: resp.data.subcat_name,
      //                 sell_price: resp.data.sell_price,
      //                 size_desc: resp.data.size_desc != null ? resp.data.size_desc : sizeDesc,
      //                 isLoading: false,
      //                 images: resp.data.prod_image,
      //                 descriptions: resp.data.descriptions,
      //                 designer: resp.data.designer != "" ? resp.data.designer : "-",
      //                 photographer: resp.data.photographer != "" ? resp.data.photographer : '-',
      //                 model_name: resp.data.model_name != "" ? resp.data.model_name : '-',
      //                 makeup_artist: resp.data.makeup_artist != "" ? resp.data.makeup_artist : '-',
      //                 prod_code: resp.data.prod_code,
      //                 cat_name: resp.data.cat_name,
      //                 like: resp.data.is_wishlist == "" || resp.data.is_wishlist == 0 ? false : true,
      //                 similarData: resp.data.similar_product.length!=0?resp.data.similar_product.list:[],
      //                 ratings: resp.data.ratings,
      //                 data_type: resp.data.fabric_desc.list != null ? resp.data.fabric_desc.list : [],
      //                 discounted_price: resp.data.discounted_price,
      //                 discount: resp.data.discount
      //             })
      //             if (resp.data.cat_name === "Appreal") {
      //                 this.setState({ small: true, medium: false, large: false, xlarge: false, })
      //             }
      //             else if (resp.data.cat_name === "Appreal") {
      //             }
      //         }
      //         else {
      //             this.setState({ isLoading: false }, () => alert("Something went wrong please try again."))
      //         }
      //     })
    }
    catch (error) {
      console.log("leaderboard_list_catch====>", error);
    }
  }
  optionSelection = (item, index) => {

    if (index != this.state.isSelected) {
      console.log("data_different===>", item)
      this.setState({
        fabricId: item.fabric_id,
        isSelected: index,
        Selected: index,
      })
    }
    else if (index === this.state.isSelected) {
      console.log("data_Same==>")
      this.setState({
        Selected: -1,
        isSelected: -1,
        fabricId: " ",
      })
    }


  }

  Add_to_cart = () => {

    try {
      var data = {
        "jwt": "",
        "pr_id": this.state.p_id,
        "pr_quantity": "1",
        "fabric_id": this.state.fabricId,
        "size": this.state.cat_name === "Footwear" ? this.state.selectedSize
          :
          this.state.cat_name === "Appreal" ? this.state.small ? "S" : this.state.medium ? "M" : this.state.large ? "L" : this.state.xlarge ? "XL" : "" : ''
        // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9leGFtcGxlLm9yZyIsImF1ZCI6Imh0dHA6XC9cL2V4YW1wbGUuY29tIiwiaWF0IjoxMzU2OTk5NTI0LCJuYmYiOjEzNTcwMDAwMDAsImRhdGEiOnsiaWQiOiIzIiwidXNlcl90eXBlIjoiQm91dGlxdWUiLCJlbWFpbCI6ImF6aXppYUBnbWFpbC5jb20ifX0.6dg2coaYq6avqS2UOJCojT50c8MRzrIQFPxKCkhnxfc",
      }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");
      var requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        redirect: 'follow'
      };
      console.log("data==>", requestOptions)
      fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/add_cart.php", requestOptions)
        .then(async resp => {
          const json = await resp.json()
          console.log("data==>", json)
          console.log("add_cart>>>>>", resp)
          if (json.status == 200) {
            this.setState({
              // productData: json.data.list,
              isLoading: false,
            }, () => swal({
                    title: "Alert",
                    text: json.message,
                    icon: "success",
                    button: "Ok",
                  }))

          }
          else if (json.status == 201) {
            this.setState({
              productData: [],
              isLoading: false,
            }, () => swal({
                    title: "Alert",
                    text: json.message,
                    icon: "error",
                    button: "Ok",
                  }))

          }
          else if (json.status == 401) {
            this.setState({
              isLoading: false,
            }, () => alert("Please login.")
           
            )
          }
          else {
            this.setState({ isLoading: false }, () => alert("Something went wrong please try again."))
          }
        })
    }
    catch (error) {
      console.log("home_product_lists_catch====>", error);
    }
  }







  Add_to_wishlist = () => {

    try {
      var data = {
        "jwt":"",
        //  "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9leGFtcGxlLm9yZyIsImF1ZCI6Imh0dHA6XC9cL2V4YW1wbGUuY29tIiwiaWF0IjoxMzU2OTk5NTI0LCJuYmYiOjEzNTcwMDAwMDAsImRhdGEiOnsiaWQiOiIxMTUiLCJ1c2VyX3R5cGUiOm51bGwsIm5hbWUiOm51bGx9fQ.E0RjGs700WvO3aFnTs5P2pk8j5oVOmCQWb9tpd70DyE"        ,
        "pr_id": this.state.p_id,
        "pr_quantity": "1",
        "fabric_id": this.state.fabricId,
        "wishlist": this.state.like ? "1" : "0",
        "size": this.state.cat_name === "Footwear" ? this.state.selectedSize
          :
          this.state.cat_name === "Appreal" ? this.state.small ? "S" : this.state.medium ? "M" : this.state.large ? "L" : this.state.xlarge ? "XL" : "" : ''
        // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9leGFtcGxlLm9yZyIsImF1ZCI6Imh0dHA6XC9cL2V4YW1wbGUuY29tIiwiaWF0IjoxMzU2OTk5NTI0LCJuYmYiOjEzNTcwMDAwMDAsImRhdGEiOnsiaWQiOiIzIiwidXNlcl90eXBlIjoiQm91dGlxdWUiLCJlbWFpbCI6ImF6aXppYUBnbWFpbC5jb20ifX0.6dg2coaYq6avqS2UOJCojT50c8MRzrIQFPxKCkhnxfc",
    
      }
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "text/plain");
      var requestOptions = {
        method: 'POST',
        body: JSON.stringify(data),
        redirect: 'follow'
      };
      console.log("data==>", requestOptions)
      fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/product_detail.php", requestOptions)
        .then(async resp => {
          const json = await resp.json()
          console.log("data==>", json)
          console.log("add_cart>>>>>", resp.status)
          if (resp.status == 200) {
            this.setState({
              // productData: resp.data.list,
              like:"1",
              isLoading: false,
            }, () => swal({
                    title: "Alert",
                    text: json.message,
                    icon: "success",
                    button: "Ok",
                  }))

          }
          else if (resp.status == 201) {
            this.setState({
              productData: [],
              isLoading: false,
            }, () => swal({
                    title: "Alert",
                    text: json.message,
                    icon: "error",
                    button: "Ok",
                  }))

          }
          else if (resp.status == 401) {
            this.setState({
              isLoading: false,
            },() => swal({
              title: "Alert",
              text: json.message,
              icon: "error",
              button: "Ok",
            })
           
            )
          }
          else {
            this.setState({ isLoading: false }, () => alert("Something went wrong please try again."))
          }
        })
    }
    catch (error) {
      console.log("home_product_lists_catch====>", error);
    }
  }





  onPress = (i) => {
    console.log("data==>", i)

    // this.setState({
    //   p_id:i.pr_id
    // },()=>console.log("dataSelect==>"))

    // to="/viewProduct" 
    localStorage.setItem("productData", JSON.stringify(i))
    // this.props.history.push('/viewProduct')
    window.location.reload();
    // window.location.reload();
  }




  render() {
    return (
      <div>
        <Navbar />

        <section id="magnific" className="pt-5 pb-5">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-sm-5">
                <div className="fluid__image-container">
                  <ReactImageMagnify {...{
                    smallImage: {
                      alt: 'Wristwatch by Ted Baker London',
                      isFluidWidth: true,
                      src: this.state.pImages,
                    },
                    largeImage: {
                      src: this.state.pImages,
                      width: 1200,
                      height: 1800
                    },
                    isHintEnabled: true,
                    shouldHideHintAfterFirstActivation: false
                  }} />
                </div>


                {/* <img className="xzoom5" id="xzoom-magnific" src="https://payalord.github.io/xZoom/images/gallery/preview/01_b_car.jpg" xoriginal="https://payalord.github.io/xZoom/images/gallery/original/01_b_car.jpg" title="The description goes here" style={{width: "100%"}}/> */}

                {
                  this.state.images.map((i) => {
                    return (
                      <div className="xzoom-container product_preview">
                        <div onClick={() => { this.setState({ pImages: i }) }} className="xzoom-thumbs">
                          <a><img className="xzoom-gallery5 xactive" width="80"
                            src={i} xpreview={i} title="The description goes here" /></a>
                        </div>
                      </div>
                    )
                  })
                }


              </div>
              <div className="col-md-6 col-sm-7">

                <div className="product_brief">
                  <a href="#" className="brand_name_product">{this.state.brand}</a>
                  <h3>{this.state.subcat_name}({this.state.brand})</h3>
                  <div className="product_preview_pricing">
                    <span className="product_price_text">Price: </span>
                    <span className="product_current_price"> AED{this.state.discounted_price}</span>
                    <span className="product_actual_price">AED {this.state.sell_price}</span>
                    <span className="product_percent_price">{this.state.discount}% Off </span>
                  </div>

                  <div className="product_rating">
                    <div className='App'>
                      <Rating
                        ratingValue={3.5}
                        readonly={true}
                        size={20}
                        transition
                        allowHalfIcon
                      />
                    </div>
                  </div>
                </div>
                <h3 className="choose_size">Choose Size:</h3>
                {/* <div className="product_size"> */}

                {/* {
  this.state.size_desc.map((item,index)=>{
    return(
      <span>XS</span>
    )
  })
} */}


                {/* </div> */}


                {

                  this.state.size_desc != [] ?
                    <p className='out_of_stock' >{"Out of stock"}</p>
                    :
                    this.state.cat_name === "Appreal" ?
                      <div className="product_size">
                        <span>XS</span>
                        <span>S</span>
                        <span>M</span>
                        <span>L</span>
                        <span>XL</span>
                        <span>XXL</span>
                        <span>3XL</span>
                      </div>
                      : null
                }



                {this.state.data_type.length != 0 ?
                  <div>
                    <h3 className="choose_size">Choose Textile:</h3>
                    <div class="product_textile_box">
                      {
                        this.state.data_type.map((item, index) => {
                          return (
                            <span className="product_textile" style={{ backgroundColor: this.state.isSelected == index ? "#000" : "#fff" }}  >
                              <a onClick={() => this.optionSelection(item, index)}>
                                <img src={item.fabric_image} alt="" />
                                <h3 style={{ color: this.state.isSelected == index ? "#fff" : "#000" }} > <a onClick={() => this.optionSelection(item, index)}> {item.fabric_code} </a></h3>
                              </a>
                            </span>

                          )
                        })
                      }
                    </div>
                  </div>
                  : null
                }

                <div className="product_type_btn">
                  <span class="product_type_one">Product Type</span>
                  <span class="product_type_two">Readymade</span>
                </div>

                <div className="product_description">
                  <p>{this.state.descriptions}</p>
                  {/* <ul>
                <li>Care Instructions: Machine Wash</li>
                <li>Fit Type: Loose Fit</li>
                <li>Vortex Viscose 96% and Spandex 4%</li>
                <li>Solid, Loose fit</li>
                <li>Care Instructions: Machine Wash</li>
                <li>Fit Type: Loose Fit</li>
                <li>Vortex Viscose 96% and Spandex 4%</li>
                <li>Machine wash</li>
              </ul> */}
                </div>

                <div className="artists_row_product">
                  <div className="row pb-3">
                    <div className="col-lg-6 col-md-12 justify-content-center">
                      <Link to={'/' + this.state.paramsDesigner} className="artists_box">
                        <div className="artists_category">
                          Fashion Designer
                        </div>
                        <span className="artists_center">:</span>
                        <div className="artists_name">
                          {this.state.designer}
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-6 col-md-12 justify-content-center">
                      <Link to={"/" + this.state.paramsPhotographer} className="artists_box">
                        <div className="artists_category">
                          Photographer
                        </div>
                        <span className="artists_center">:</span>
                        <div className="artists_name">
                          {this.state.photographer}
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-6 col-md-12 justify-content-center">
                      <Link to={"/" + this.state.paramsModel_name} className="artists_box">
                        <div className="artists_category">
                          Model
                        </div>
                        <span className="artists_center">:</span>
                        <div className="artists_name">
                          {this.state.model_name}
                        </div>
                      </Link>
                    </div>
                    <div className="col-lg-6 col-md-12 justify-content-center">
                      <Link to={'/' + this.state.paramsMakeup_artist} className="artists_box">
                        <div className="artists_category">
                          Makeup Artist
                        </div>
                        <span className="artists_center">:</span>
                        <div className="artists_name">
                          {this.state.makeup_artist}
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="row">

                  {this.state.size_desc.length != 0 ?
                    <div className="col-6 pe-1">
                      <div className="preview_button">
                        <button type="submt" onClick={() => this.Add_to_cart()} ><i className="fa fa-shopping-cart" aria-hidden="true"></i> Add To Cart</button>
                      </div>
                    </div>
                    : null}




                  <div className="col-6">
                    <div className="preview_wish_button">
                      <button onClick={()=>this.Add_to_wishlist()}  type="submt"><i className="fa fa-heart" aria-hidden="true"></i> Add To Wishlist</button>
                    </div>
                  </div>





                </div>


              </div>

            </div>
          </div>
        </section>


        <section className="outfit_collections pt-5 pb-5">
          <div className="container">
            <div className="row">
              <SectionTitle text={"Suggested Product"} />
            </div>
            <div className="row">
              {
                this.state.similarData.map((i) => {
                  return (
                    <div onClick={() => this.onPress(i)} className="col-lg-25 col-md-4 col-sm-6 col-6">
                      <div className="product_heading">
                        <h3>
                          <a>{i.brand}</a>
                        </h3>
                      </div>
                      <div className="prduct_card">
                        <a>
                          <img src={`https://demo.smileitsolutions.com/kaaf/uploads/product/${i.prod_image[0]}`} alt="" />
                        </a>
                        {/* <span className="wishlist_icon"><i className="fa fa-heart" aria-hidden="true"></i></span> */}
                        <div className="product_price_title">
                          <h3>
                            <a>{i.subcat_name}({i.brand})</a>
                          </h3>
                          <span className="current_price">ADE {i.sell_price}</span>
                          {/* <span className="actual_price">{i.sell_price}</span>
                                      <span className="percent_price">{i.discount}%</span> */}

                          <div class="product_reviews_stars">
                            <div className='App'>
                              <Rating
                                ratingValue={i.ratings}
                                readonly={true}
                                size={20}
                                transition
                                allowHalfIcon
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }


            </div>
          </div>
        </section>



        <Footer />

      </div>
    )
  }

}
