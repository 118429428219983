import React from 'react'
import { Link } from 'react-router-dom';
import { googlePlay, appleStore, paymentMethod, } from "../components/images";

export default function Footer() {
  return (
    <div>

      <section className="pledge">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-6">
              <div className="pledge_box border_box">
                <i className="fa fa-plane" aria-hidden="true"></i>
                <h3>All UAE Cash On Delivery Available</h3>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="pledge_box border_box">
                <i className="fa fa-phone" aria-hidden="true"></i>
                <h3>24/7 Online Customer Support Available</h3>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="pledge_box border_box">
                <i className="fa fa-star" aria-hidden="true"></i>
                <h3>Premium Quality And Genuine Proucts</h3>
              </div>
            </div>
            <div className="col-md-3 col-6">
              <div className="pledge_box">
                <i className="fa fa-lock" aria-hidden="true"></i>
                <h3>100% Secure Payment Gateway System</h3>
              </div>
            </div>
          </div>
        </div>
      </section>


      <footer className="footer_bg_dark pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <div className="footer_column">
                <h3>Company</h3>
                <ul>
                  <li><a href="/">Home</a></li>
                  <li><a href="/">About Us</a></li>
                  <li><a href="/contactUs">Contact Us</a></li>
                  <li><a href="/termsConditions">Terms & Conditions</a></li>
                  <li><a href="/privacyPolicy">Privacy Policy</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="footer_column">
                <h3>My Account</h3>
                <ul>
                  <li><a href="/myAccount">Profile</a></li>
                  <li><a href="/myCart">Orders</a></li>
                  <li><a href="/">Cart</a></li>
                  <li><a href="/wishlist">Wishlist</a></li>
                  <li><a href="/">Help</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="footer_column">
                <h3>Shop Now</h3>
                <ul>
                  <li><a href="/">Women's</a></li>
                  <li><a href="/">Girls</a></li>
                  <li><a href="/">Men's</a></li>
                  <li><a href="/">Summer</a></li>
                  <li><a href="/">Winter</a></li>
                </ul>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="footer_column">
                <h3>Stay Connected</h3>
                <div className="follow_us">
                  <span className="facebook_icon"><a href="/"><i className="fa fa-facebook" aria-hidden="true"></i></a></span>
                  <span className="instagram_icon"><a href="/"><i className="fa fa-instagram" aria-hidden="true"></i></a></span>
                  <span className="twitter_icon"><a href="/"><i className="fa fa-twitter" aria-hidden="true"></i></a></span>
                  <span className="youtube_icon"><a href="/"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></span>
                </div>
                <h3>Get The App</h3>
                <a href="/"
                ><img src={googlePlay} alt=""
                  /></a>
                <a href="/"><img src={appleStore} alt="" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer_credit">
          {/* <img src={paymentMethod} alt="" /> */}
          <p>
            Copyright © KAAF International
            {/* | Developed by
            <a href="http://smileitsolutions.com/">Smile IT Solutions</a> */}
          </p>
        </div>
      </footer>

      <footer className="footer_mob_resp">
        {/* <div className="row"> */}
        {/* <div className="col-12"> */}
        <div className="footer_mob_tabs">
          <div className="footer_mob_tab">
            <input type="checkbox" id="chck1" />
            <label className="footer_mob_tab-label" for="chck1">Company</label>
            <ul className="footer_mob_tab-content">
              <li><a href="/">Home</a></li>
              <li><a href="/">About Us</a></li>
              <li><a href="/contactUs">Contact Us</a></li>
              <li><a href="/termsConditions">Terms & Conditions</a></li>
              <li><a href="/privacyPolicy">Privacy Policy</a></li>
            </ul>
          </div>
          <div className="footer_mob_tab">
            <input type="checkbox" id="chck2" />
            <label className="footer_mob_tab-label" for="chck2">My Account</label>
            <ul className="footer_mob_tab-content">
              <li><a href="/myAccount">Profile</a></li>
              <li><a href="/myCart">Orders</a></li>
              <li><a href="/">Cart</a></li>
              <li><a href="/wishlist">Wishlist</a></li>
              <li><a href="/">Help</a></li>
            </ul>
          </div>
          <div className="footer_mob_tab">
            <input type="checkbox" id="chck3" />
            <label className="footer_mob_tab-label" for="chck3">Shop Now</label>
            <ul className="footer_mob_tab-content">
              <li><a href="/">Women's</a></li>
              <li><a href="/">Girls</a></li>
              <li><a href="/">Men's</a></li>
              <li><a href="/">Summer</a></li>
              <li><a href="/">Winter</a></li>
            </ul>
          </div>

          <div className="footer_mob_tab">
            <div className=" footer_column_resp">
              <h3>Stay Connected</h3>
              <div className="follow_us">
                <span className="facebook_icon"><a href="/"><i className="fa fa-facebook" aria-hidden="true"></i></a></span>
                <span className="instagram_icon"><a href="/"><i className="fa fa-instagram" aria-hidden="true"></i></a></span>
                <span className="twitter_icon"><a href="/"><i className="fa fa-twitter" aria-hidden="true"></i></a></span>
                <span className="youtube_icon"><a href="/"><i className="fa fa-youtube-play" aria-hidden="true"></i></a></span>
              </div>
              <h3>Get The App</h3>
              <a href="/" className="play_store_resp">
                <img src={googlePlay} alt="" />
              </a>
              <a href="/"><img src={appleStore} alt="" /></a>
            </div>
          </div>
          <div className="footer_credit">
            {/* <img src={paymentMethod} alt="" /> */}
            <p>
              Copyright © KAAF International
              {/* | Developed by
              <a href="http://smileitsolutions.com/">Smile IT Solutions</a> */}
            </p>
          </div>
        </div>
      </footer>
    </div>

  )
}
