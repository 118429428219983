import React from "react"



export default function SectionTitle(props) {
  return (
    <div className="col-12 mb-3">
      <div onClick={props.onClick} className="section_title">
        <h3>{props.text}<span> {props.textHeading ? null : "View all"}</span></h3>
        {/* <div className="section_separator"></div> */}
      </div>
    </div>
  )
}
