import React, { Component } from 'react'
import Navbar from '../components/Navbar';
import HomeSlider from '../components/HomeSlider';
import ShopCategory from '../components/shopCategory';
import Footer from '../components/Footer';
import BrandSlider from '../components/BrandSlider';
// import SeasonSale from '../components/SeasonSale';
import ProductCard from '../components/ProductCard';
import { featuredAdvert } from '../components/images';
import CommunityHome from '../components/communityHome';
import HomeBrands from '../components/HomeBrands';
import HiringBanner from '../components/HiringBanner';
import { Link } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import SectionTitle from '../components/sectionTitle';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { mainBannerDress, registration, By_Yasmin } from "../components/images";
export default class TheModestyShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kaafArrival: [],
            communityData: [],
            brandData: [],
            categoriesData: [],
            bestSeller: [],
            images: [],
            page1: true,
            page2: false,
            page3: false,
            page4: false,
        }
    }
    render() {
        return (
            <div>
                <Navbar />
                <section class="tms">
                    <div class="container">
                        <div class="row m-auto">
                            {/* <!-- <div class="col-md-2"></div> --> */}
                            <div class="col-md-1"></div>
                            <div class="col-lg-10">
                                <div class="themodestyshow">
                                    <div class="heading_content">
                                        <h1><strong>The Modesty Show</strong></h1>
                                        <h3>Modesty is the essence of beauty and it is our effort to enhance this quality
                                            through
                                            our
                                            fashion show.</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1"></div>
                            <div class="themodestyshow-2">
                                <div class="row">
                                    {/* <!-- <div class="col-md-1"></div> --> */}
                                    <div class="col-lg-6">
                                        <div class="themodestyshow-3">
                                            <p> Coming from traditional culture and living in a place which has similar
                                                atmosphere I was impressed and influenced by the way, this region has
                                                amalgamated ,beautiful designs with the modesty of the woman.</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="themodestyshow-3 tms-3">
                                            <p>Moreover , Many deserving talented beautiful models had been waiting for
                                                such
                                                platform to participate in, where they could exhibit their quint
                                                essential
                                                talent.</p>
                                        </div>
                                    </div>
                                    {/* <!-- <div class="col-md-1"></div> --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section class="sponser_main_main">
                    {/* <!-- <div class="parallax"></div> --> */}
                    <div class="sponser_main">
                        <div class="sponser">
                            <div class="row">
                                <div class="col-md-1"></div>
                                <div class="col-md-10">
                                    <div class="container">
                                        <h1><strong>Sponser</strong></h1>
                                        <div class="themodestyshow_content">
                                            <h1>Fashion and You <img src={By_Yasmin} alt="" /></h1>
                                            <p>Yasmin, founder of Fashion and youu believed in the essence of modesty that defined
                                                beauty. We believe in equal opportunity for all walking our ramps beyond class,
                                                color,
                                                creed or race.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1"></div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- <div class="parallax"></div>  --> */}
                    {/* <!-- </section>
        <section class="benefits"> --> */}
                    <div class="">
                        <div class="designer_benefits">
                            <div class="row">
                                <div class="col-md-12">
                                    <h1>Our Designers Prime Benefits</h1>
                                    <h3>Special discount to register with KAAF, the over arching umbrella for fashion.</h3>
                                </div>
                                <div class="col-md-4">
                                    <p class="first">
                                        <i class="fa fa-check-circle" aria-hidden="true"></i><span> Opportunity to promote your
                                            design the twin platform of <strong>‘Fashion and you +
                                                KAAF’.</strong></span>
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <p class="second">
                                        <i class="fa fa-check-circle" aria-hidden="true"></i><span> Extensive media exposure
                                            through
                                            various social media and digital media
                                            platforms.</span>
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <p class="third">
                                        <i class="fa fa-check-circle" aria-hidden="true"></i><span> Pathway to connect to the
                                            industries talented makeup artist and models
                                            Through KAAF.</span>
                                    </p>
                                </div>
                                <div class="col-md-12">
                                    <h3>
                                        Designers are assured to be provided </h3>
                                </div>
                                <div class="col-md-4">
                                    <p>
                                        <i class="fa fa-check-circle" aria-hidden="true"></i><span>12 Professional models to
                                            showcase their collections. </span>
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <p class="fifth">
                                        <i class="fa fa-check-circle" aria-hidden="true"></i><span>Professional videographers
                                            and
                                            photographers.</span>
                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <p class="sixth">
                                        <i class="fa fa-check-circle" aria-hidden="true"></i><span>Professional makeup
                                            artists.</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- <div class="parallax"></div>  --> */}
                </section>
                {/* <!-- </div> --> */}
                {
                    this.state.page1 ?
                        <section class="new_1 mb-3">
                            <div class="container">
                                <form action="" class="regisform">
                                    <div class="desiner_registration">
                                        <img src={registration} alt="" />
                                    </div>
                                    {/* <!-- One "tab" for each step in the form: --> */}
                                    <div class="tab">Designer Details:
                                        <div class="row">
                                            <div class="col-md-6 ">
                                                <div class="name">
                                                    <p><input type="text" placeholder="Name..." oninput="this.className = ''" /></p>
                                                    <p><input type="number" placeholder="Mobile Number..."
                                                        oninput="this.className = ''" />
                                                    </p>
                                                </div>
                                                <div class="name_1">
                                                    <p><input type="text" placeholder="Country..." oninput="this.className = ''" />
                                                    </p>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="name">
                                                    <p><input type="email" placeholder="Email..." oninput="this.className = ''" /></p>
                                                    <p><input type="text" placeholder="Brand..." oninput="this.className = ''" /></p>
                                                    <p><input type="text" placeholder="Address..." oninput="this.className = ''" />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="newsubmit" style={{ display: "inline-flex" }}>
                                            <button onClick={() => this.setState({ page2: true, page1: false, page3: false, page4: false })} type="button" >Next</button>
                                        </div>
                                    </div>
                                </form>
                            </div>


                            <div class="col-lg-12 col-md-12 col-sm-12">
                                <div class="step_main" style={{ textAlign: "center", marginTop: "20px" }}>
                                    <span onClick={() => this.setState({ page1: true, page2: false, page3: false, page4: false })} class={this.state.page1 ? "step.active" : "step"}>1</span>
                                    <span onClick={() => this.setState({ page1: false, page2: true, page3: false, page4: false })} class={this.state.page2 ? "step.active" : "step"}>2</span>
                                    <span onClick={() => this.setState({ page1: false, page2: false, page3: true, page4: false })} class={this.state.page3 ? "step.active" : "step"}>3</span>
                                    <span onClick={() => this.setState({ page1: false, page2: false, page3: false, page4: true })} class={this.state.page4 ? "step.active" : "step"}>4</span>
                                </div>
                            </div>

                        </section>
                        : this.state.page2 ?
                            <section class="new_1 mb-3">
                                <div class="container">
                                    <form action="" class="regisform">
                                        <div class="desiner_registration">
                                            <img src={registration} alt="" />
                                        </div>
                                        {/* <!-- One "tab" for each step in the form: --> */}
                                        <div class="tab">Categories & Slot:
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="name_1">
                                                        <p>
                                                            <select>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">
                                                                    Select
                                                                    types of
                                                                    products</option>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">
                                                                    Designer Clothing
                                                                </option>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">
                                                                    Accessories
                                                                </option>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">
                                                                    Others
                                                                </option>
                                                            </select>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class=" col-md-6">
                                                    <div class="name_1">
                                                        <p>
                                                            <select>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">
                                                                    Select
                                                                    your slot
                                                                </option>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">
                                                                    General Slot
                                                                </option>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">
                                                                    Premium Slot
                                                                </option>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">
                                                                    Opening Slot
                                                                </option>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">
                                                                    Closing Slot
                                                                </option>
                                                            </select>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="name_1">
                                                        <p>
                                                            <select>
                                                                <option value="" oninput="this.className = ''">Female Models
                                                                </option>
                                                                <option value="" oninput="this.className = ''">1</option>
                                                                <option value="" oninput="this.className = ''">2</option>
                                                                <option value="" oninput="this.className = ''">3</option>
                                                                <option value="" oninput="this.className = ''">4</option>
                                                            </select>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class=" col-md-6 ">
                                                    <div class="name">
                                                        <p>
                                                            <select>
                                                                <option value="" oninput="this.className = ''">Male Models</option>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">1
                                                                </option>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">2
                                                                </option>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">3
                                                                </option>
                                                                <option value="" placeholder="Phone..." oninput="this.className = ''">4
                                                                </option>
                                                            </select>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class=" col-md-6 ">
                                                    <div class="name_2">
                                                        <p><select>
                                                            <option value="" oninput="this.className = ''">Choose mode of payment
                                                            </option>
                                                            <option value="" oninput="this.className = ''">Cash</option>
                                                            <option value="" oninput="this.className = ''">Cheque</option>
                                                            <option value="" oninput="this.className = ''">Online Payment</option>
                                                        </select></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="newsubmit" style={{ display: "inline-flex" }}>
                                                <button type="button" onClick={() => this.setState({ page2: false, page1: false, page3: true, page4: false })} >Next</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>


                                <div class="col-lg-12 col-md-12 col-sm-12">
                                    <div class="step_main" style={{ textAlign: "center", marginTop: "20px" }}>
                                        <span onClick={() => this.setState({ page1: true, page2: false, page3: false, page4: false })} class={this.state.page1 ? "step.active" : "step"}>1</span>
                                        <span onClick={() => this.setState({ page1: false, page2: true, page3: false, page4: false })} class={this.state.page2 ? "step.active" : "step"}>2</span>
                                        <span onClick={() => this.setState({ page1: false, page2: false, page3: true, page4: false })} class={this.state.page3 ? "step.active" : "step"}>3</span>
                                        <span onClick={() => this.setState({ page1: false, page2: false, page3: false, page4: true })} class={this.state.page4 ? "step.active" : "step"}>4</span>
                                    </div>
                                </div>

                            </section>
                            : this.state.page3 ?
                                <section class="new_1 mb-3">
                                    <div class="container">
                                        <form action="" class="regisform">
                                            <div class="desiner_registration">
                                                <img src={registration} alt="" />
                                            </div>
                                            <div class="tab">Passport:
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                                        <div class="name_1">
                                                            <p><input type="text" placeholder="Passport Number" oninput="this.className = ''" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                                        <div class="name_1">
                                                            <p><input type="date" placeholder="Date of Issue" oninput="this.className = ''" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                                        <div class="name_1">
                                                            <p><input type="date" placeholder="Date of Expiry" oninput="this.className = ''" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                                        <div class="name">
                                                            <p><input type="text" placeholder="Emirates ID" oninput="this.className = ''" />
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="newsubmit" style={{ display: "inline-flex" }}>
                                                    <button type="button" onClick={() => this.setState({ page2: false, page1: false, page3: false, page4: true })} >Next</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>

                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <div class="step_main" style={{ textAlign: "center", marginTop: "20px" }}>
                                            <span onClick={() => this.setState({ page1: true, page2: false, page3: false, page4: false })} class={this.state.page1 ? "step.active" : "step"}>1</span>
                                            <span onClick={() => this.setState({ page1: false, page2: true, page3: false, page4: false })} class={this.state.page2 ? "step.active" : "step"}>2</span>
                                            <span onClick={() => this.setState({ page1: false, page2: false, page3: true, page4: false })} class={this.state.page3 ? "step.active" : "step"}>3</span>
                                            <span onClick={() => this.setState({ page1: false, page2: false, page3: false, page4: true })} class={this.state.page4 ? "step.active" : "step"}>4</span>
                                        </div>
                                    </div>

                                </section>
                                :
                                this.state.page4 ?
                                    <section class="new_1 mb-3">
                                        <div class="container">
                                            <form action="" class="regisform">
                                                <div class="desiner_registration">
                                                    <img src={registration} alt="" />
                                                </div>
                                                <div class="tab">Submit:
                                                    <div class="col-lg-6 col-md-6 col-sm-12">
                                                        <div class="name">
                                                            <p><input type="text" placeholder="Reference By" oninput="this.className = ''" /></p>
                                                        </div>
                                                    </div>
                                                    <div class=" col-lg-12 col-md-12 col-sm-12 pb-2">
                                                        <div class="name_1">
                                                            <div class="name_2">
                                                                <p><input type="checkbox" placeholder="Date of Expiry" oninput="this.className = ''" />
                                                                </p>
                                                            </div>
                                                            <div class="name_content">
                                                                <p>I agree that A Square Mode Entertainments or ASM ( commonly known as "A
                                                                    Square
                                                                    London")
                                                                    reserves the "right" to use all images and videos derived in any format from
                                                                    the
                                                                    Designer
                                                                    before/ during/ after registering with ASM and transform the same into any
                                                                    or
                                                                    all forms of
                                                                    media currently in existance or yet to be created.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="newsubmit" style={{ display: "inline-flex" }}>
                                                        <button type="button" >Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="step_main" style={{ textAlign: "center", marginTop: "20px" }}>
                                                <span onClick={() => this.setState({ page1: true, page2: false, page3: false, page4: false })} class={this.state.page1 ? "step.active" : "step"}>1</span>
                                                <span onClick={() => this.setState({ page1: false, page2: true, page3: false, page4: false })} class={this.state.page2 ? "step.active" : "step"}>2</span>
                                                <span onClick={() => this.setState({ page1: false, page2: false, page3: true, page4: false })} class={this.state.page3 ? "step.active" : "step"}>3</span>
                                                <span onClick={() => this.setState({ page1: false, page2: false, page3: false, page4: true })} class={this.state.page4 ? "step.active" : "step"}>4</span>
                                            </div>
                                        </div>

                                    </section>
                                    : null}
                <Footer />
            </div>
        )
    }
}