


import React, { Component } from 'react'
import { logoImage } from '../components/images'
import { menuImage } from '../components/images'
import { menuImagetwo } from '../components/images'
import { communityIcon, mobMenuOne, mobMenuTwo, mobMenuThree, mobMenuFour, mobMenuFive } from "../components/images";
import { Link } from 'react-router-dom';
// export default function Navbar(props) {
export default class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: ""
        }
    }
    componentDidMount = async () => {
        const viewData = localStorage.getItem('token')
        console.log("Data==>", viewData)
        await this.setState({ token: viewData })
    }


    render() {
        return (
            <div>
                <header className="header_container">
                    <div className='head_app_install_box'>
                        <span>Download <span>KAAF App</span> Now and Shop On the Go</span>
                        <span className='install_btn_box'>
                            <a href="" >
                                <button>
                                    <span><i className="fa fa-mobile" aria-hidden="true"></i></span>
                                    <span> Download App</span>


                                </button>
                            </a>
                        </span>
                    </div>
                    <div className="container">
                        <div className="primary_header">
                            <div className="row align-items-center">
                                <div className="col-3 pr-0">
                                    <div className="website_logo">
                                        <a href="/">
                                            <img src={logoImage} alt="" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-5 col-sm-7 col-8">
                                    <div className="website_search">
                                        <input type="text" placeholder="Search dresses, t-shirts" />
                                        <button type="submit" className="button" >
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-2 col-1 pl-0">
                                    <div className="action_buttons">


                                        {
                                            this.state.token != null ? null :
                                                <span>
                                                    <Link to={"/login"} id="login_btn">
                                                        <i className="fa fa-sign-in" aria-hidden="true"></i><span className='header_text_resp'> Login</span>
                                                    </Link>
                                                </span>
                                        }
                                        {
                                            this.state.token != null ?
                                                <span>
                                                    <Link to={"/myAccount"} >
                                                        <i className="fa fa-user" aria-hidden="true"></i><span className='header_text_resp'> Account</span>
                                                    </Link>
                                                </span>

                                                :
                                                null

                                        }

                                        {
                                            this.state.token != null ?
                                                <span>
                                                    <Link to={"/wishlist"} >
                                                        <i className="fa fa-heart" aria-hidden="true">
                                                        </i><span className='header_text_resp'> Wishlist</span>
                                                    </Link>
                                                </span>
                                                :
                                                null

                                        }

                                        {
                                            this.state.token != null ?
                                                <span>
                                                    <Link to={"/myCart"} >
                                                        <i className="fa fa-shopping-bag" aria-hidden="true">
                                                        </i><span className='header_text_resp'> My Cart</span>
                                                    </Link>
                                                </span>

                                                :
                                                null

                                        }



                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="primary_header_resp">
                            <div className="row align-items-center">
                                <div className="col-4 pr-0">
                                    <div className="website_logo">
                                        <a href="/">
                                            <img src={logoImage} alt="" />
                                        </a>
                                    </div>
                                </div>
                                {/* <div className="col-5">
                                <div className="website_search">
                                    <input type="text" placeholder="Search dresses, t-shirts" />
                                    <button type="submit" className="button" >
                                        <i className="fa fa-search" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div> */}
                                <div className="col-6 pl-0 ml-auto">
                                    <div className="action_buttons">


                                        {
                                            this.state.token != null ?
                                                null
                                                :
                                                <span>
                                                    <Link to={"/login"} id="login_btn">
                                                        <i className="fa fa-sign-in" aria-hidden="true"></i><span className='header_text_resp'> Login</span>
                                                    </Link>
                                                </span>

                                        }




                                        {
                                            this.state.token != null ?
                                                <span>
                                                    <Link to={"/myAccount"} >
                                                        <i className="fa fa-user" aria-hidden="true"></i><span className='header_text_resp'> Account</span>
                                                    </Link>
                                                </span>
                                                :
                                                null

                                        }

                                        {
                                            this.state.token != null ?
                                                <span>
                                                    <Link to={"/wishlist"} >
                                                        <i className="fa fa-heart" aria-hidden="true">
                                                        </i><span className='header_text_resp'> Wishlist</span>
                                                    </Link>
                                                </span>
                                                :
                                                null

                                        }

                                        {
                                            this.state.token != null ?
                                                <span>
                                                    <Link to={"/myCart"} >
                                                        <i className="fa fa-shopping-bag" aria-hidden="true">
                                                        </i><span className='header_text_resp'> My Cart</span>
                                                    </Link>
                                                </span>
                                                :
                                                null

                                        }






                                        {/* <span class="nav_changer mobile_hamburger">
                                        <i class="fa fa-bars" aria-hidden="true"></i>
                                    </span> */}
                                    </div>

                                </div>
                            </div>

                            <div className="row align-items-center">
                                <div className="col-12 p-1 bg_black">
                                    <div className="website_search">
                                        <input type="text" placeholder="Search dresses, t-shirts" />
                                        <button type="submit" className="button" >
                                            <i className="fa fa-search" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="mobile_menu">
                        <div class="nav-wrapper">
                            <a href="/" class="js-nav-toggle nav_changer">
                                <span><i class="fa fa-times" aria-hidden="true"></i></span>
                            </a>
                            <nav role="navigation">
                                <div class="nav-toggle">
                                    <span class="nav-back"><i class="fa fa-chevron-left" aria-hidden="true"></i></span>
                                    <span class="nav-title"> Menu </span>
                                    <span class="nav-close"></span>
                                </div>
                                <ul>
                                    <li>
                                        <a href="/">Home</a>
                                    </li>
                                    <li>
                                        <a href="/">Boutiques</a>
                                    </li>
                                    {/* <!--<li class="has-dropdown">-->
                                            <!--    <a href="/">Home</a>-->
                                            <!--    <ul>-->
                                                <!--        <li><a href="/">Cat 1</a></li>-->
                                                <!--        <li><a href="/">Cat 2</a></li>-->
                                                <!--        <li><a href="/">Cat 3</a></li>-->
                                                <!--        <li><a href="/">Cat 4</a></li>-->
                                                <!--        <li><a href="/">Cat 5</a></li>-->
                                                <!--        <li><a href="/">Cat 6</a></li>-->
                                                <!--        <li><a href="/">Cat 7</a></li>-->
                                                <!--    </ul>-->
                                            <!--</li>--> */}
                                    <li class="has-dropdown">
                                        <a href="/">Categories</a>
                                        <ul>
                                            {/* <!--<li class="has-dropdown">-->
                                                    <!--    <a href="/">Sub Menu</a>-->
                                                    <!--    <ul>-->
                                                        <!--        <li><a href="web-development.php">Web Development</a></li>
                                                        <li><a href="app-development.php">App Development</a></li>
                                                        <li><a href="e-commerece-development.php">E-Commerce Development</a></li>
                                                        <li><a href="digital-marketing.php">Digital Marketing</a></li>
                                                        <li><a href="website-maintenance.php">Website Maintenance</a></li>
                                                        <!--    </ul>-->
                                                    <!--</li>--> */}
                                            <li><a href="/">Apparels</a></li>
                                            <li><a href="/">Perfumes</a></li>
                                            <li><a href="/">Handbags</a></li>
                                            <li><a href="/">Footwears</a></li>
                                            <li><a href="/">Jwellery</a></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <a href="/communityList">Community</a>
                                    </li>
                                    <li>
                                        <a href="https://kaafofficial.com/magazine/">Magazine</a>
                                    </li>
                                    {/* <!--<li class="has-dropdown">-->
                                            <!--    <a href="/">Contact Us</a>-->
                                            <!--    <ul>-->
                                                <!--        <li class="has-dropdown">-->
                                                    <!--            <a href="/">By mail</a>-->
                                                    <!--            <ul>-->
                                                        <!--                <li><a href="/">mail 1</a></li>-->
                                                        <!--                <li><a href="/">mail 2</a></li>-->
                                                        <!--                <li><a href="/">mail 3</a></li>-->
                                                        <!--            </ul>-->
                                                    <!--        </li>-->
                                                <!--        <li><a href="/">Anything</a></li>-->
                                                <!--        <li><a href="/">Tester</a></li>-->
                                                <!--    </ul>-->
                                            <!--</li>--> */}
                                    {/* <li>
                                    <a href="portfolio.php">Portfolio</a>
                                </li> */}

                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="megamenu">
                        <div className="container">
                            <div className="navbar">
                                <a href="/">Home</a>
                                {/* <a href="/news">Fashion</a> */}
                                {/* <div className="dropdown">
                                <button className="dropbtn">Fashion
                                    <i className="fa fa-caret-down"></i>
                                </button>
                                <div className="dropdown-content">
                                    <div className="container">
                                        <div className="drop_down_inner">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="row">
                                                        <div className="col-3 column">
                                                            <div className="dropdown_menu_list">
                                                                <h3><a href="/">Ocassion Shop</a></h3>
                                                                <ul>
                                                                    <li><a className="bg_none" href="/">Corset Tops</a></li>
                                                                    <li><a href="/">Coats & Jackets</a></li>
                                                                    <li><a href="/">Jumpers & Cardigans</a></li>
                                                                    <li><a href="/">Occasion Wear</a></li>
                                                                    <li><a href="/">Casual Wear</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 column">
                                                            <div className="dropdown_menu_list">
                                                                <h3><a href="/">Casual Fashion</a></h3>
                                                                <ul>
                                                                    <li><a href="/">Co-Ord Sets</a></li>
                                                                    <li><a href="/">Jumpsuits</a></li>
                                                                    <li><a href="/">Suits & Tailoring</a></li>
                                                                    <li><a href="/">Nightwear</a></li>
                                                                    <li><a href="/">Shirts & Blouses</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 column">
                                                            <div className="dropdown_menu_list">
                                                                <h3><a href="/">Going Out</a></h3>
                                                                <ul>
                                                                    <li><a href="/">Holiday Shoes</a></li>
                                                                    <li><a href="/">Festival Shoes</a></li>
                                                                    <li><a href="/">Sports Trainers</a></li>
                                                                    <li><a href="/">Work Shoes</a></li>
                                                                    <li><a href="/">Prom Shoes</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 column">
                                                            <div className="dropdown_menu_list">
                                                                <h3><a href="/">Bridal Drsses</a></h3>
                                                                <ul>
                                                                    <li><a href="/">Bride Pyjamas</a></li>
                                                                    <li><a href="/">All Going Out</a></li>
                                                                    <li><a href="/">Going Out Tops</a></li>
                                                                    <li><a href="/">Bodycon Dresses</a></li>
                                                                    <li><a href="/">Corset Dresses</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="drop_down_banner">
                                                        <a href="/"><img src={menuImagetwo} alt="" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                                {/* <div className="dropdown">
                                <button className="dropbtn">Women's
                                    <i className="fa fa-caret-down"></i>
                                </button>
                                <div className="dropdown-content">
                                    <div className="container">
                                        <div className="drop_down_inner">
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="row">
                                                        <div className="col-3 column">
                                                            <div className="dropdown_menu_list">
                                                                <h3><a href="/">Ocassion Shop</a></h3>
                                                                <ul>
                                                                    <li><a className="bg_none" href="/">Corset Tops</a></li>
                                                                    <li><a href="/">Coats & Jackets</a></li>
                                                                    <li><a href="/">Jumpers & Cardigans</a></li>
                                                                    <li><a href="/">Occasion Wear</a></li>
                                                                    <li><a href="/">Casual Wear</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 column">
                                                            <div className="dropdown_menu_list">
                                                                <h3><a href="/">Casual Fashion</a></h3>
                                                                <ul>
                                                                    <li><a href="/">Co-Ord Sets</a></li>
                                                                    <li><a href="/">Jumpsuits</a></li>
                                                                    <li><a href="/">Suits & Tailoring</a></li>
                                                                    <li><a href="/">Nightwear</a></li>
                                                                    <li><a href="/">Shirts & Blouses</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 column">
                                                            <div className="dropdown_menu_list">
                                                                <h3><a href="/">Going Out</a></h3>
                                                                <ul>
                                                                    <li><a href="/">Holiday Shoes</a></li>
                                                                    <li><a href="/">Festival Shoes</a></li>
                                                                    <li><a href="/">Sports Trainers</a></li>
                                                                    <li><a href="/">Work Shoes</a></li>
                                                                    <li><a href="/">Prom Shoes</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-3 column">
                                                            <div className="dropdown_menu_list">
                                                                <h3><a href="/">Bridal Drsses</a></h3>
                                                                <ul>
                                                                    <li><a href="/">Bride Pyjamas</a></li>
                                                                    <li><a href="/">All Going Out</a></li>
                                                                    <li><a href="/">Going Out Tops</a></li>
                                                                    <li><a href="/">Bodycon Dresses</a></li>
                                                                    <li><a href="/">Corset Dresses</a></li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="drop_down_banner">
                                                        <a href="/"><img src={menuImage} alt="" /></a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                                {/* <a href="/news">Men</a> */}
                                {/* <a href="/news">Kids</a> */}
                                {/* <a href="/news">Accessories</a> */}
                                <a href="/communityList" className="header_comm_box">
                                    {/* <img src={communityIcon} alt="" /> */}
                                    Community</a>
                                <a href="https://kaafofficial.com/magazine/" className="header_comm_box">
                                    {/* <img src={communityIcon} alt="" /> */}
                                    Magazine</a>

                            </div>
                        </div>
                    </div>
                </header>

                {/* <div className='whatsapp_floating_btn'> */}
                <a href='https://api.whatsapp.com/send?phone=971588001786&text=Hi.%20I%20want%20to%20know%20more.%20' className='whatsapp_floating_btn'>
                    <i class="fa fa-whatsapp"></i>
                </a>
                {/* </div> */}

                <div className='mob_bottom_menu'>
                    <ul>
                        <li><a href="/">
                            <img src={mobMenuOne} alt="" />
                            <span>Home</span>
                        </a></li>
                        <li><a href="/brands">
                            <img src={mobMenuTwo} alt="" />
                            <span>Brands</span>
                        </a></li>
                        <li className='mob_menu_community'><a href="/communityList">
                            <img src={mobMenuThree} alt="" />
                            <span>Community</span>
                        </a></li>
                        <li><a href="/viewcategory">
                            <img src={mobMenuFour} alt="" />
                            <span>Categories</span>
                        </a> </li>
                        <li><a href="/myCart">
                            <img src={mobMenuFive} alt="" />
                            <span>Shop Cart</span>
                        </a> </li>
                    </ul>
                </div>

            </div>
        )
    }
}
