import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

export default function PaymentFailed() {
    return (
        <div>
            <Navbar/>
            <section className="pt-5 pb-5 cart_page">
        <div className="container">
            <div className="row">
                <div className="col-md-6 m-auto">
                    <div className="payment_status payment_failed">
                        <span><i className="fa fa-times-circle" aria-hidden="true"></i></span>
                        <h3>Transaction Failed!</h3>                        
                        <p>Your payment was not successfully processed. Please try some other method.</p>                        
                    </div>
                </div>
            </div>
        </div>
    </section>
            <Footer/>
        </div>
    )
}
