import './App.css';
//-----Router-----
import { BrowserRouter, Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from "history";
//----------------
import Home from './screens/Home';
import MyAccount from './screens/MyAccount';
import ViewProduct from './screens/ViewProduct';
import Listing from './screens/Listing';
import MyCart from './screens/MyCart';
import Checkout from './screens/Checkout';
import Wishlist from './screens/Wishlist';
import ContactUs from './screens/ContactUs';
import ForgotPassword from './screens/ForgotPassword';
import UpdatePassword from './screens/UpdatePassword';
import Login from './screens/Login';
import Signup from './screens/Signup';
import PaymentFailed from './screens/PaymentFailed';
import PaymentSuccessful from './screens/PaymentSuccessful';
import PrivacyPolicy from './screens/PrivacyPolicy';
import TermsConditions from './screens/TermsConditions';
import Community from './screens/Community';
import FreelancerView from './screens/ViewFreelancer';
import TheModestyShow from './screens/themodestyshow';
import CheckoutAddress from './screens/CheckoutAddress';
import ViewSubcategory from './screens/ViewSubcategory';
import ViewCategory from './screens/ViewCategory';

export const history = createBrowserHistory();

function App() {
    return (

        <BrowserRouter>
            <Switch>
                <Route path="/" component={Home} exact />

                <Route path="/myAccount" component={MyAccount} exact />
                <Route path="/myCart" component={MyCart} exact />
                <Route path="/wishlist" component={Wishlist} exact />
                <Route path="/brands" component={Checkout} exact />
                <Route path="/checkoutAddress" component={CheckoutAddress} exact />
                <Route path="/viewProduct" component={ViewProduct} exact />
                <Route path="/listing/:name" component={Listing} exact />
                <Route path="/viewcategory" component={ViewCategory} exact />

                <Route path="/viewSubcategory" component={ViewSubcategory} exact />


                <Route path="/login" component={Login} exact />
                <Route path="/signup" component={Signup} exact />
                <Route path="/forgotPassword" component={ForgotPassword} exact />
                <Route path="/updatePassword" component={UpdatePassword} exact />

                <Route path="/privacyPolicy" component={PrivacyPolicy} exact />
                <Route path="/termsConditions" component={TermsConditions} exact />
                <Route path="/contactUs" component={ContactUs} exact />
                <Route path="/themodestyshow" component={TheModestyShow} exact />
                <Route path="/paymentFailed" component={PaymentFailed} exact />
                <Route path="/paymentSuccessful" component={PaymentSuccessful} exact />
                <Route path="/communityList" component={Community} exact />
                <Route path="/:id" component={FreelancerView} exact />

            </Switch>
        </BrowserRouter>





        // {/* <ForgotPassword/> */}
        // {/* <UpdatePassword/> */}
        // {/* <Login/> */}
        // {/* <Signup/> */}




        // {/* <PaymentFailed/> */}


        // {/* <PrivacyPolicy/> */}
        // {/* <TermsConditions/> */} 
        // {/* <ContactUs/> */}

        // </div>
    );
}
export default App;