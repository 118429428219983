import React, { Component } from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { shoppingSix, indiaFlag, uaeFlag, rankBadge } from '../components/images'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


export default class Community extends Component {
    constructor(props) {
        super(props);
        this.state = {
            commCard: [],
            fd: false, ph: false, m: false, ma: false, b: false, ma: false, i: false,
            country_data:[
                {
                    c_id: "1",
                    sortname: "AF",
                    c_name: "Select Country",
                    phonecode: "93",
                    status: false
                  },
                {
                  c_id: "1",
                  sortname: "AF",
                  c_name: "Afghanistan",
                  phonecode: "93",
                  status: false
                },
                {
                  c_id: "2",
                  sortname: "AL",
                  c_name: "Albania",
                  phonecode: "355",
                  status: false
                },
                {
                  c_id: "3",
                  sortname: "DZ",
                  c_name: "Algeria",
                  phonecode: "213",
                  status: false
                },
                {
                  c_id: "4",
                  sortname: "AS",
                  c_name: "American Samoa",
                  phonecode: "1684",
                  status: false
                },
                {
                  c_id: "5",
                  sortname: "AD",
                  c_name: "Andorra",
                  phonecode: "376",
                  status: false
                },
                {
                  c_id: "6",
                  sortname: "AO",
                  c_name: "Angola",
                  phonecode: "244",
                  status: false
                },
                {
                  c_id: "7",
                  sortname: "AI",
                  c_name: "Anguilla",
                  phonecode: "1264",
                  status: false
                },
                {
                  c_id: "8",
                  sortname: "AQ",
                  c_name: "Antarctica",
                  phonecode: "0",
                  status: false
                },
                {
                  c_id: "9",
                  sortname: "AG",
                  c_name: "Antigua And Barbuda",
                  phonecode: "1268",
                  status: false
                },
                {
                  c_id: "10",
                  sortname: "AR",
                  c_name: "Argentina",
                  phonecode: "54",
                  status: false
                },
                {
                  c_id: "11",
                  sortname: "AM",
                  c_name: "Armenia",
                  phonecode: "374",
                  status: false
                },
                {
                  c_id: "12",
                  sortname: "AW",
                  c_name: "Aruba",
                  phonecode: "297",
                  status: false
                },
                {
                  c_id: "13",
                  sortname: "AU",
                  c_name: "Australia",
                  phonecode: "61",
                  status: false
                },
                {
                  c_id: "14",
                  sortname: "AT",
                  c_name: "Austria",
                  phonecode: "43",
                  status: false
                },
                {
                  c_id: "15",
                  sortname: "AZ",
                  c_name: "Azerbaijan",
                  phonecode: "994",
                  status: false
                },
                {
                  c_id: "16",
                  sortname: "BS",
                  c_name: "Bahamas The",
                  phonecode: "1242",
                  status: false
                },
                {
                  c_id: "17",
                  sortname: "BH",
                  c_name: "Bahrain",
                  phonecode: "973",
                  status: false
                },
                {
                  c_id: "18",
                  sortname: "BD",
                  c_name: "Bangladesh",
                  phonecode: "880",
                  status: false
                },
                {
                  c_id: "19",
                  sortname: "BB",
                  c_name: "Barbados",
                  phonecode: "1246",
                  status: false
                },
                {
                  c_id: "20",
                  sortname: "BY",
                  c_name: "Belarus",
                  phonecode: "375",
                  status: false
                },
                {
                  c_id: "21",
                  sortname: "BE",
                  c_name: "Belgium",
                  phonecode: "32",
                  status: false
                },
                {
                  c_id: "22",
                  sortname: "BZ",
                  c_name: "Belize",
                  phonecode: "501",
                  status: false
                },
                {
                  c_id: "23",
                  sortname: "BJ",
                  c_name: "Benin",
                  phonecode: "229",
                  status: false
                },
                {
                  c_id: "24",
                  sortname: "BM",
                  c_name: "Bermuda",
                  phonecode: "1441",
                  status: false
                },
                {
                  c_id: "25",
                  sortname: "BT",
                  c_name: "Bhutan",
                  phonecode: "975",
                  status: false
                },
                {
                  c_id: "26",
                  sortname: "BO",
                  c_name: "Bolivia",
                  phonecode: "591",
                  status: false
                },
                {
                  c_id: "27",
                  sortname: "BA",
                  c_name: "Bosnia and Herzegovina",
                  phonecode: "387",
                  status: false
                },
                {
                  c_id: "28",
                  sortname: "BW",
                  c_name: "Botswana",
                  phonecode: "267",
                  status: false
                },
                {
                  c_id: "29",
                  sortname: "BV",
                  c_name: "Bouvet Island",
                  phonecode: "0",
                  status: false
                },
                {
                  c_id: "30",
                  sortname: "BR",
                  c_name: "Brazil",
                  phonecode: "55",
                  status: false
                },
                {
                  c_id: "31",
                  sortname: "IO",
                  c_name: "British Indian Ocean Territory",
                  phonecode: "246",
                  status: false
                },
                {
                  c_id: "32",
                  sortname: "BN",
                  c_name: "Brunei",
                  phonecode: "673",
                  status: false
                },
                {
                  c_id: "33",
                  sortname: "BG",
                  c_name: "Bulgaria",
                  phonecode: "359",
                  status: false
                },
                {
                  c_id: "34",
                  sortname: "BF",
                  c_name: "Burkina Faso",
                  phonecode: "226",
                  status: false
                },
                {
                  c_id: "35",
                  sortname: "BI",
                  c_name: "Burundi",
                  phonecode: "257",
                  status: false
                },
                {
                  c_id: "36",
                  sortname: "KH",
                  c_name: "Cambodia",
                  phonecode: "855",
                  status: false
                },
                {
                  c_id: "37",
                  sortname: "CM",
                  c_name: "Cameroon",
                  phonecode: "237",
                  status: false
                },
                {
                  c_id: "38",
                  sortname: "CA",
                  c_name: "Canada",
                  phonecode: "1",
                  status: false
                },
                {
                  c_id: "39",
                  sortname: "CV",
                  c_name: "Cape Verde",
                  phonecode: "238",
                  status: false
                },
                {
                  c_id: "40",
                  sortname: "KY",
                  c_name: "Cayman Islands",
                  phonecode: "1345",
                  status: false
                },
                {
                  c_id: "41",
                  sortname: "CF",
                  c_name: "Central African Republic",
                  phonecode: "236",
                  status: false
                },
                {
                  c_id: "42",
                  sortname: "TD",
                  c_name: "Chad",
                  phonecode: "235",
                  status: false
                },
                {
                  c_id: "43",
                  sortname: "CL",
                  c_name: "Chile",
                  phonecode: "56",
                  status: false
                },
                {
                  c_id: "44",
                  sortname: "CN",
                  c_name: "China",
                  phonecode: "86",
                  status: false
                },
                {
                  c_id: "45",
                  sortname: "CX",
                  c_name: "Christmas Island",
                  phonecode: "61",
                  status: false
                },
                {
                  c_id: "46",
                  sortname: "CC",
                  c_name: "Cocos (Keeling) Islands",
                  phonecode: "672",
                  status: false
                },
                {
                  c_id: "47",
                  sortname: "CO",
                  c_name: "Colombia",
                  phonecode: "57",
                  status: false
                },
                {
                  c_id: "48",
                  sortname: "KM",
                  c_name: "Comoros",
                  phonecode: "269",
                  status: false
                },
                {
                  c_id: "49",
                  sortname: "CG",
                  c_name: "Republic Of The Congo",
                  phonecode: "242",
                  status: false
                },
                {
                  c_id: "50",
                  sortname: "CD",
                  c_name: "Democratic Republic Of The Congo",
                  phonecode: "242",
                  status: false
                },
                {
                  c_id: "51",
                  sortname: "CK",
                  c_name: "Cook Islands",
                  phonecode: "682",
                  status: false
                },
                {
                  c_id: "52",
                  sortname: "CR",
                  c_name: "Costa Rica",
                  phonecode: "506",
                  status: false
                },
                {
                  c_id: "53",
                  sortname: "CI",
                  c_name: "Cote D'Ivoire (Ivory Coast)",
                  phonecode: "225",
                  status: false
                },
                {
                  c_id: "54",
                  sortname: "HR",
                  c_name: "Croatia (Hrvatska)",
                  phonecode: "385",
                  status: false
                },
                {
                  c_id: "55",
                  sortname: "CU",
                  c_name: "Cuba",
                  phonecode: "53",
                  status: false
                },
                {
                  c_id: "56",
                  sortname: "CY",
                  c_name: "Cyprus",
                  phonecode: "357",
                  status: false
                },
                {
                  c_id: "57",
                  sortname: "CZ",
                  c_name: "Czech Republic",
                  phonecode: "420",
                  status: false
                },
                {
                  c_id: "58",
                  sortname: "DK",
                  c_name: "Denmark",
                  phonecode: "45",
                  status: false
                },
                {
                  c_id: "59",
                  sortname: "DJ",
                  c_name: "Djibouti",
                  phonecode: "253",
                  status: false
                },
                {
                  c_id: "60",
                  sortname: "DM",
                  c_name: "Dominica",
                  phonecode: "1767",
                  status: false
                },
                {
                  c_id: "61",
                  sortname: "DO",
                  c_name: "Dominican Republic",
                  phonecode: "1809",
                  status: false
                },
                {
                  c_id: "62",
                  sortname: "TP",
                  c_name: "East Timor",
                  phonecode: "670",
                  status: false
                },
                {
                  c_id: "63",
                  sortname: "EC",
                  c_name: "Ecuador",
                  phonecode: "593",
                  status: false
                },
                {
                  c_id: "64",
                  sortname: "EG",
                  c_name: "Egypt",
                  phonecode: "20",
                  status: false
                },
                {
                  c_id: "65",
                  sortname: "SV",
                  c_name: "El Salvador",
                  phonecode: "503",
                  status: false
                },
                {
                  c_id: "66",
                  sortname: "GQ",
                  c_name: "Equatorial Guinea",
                  phonecode: "240",
                  status: false
                },
                {
                  c_id: "67",
                  sortname: "ER",
                  c_name: "Eritrea",
                  phonecode: "291",
                  status: false
                },
                {
                  c_id: "68",
                  sortname: "EE",
                  c_name: "Estonia",
                  phonecode: "372",
                  status: false
                },
                {
                  c_id: "69",
                  sortname: "ET",
                  c_name: "Ethiopia",
                  phonecode: "251",
                  status: false
                },
                {
                  c_id: "70",
                  sortname: "XA",
                  c_name: "External Territories of Australia",
                  phonecode: "61",
                  status: false
                },
                {
                  c_id: "71",
                  sortname: "FK",
                  c_name: "Falkland Islands",
                  phonecode: "500",
                  status: false
                },
                {
                  c_id: "72",
                  sortname: "FO",
                  c_name: "Faroe Islands",
                  phonecode: "298",
                  status: false
                },
                {
                  c_id: "73",
                  sortname: "FJ",
                  c_name: "Fiji Islands",
                  phonecode: "679",
                  status: false
                },
                {
                  c_id: "74",
                  sortname: "FI",
                  c_name: "Finland",
                  phonecode: "358",
                  status: false
                },
                {
                  c_id: "75",
                  sortname: "FR",
                  c_name: "France",
                  phonecode: "33",
                  status: false
                },
                {
                  c_id: "76",
                  sortname: "GF",
                  c_name: "French Guiana",
                  phonecode: "594",
                  status: false
                },
                {
                  c_id: "77",
                  sortname: "PF",
                  c_name: "French Polynesia",
                  phonecode: "689",
                  status: false
                },
                {
                  c_id: "78",
                  sortname: "TF",
                  c_name: "French Southern Territories",
                  phonecode: "0",
                  status: false
                },
                {
                  c_id: "79",
                  sortname: "GA",
                  c_name: "Gabon",
                  phonecode: "241",
                  status: false
                },
                {
                  c_id: "80",
                  sortname: "GM",
                  c_name: "Gambia The",
                  phonecode: "220",
                  status: false
                },
                {
                  c_id: "81",
                  sortname: "GE",
                  c_name: "Georgia",
                  phonecode: "995",
                  status: false
                },
                {
                  c_id: "82",
                  sortname: "DE",
                  c_name: "Germany",
                  phonecode: "49",
                  status: false
                },
                {
                  c_id: "83",
                  sortname: "GH",
                  c_name: "Ghana",
                  phonecode: "233",
                  status: false
                },
                {
                  c_id: "84",
                  sortname: "GI",
                  c_name: "Gibraltar",
                  phonecode: "350",
                  status: false
                },
                {
                  c_id: "85",
                  sortname: "GR",
                  c_name: "Greece",
                  phonecode: "30",
                  status: false
                },
                {
                  c_id: "86",
                  sortname: "GL",
                  c_name: "Greenland",
                  phonecode: "299",
                  status: false
                },
                {
                  c_id: "87",
                  sortname: "GD",
                  c_name: "Grenada",
                  phonecode: "1473",
                  status: false
                },
                {
                  c_id: "88",
                  sortname: "GP",
                  c_name: "Guadeloupe",
                  phonecode: "590",
                  status: false
                },
                {
                  c_id: "89",
                  sortname: "GU",
                  c_name: "Guam",
                  phonecode: "1671",
                  status: false
                },
                {
                  c_id: "90",
                  sortname: "GT",
                  c_name: "Guatemala",
                  phonecode: "502",
                  status: false
                },
                {
                  c_id: "91",
                  sortname: "XU",
                  c_name: "Guernsey and Alderney",
                  phonecode: "44",
                  status: false
                },
                {
                  c_id: "92",
                  sortname: "GN",
                  c_name: "Guinea",
                  phonecode: "224",
                  status: false
                },
                {
                  c_id: "93",
                  sortname: "GW",
                  c_name: "Guinea-Bissau",
                  phonecode: "245",
                  status: false
                },
                {
                  c_id: "94",
                  sortname: "GY",
                  c_name: "Guyana",
                  phonecode: "592",
                  status: false
                },
                {
                  c_id: "95",
                  sortname: "HT",
                  c_name: "Haiti",
                  phonecode: "509",
                  status: false
                },
                {
                  c_id: "96",
                  sortname: "HM",
                  c_name: "Heard and McDonald Islands",
                  phonecode: "0",
                  status: false
                },
                {
                  c_id: "97",
                  sortname: "HN",
                  c_name: "Honduras",
                  phonecode: "504",
                  status: false
                },
                {
                  c_id: "98",
                  sortname: "HK",
                  c_name: "Hong Kong S.A.R.",
                  phonecode: "852",
                  status: false
                },
                {
                  c_id: "99",
                  sortname: "HU",
                  c_name: "Hungary",
                  phonecode: "36",
                  status: false
                },
                {
                  c_id: "100",
                  sortname: "IS",
                  c_name: "Iceland",
                  phonecode: "354",
                  status: false
                },
                {
                  c_id: "101",
                  sortname: "IN",
                  c_name: "India",
                  phonecode: "91",
                  status: false
                },
                {
                  c_id: "102",
                  sortname: "ID",
                  c_name: "Indonesia",
                  phonecode: "62",
                  status: false
                },
                {
                  c_id: "103",
                  sortname: "IR",
                  c_name: "Iran",
                  phonecode: "98",
                  status: false
                },
                {
                  c_id: "104",
                  sortname: "IQ",
                  c_name: "Iraq",
                  phonecode: "964",
                  status: false
                },
                {
                  c_id: "105",
                  sortname: "IE",
                  c_name: "Ireland",
                  phonecode: "353",
                  status: false
                },
                {
                  c_id: "106",
                  sortname: "IL",
                  c_name: "Israel",
                  phonecode: "972",
                  status: false
                },
                {
                  c_id: "107",
                  sortname: "IT",
                  c_name: "Italy",
                  phonecode: "39",
                  status: false
                },
                {
                  c_id: "108",
                  sortname: "JM",
                  c_name: "Jamaica",
                  phonecode: "1876",
                  status: false
                },
                {
                  c_id: "109",
                  sortname: "JP",
                  c_name: "Japan",
                  phonecode: "81",
                  status: false
                },
                {
                  c_id: "110",
                  sortname: "XJ",
                  c_name: "Jersey",
                  phonecode: "44",
                  status: false
                },
                {
                  c_id: "111",
                  sortname: "JO",
                  c_name: "Jordan",
                  phonecode: "962",
                  status: false
                },
                {
                  c_id: "112",
                  sortname: "KZ",
                  c_name: "Kazakhstan",
                  phonecode: "7",
                  status: false
                },
                {
                  c_id: "113",
                  sortname: "KE",
                  c_name: "Kenya",
                  phonecode: "254",
                  status: false
                },
                {
                  c_id: "114",
                  sortname: "KI",
                  c_name: "Kiribati",
                  phonecode: "686",
                  status: false
                },
                {
                  c_id: "115",
                  sortname: "KP",
                  c_name: "Korea North",
                  phonecode: "850",
                  status: false
                },
                {
                  c_id: "116",
                  sortname: "KR",
                  c_name: "Korea South",
                  phonecode: "82",
                  status: false
                },
                {
                  c_id: "117",
                  sortname: "KW",
                  c_name: "Kuwait",
                  phonecode: "965",
                  status: false
                },
                {
                  c_id: "118",
                  sortname: "KG",
                  c_name: "Kyrgyzstan",
                  phonecode: "996",
                  status: false
                },
                {
                  c_id: "119",
                  sortname: "LA",
                  c_name: "Laos",
                  phonecode: "856",
                  status: false
                },
                {
                  c_id: "120",
                  sortname: "LV",
                  c_name: "Latvia",
                  phonecode: "371",
                  status: false
                },
                {
                  c_id: "121",
                  sortname: "LB",
                  c_name: "Lebanon",
                  phonecode: "961",
                  status: false
                },
                {
                  c_id: "122",
                  sortname: "LS",
                  c_name: "Lesotho",
                  phonecode: "266",
                  status: false
                },
                {
                  c_id: "123",
                  sortname: "LR",
                  c_name: "Liberia",
                  phonecode: "231",
                  status: false
                },
                {
                  c_id: "124",
                  sortname: "LY",
                  c_name: "Libya",
                  phonecode: "218",
                  status: false
                },
                {
                  c_id: "125",
                  sortname: "LI",
                  c_name: "Liechtenstein",
                  phonecode: "423",
                  status: false
                },
                {
                  c_id: "126",
                  sortname: "LT",
                  c_name: "Lithuania",
                  phonecode: "370",
                  status: false
                },
                {
                  c_id: "127",
                  sortname: "LU",
                  c_name: "Luxembourg",
                  phonecode: "352",
                  status: false
                },
                {
                  c_id: "128",
                  sortname: "MO",
                  c_name: "Macau S.A.R.",
                  phonecode: "853",
                  status: false
                },
                {
                  c_id: "129",
                  sortname: "MK",
                  c_name: "Macedonia",
                  phonecode: "389",
                  status: false
                },
                {
                  c_id: "130",
                  sortname: "MG",
                  c_name: "Madagascar",
                  phonecode: "261",
                  status: false
                },
                {
                  c_id: "131",
                  sortname: "MW",
                  c_name: "Malawi",
                  phonecode: "265",
                  status: false
                },
                {
                  c_id: "132",
                  sortname: "MY",
                  c_name: "Malaysia",
                  phonecode: "60",
                  status: false
                },
                {
                  c_id: "133",
                  sortname: "MV",
                  c_name: "Maldives",
                  phonecode: "960",
                  status: false
                },
                {
                  c_id: "134",
                  sortname: "ML",
                  c_name: "Mali",
                  phonecode: "223",
                  status: false
                },
                {
                  c_id: "135",
                  sortname: "MT",
                  c_name: "Malta",
                  phonecode: "356",
                  status: false
                },
                {
                  c_id: "136",
                  sortname: "XM",
                  c_name: "Man (Isle of)",
                  phonecode: "44",
                  status: false
                },
                {
                  c_id: "137",
                  sortname: "MH",
                  c_name: "Marshall Islands",
                  phonecode: "692",
                  status: false
                },
                {
                  c_id: "138",
                  sortname: "MQ",
                  c_name: "Martinique",
                  phonecode: "596",
                  status: false
                },
                {
                  c_id: "139",
                  sortname: "MR",
                  c_name: "Mauritania",
                  phonecode: "222",
                  status: false
                },
                {
                  c_id: "140",
                  sortname: "MU",
                  c_name: "Mauritius",
                  phonecode: "230",
                  status: false
                },
                {
                  c_id: "141",
                  sortname: "YT",
                  c_name: "Mayotte",
                  phonecode: "269",
                  status: false
                },
                {
                  c_id: "142",
                  sortname: "MX",
                  c_name: "Mexico",
                  phonecode: "52",
                  status: false
                },
                {
                  c_id: "143",
                  sortname: "FM",
                  c_name: "Micronesia",
                  phonecode: "691",
                  status: false
                },
                {
                  c_id: "144",
                  sortname: "MD",
                  c_name: "Moldova",
                  phonecode: "373",
                  status: false
                },
                {
                  c_id: "145",
                  sortname: "MC",
                  c_name: "Monaco",
                  phonecode: "377",
                  status: false
                },
                {
                  c_id: "146",
                  sortname: "MN",
                  c_name: "Mongolia",
                  phonecode: "976",
                  status: false
                },
                {
                  c_id: "147",
                  sortname: "MS",
                  c_name: "Montserrat",
                  phonecode: "1664",
                  status: false
                },
                {
                  c_id: "148",
                  sortname: "MA",
                  c_name: "Morocco",
                  phonecode: "212",
                  status: false
                },
                {
                  c_id: "149",
                  sortname: "MZ",
                  c_name: "Mozambique",
                  phonecode: "258",
                  status: false
                },
                {
                  c_id: "150",
                  sortname: "MM",
                  c_name: "Myanmar",
                  phonecode: "95",
                  status: false
                },
                {
                  c_id: "151",
                  sortname: "NA",
                  c_name: "Namibia",
                  phonecode: "264",
                  status: false
                },
                {
                  c_id: "152",
                  sortname: "NR",
                  c_name: "Nauru",
                  phonecode: "674",
                  status: false
                },
                {
                  c_id: "153",
                  sortname: "NP",
                  c_name: "Nepal",
                  phonecode: "977",
                  status: false
                },
                {
                  c_id: "154",
                  sortname: "AN",
                  c_name: "Netherlands Antilles",
                  phonecode: "599",
                  status: false
                },
                {
                  c_id: "155",
                  sortname: "NL",
                  c_name: "Netherlands The",
                  phonecode: "31",
                  status: false
                },
                {
                  c_id: "156",
                  sortname: "NC",
                  c_name: "New Caledonia",
                  phonecode: "687",
                  status: false
                },
                {
                  c_id: "157",
                  sortname: "NZ",
                  c_name: "New Zealand",
                  phonecode: "64",
                  status: false
                },
                {
                  c_id: "158",
                  sortname: "NI",
                  c_name: "Nicaragua",
                  phonecode: "505",
                  status: false
                },
                {
                  c_id: "159",
                  sortname: "NE",
                  c_name: "Niger",
                  phonecode: "227",
                  status: false
                },
                {
                  c_id: "160",
                  sortname: "NG",
                  c_name: "Nigeria",
                  phonecode: "234",
                  status: false
                },
                {
                  c_id: "161",
                  sortname: "NU",
                  c_name: "Niue",
                  phonecode: "683",
                  status: false
                },
                {
                  c_id: "162",
                  sortname: "NF",
                  c_name: "Norfolk Island",
                  phonecode: "672",
                  status: false
                },
                {
                  c_id: "163",
                  sortname: "MP",
                  c_name: "Northern Mariana Islands",
                  phonecode: "1670",
                  status: false
                },
                {
                  c_id: "164",
                  sortname: "NO",
                  c_name: "Norway",
                  phonecode: "47",
                  status: false
                },
                {
                  c_id: "165",
                  sortname: "OM",
                  c_name: "Oman",
                  phonecode: "968",
                  status: false
                },
                {
                  c_id: "166",
                  sortname: "PK",
                  c_name: "Pakistan",
                  phonecode: "92",
                  status: false
                },
                {
                  c_id: "167",
                  sortname: "PW",
                  c_name: "Palau",
                  phonecode: "680",
                  status: false
                },
                {
                  c_id: "168",
                  sortname: "PS",
                  c_name: "Palestinian Territory Occupied",
                  phonecode: "970",
                  status: false
                },
                {
                  c_id: "169",
                  sortname: "PA",
                  c_name: "Panama",
                  phonecode: "507",
                  status: false
                },
                {
                  c_id: "170",
                  sortname: "PG",
                  c_name: "Papua new Guinea",
                  phonecode: "675",
                  status: false
                },
                {
                  c_id: "171",
                  sortname: "PY",
                  c_name: "Paraguay",
                  phonecode: "595",
                  status: false
                },
                {
                  c_id: "172",
                  sortname: "PE",
                  c_name: "Peru",
                  phonecode: "51",
                  status: false
                },
                {
                  c_id: "173",
                  sortname: "PH",
                  c_name: "Philippines",
                  phonecode: "63",
                  status: false
                },
                {
                  c_id: "174",
                  sortname: "PN",
                  c_name: "Pitcairn Island",
                  phonecode: "0",
                  status: false
                },
                {
                  c_id: "175",
                  sortname: "PL",
                  c_name: "Poland",
                  phonecode: "48",
                  status: false
                },
                {
                  c_id: "176",
                  sortname: "PT",
                  c_name: "Portugal",
                  phonecode: "351",
                  status: false
                },
                {
                  c_id: "177",
                  sortname: "PR",
                  c_name: "Puerto Rico",
                  phonecode: "1787",
                  status: false
                },
                {
                  c_id: "178",
                  sortname: "QA",
                  c_name: "Qatar",
                  phonecode: "974",
                  status: false
                },
                {
                  c_id: "179",
                  sortname: "RE",
                  c_name: "Reunion",
                  phonecode: "262",
                  status: false
                },
                {
                  c_id: "180",
                  sortname: "RO",
                  c_name: "Romania",
                  phonecode: "40",
                  status: false
                },
                {
                  c_id: "181",
                  sortname: "RU",
                  c_name: "Russia",
                  phonecode: "70",
                  status: false
                },
                {
                  c_id: "182",
                  sortname: "RW",
                  c_name: "Rwanda",
                  phonecode: "250",
                  status: false
                },
                {
                  c_id: "183",
                  sortname: "SH",
                  c_name: "Saint Helena",
                  phonecode: "290",
                  status: false
                },
                {
                  c_id: "184",
                  sortname: "KN",
                  c_name: "Saint Kitts And Nevis",
                  phonecode: "1869",
                  status: false
                },
                {
                  c_id: "185",
                  sortname: "LC",
                  c_name: "Saint Lucia",
                  phonecode: "1758",
                  status: false
                },
                {
                  c_id: "186",
                  sortname: "PM",
                  c_name: "Saint Pierre and Miquelon",
                  phonecode: "508",
                  status: false
                },
                {
                  c_id: "187",
                  sortname: "VC",
                  c_name: "Saint Vincent And The Grenadines",
                  phonecode: "1784",
                  status: false
                },
                {
                  c_id: "188",
                  sortname: "WS",
                  c_name: "Samoa",
                  phonecode: "684",
                  status: false
                },
                {
                  c_id: "189",
                  sortname: "SM",
                  c_name: "San Marino",
                  phonecode: "378",
                  status: false
                },
                {
                  c_id: "190",
                  sortname: "ST",
                  c_name: "Sao Tome and Principe",
                  phonecode: "239",
                  status: false
                },
                {
                  c_id: "191",
                  sortname: "SA",
                  c_name: "Saudi Arabia",
                  phonecode: "966",
                  status: false
                },
                {
                  c_id: "192",
                  sortname: "SN",
                  c_name: "Senegal",
                  phonecode: "221",
                  status: false
                },
                {
                  c_id: "193",
                  sortname: "RS",
                  c_name: "Serbia",
                  phonecode: "381",
                  status: false
                },
                {
                  c_id: "194",
                  sortname: "SC",
                  c_name: "Seychelles",
                  phonecode: "248",
                  status: false
                },
                {
                  c_id: "195",
                  sortname: "SL",
                  c_name: "Sierra Leone",
                  phonecode: "232",
                  status: false
                },
                {
                  c_id: "196",
                  sortname: "SG",
                  c_name: "Singapore",
                  phonecode: "65",
                  status: false
                },
                {
                  c_id: "197",
                  sortname: "SK",
                  c_name: "Slovakia",
                  phonecode: "421",
                  status: false
                },
                {
                  c_id: "198",
                  sortname: "SI",
                  c_name: "Slovenia",
                  phonecode: "386",
                  status: false
                },
                {
                  c_id: "199",
                  sortname: "XG",
                  c_name: "Smaller Territories of the UK",
                  phonecode: "44",
                  status: false
                },
                {
                  c_id: "200",
                  sortname: "SB",
                  c_name: "Solomon Islands",
                  phonecode: "677",
                  status: false
                },
                {
                  c_id: "201",
                  sortname: "SO",
                  c_name: "Somalia",
                  phonecode: "252",
                  status: false
                },
                {
                  c_id: "202",
                  sortname: "ZA",
                  c_name: "South Africa",
                  phonecode: "27",
                  status: false
                },
                {
                  c_id: "203",
                  sortname: "GS",
                  c_name: "South Georgia",
                  phonecode: "0",
                  status: false
                },
                {
                  c_id: "204",
                  sortname: "SS",
                  c_name: "South Sudan",
                  phonecode: "211",
                  status: false
                },
                {
                  c_id: "205",
                  sortname: "ES",
                  c_name: "Spain",
                  phonecode: "34",
                  status: false
                },
                {
                  c_id: "206",
                  sortname: "LK",
                  c_name: "Sri Lanka",
                  phonecode: "94",
                  status: false
                },
                {
                  c_id: "207",
                  sortname: "SD",
                  c_name: "Sudan",
                  phonecode: "249",
                  status: false
                },
                {
                  c_id: "208",
                  sortname: "SR",
                  c_name: "Suriname",
                  phonecode: "597",
                  status: false
                },
                {
                  c_id: "209",
                  sortname: "SJ",
                  c_name: "Svalbard And Jan Mayen Islands",
                  phonecode: "47",
                  status: false
                },
                {
                  c_id: "210",
                  sortname: "SZ",
                  c_name: "Swaziland",
                  phonecode: "268",
                  status: false
                },
                {
                  c_id: "211",
                  sortname: "SE",
                  c_name: "Sweden",
                  phonecode: "46",
                  status: false
                },
                {
                  c_id: "212",
                  sortname: "CH",
                  c_name: "Switzerland",
                  phonecode: "41",
                  status: false
                },
                {
                  c_id: "213",
                  sortname: "SY",
                  c_name: "Syria",
                  phonecode: "963",
                  status: false
                },
                {
                  c_id: "214",
                  sortname: "TW",
                  c_name: "Taiwan",
                  phonecode: "886",
                  status: false
                },
                {
                  c_id: "215",
                  sortname: "TJ",
                  c_name: "Tajikistan",
                  phonecode: "992",
                  status: false
                },
                {
                  c_id: "216",
                  sortname: "TZ",
                  c_name: "Tanzania",
                  phonecode: "255",
                  status: false
                },
                {
                  c_id: "217",
                  sortname: "TH",
                  c_name: "Thailand",
                  phonecode: "66",
                  status: false
                },
                {
                  c_id: "218",
                  sortname: "TG",
                  c_name: "Togo",
                  phonecode: "228",
                  status: false
                },
                {
                  c_id: "219",
                  sortname: "TK",
                  c_name: "Tokelau",
                  phonecode: "690",
                  status: false
                },
                {
                  c_id: "220",
                  sortname: "TO",
                  c_name: "Tonga",
                  phonecode: "676",
                  status: false
                },
                {
                  c_id: "221",
                  sortname: "TT",
                  c_name: "Trinidad And Tobago",
                  phonecode: "1868",
                  status: false
                },
                {
                  c_id: "222",
                  sortname: "TN",
                  c_name: "Tunisia",
                  phonecode: "216",
                  status: false
                },
                {
                  c_id: "223",
                  sortname: "TR",
                  c_name: "Turkey",
                  phonecode: "90",
                  status: false
                },
                {
                  c_id: "224",
                  sortname: "TM",
                  c_name: "Turkmenistan",
                  phonecode: "7370",
                  status: false
                },
                {
                  c_id: "225",
                  sortname: "TC",
                  c_name: "Turks And Caicos Islands",
                  phonecode: "1649",
                  status: false
                },
                {
                  c_id: "226",
                  sortname: "TV",
                  c_name: "Tuvalu",
                  phonecode: "688",
                  status: false
                },
                {
                  c_id: "227",
                  sortname: "UG",
                  c_name: "Uganda",
                  phonecode: "256",
                  status: false
                },
                {
                  c_id: "228",
                  sortname: "UA",
                  c_name: "Ukraine",
                  phonecode: "380",
                  status: false
                },
                {
                  c_id: "229",
                  sortname: "UAE",
                  c_name: "United Arab Emirates",
                  phonecode: "971",
                  status: false
                },
                {
                  c_id: "230",
                  sortname: "UK",
                  c_name: "United Kingdom",
                  phonecode: "44",
                  status: false
                },
                {
                  c_id: "231",
                  sortname: "US",
                  c_name: "United States",
                  phonecode: "1",
                  status: false
                },
                {
                  c_id: "232",
                  sortname: "UM",
                  c_name: "United States Minor Outlying Islands",
                  phonecode: "1",
                  status: false
                },
                {
                  c_id: "233",
                  sortname: "UY",
                  c_name: "Uruguay",
                  phonecode: "598",
                  status: false
                },
                {
                  c_id: "234",
                  sortname: "UZ",
                  c_name: "Uzbekistan",
                  phonecode: "998",
                  status: false
                },
                {
                  c_id: "235",
                  sortname: "VU",
                  c_name: "Vanuatu",
                  phonecode: "678",
                  status: false
                },
                {
                  c_id: "236",
                  sortname: "VA",
                  c_name: "Vatican City State (Holy See)",
                  phonecode: "39",
                  status: false
                },
                {
                  c_id: "237",
                  sortname: "VE",
                  c_name: "Venezuela",
                  phonecode: "58",
                  status: false
                },
                {
                  c_id: "238",
                  sortname: "VN",
                  c_name: "Vietnam",
                  phonecode: "84",
                  status: false
                },
                {
                  c_id: "239",
                  sortname: "VG",
                  c_name: "Virgin Islands (British)",
                  phonecode: "1284",
                  status: false
                },
                {
                  c_id: "240",
                  sortname: "VI",
                  c_name: "Virgin Islands (US)",
                  phonecode: "1340",
                  status: false
                },
                {
                  c_id: "241",
                  sortname: "WF",
                  c_name: "Wallis And Futuna Islands",
                  phonecode: "681",
                  status: false
                },
                {
                  c_id: "242",
                  sortname: "EH",
                  c_name: "Western Sahara",
                  phonecode: "212",
                  status: false
                },
                {
                  c_id: "243",
                  sortname: "YE",
                  c_name: "Yemen",
                  phonecode: "967",
                  status: false
                },
                {
                  c_id: "244",
                  sortname: "YU",
                  c_name: "Yugoslavia",
                  phonecode: "38",
                  status: false
                },
                {
                  c_id: "245",
                  sortname: "ZM",
                  c_name: "Zambia",
                  phonecode: "260",
                  status: false
                },
                {
                  c_id: "246",
                  sortname: "ZW",
                  c_name: "Zimbabwe",
                  phonecode: "263",
                  status: false
                }
              ],
              countryName:''
        }
    }
    // const [commCard] = useState({
    //     list: [
    //         { img: shoppingSix, name: 'Apoorva', category: 'Model', rank: '#1' },
    //         { img: shoppingSix, name: 'Apoorva', category: 'Model', rank: '#1' },
    //         { img: shoppingSix, name: 'Apoorva', category: 'Model', rank: '#1' },
    //         { img: shoppingSix, name: 'Apoorva', category: 'Model', rank: '#1' },
    //         { img: shoppingSix, name: 'Apoorva', category: 'Model', rank: '#1' },
    //         { img: shoppingSix, name: 'Apoorva', category: 'Model', rank: '#1' },
    //         { img: shoppingSix, name: 'Apoorva', category: 'Model', rank: '#1' },
    //         { img: shoppingSix, name: 'Apoorva', category: 'Model', rank: '#1' },
    //     ]
    // })
    componentDidMount = () => {
        // const productData = localStorage.getItem('productData')
        window.scrollTo(0, 0);
        // console.log("dataLocal==>",JSON.parse(productData))
        // this.setState({p_id:JSON.parse(productData).pr_id}
        this.LeaderBoard()

    }


    LeaderBoard = () => {

        try {
            var data = {
                "filter_text": this.state.fd ? "Fashion Designer" : this.state.ph ? "Photographer" : this.state.m ? "Model" : this.state.ma ? "Makeup Artist" : this.state.b ? "Blogger" : "",
                "flag":this.state.countryName
            }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                redirect: 'follow'
            };


            fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/leaderboard_list.php", requestOptions)
                .then(async resp => {

                    const json = await resp.json()
                    console.log("data==>", json)
                    console.log("leaderboard_list>>>>>", resp)
                    if (resp.status == 200) {
                        console.log("data_SignUpApi====>", JSON.stringify(resp))
                        // const newResult = resp.data[5].map(item => { return `https://demo.smileitsolutions.com/kaaf/${item}` })
                        this.setState({
                            commCard: json.list, isLoading: false
                        })
                    }
                    else {
                        this.setState({ isLoading: false }, () => alert("Invalid credentials please try again."))
                    }
                })
        }
        catch (error) {
            console.log("leaderboard_list_catch====>", error);
        }
    }



    onChangeSort =async (event) => {
      
        this.setState({ countryName: event.target.value })
        try {
            var data = {
                "filter_text": this.state.fd ? "Fashion Designer" : this.state.ph ? "Photographer" : this.state.m ? "Model" : this.state.ma ? "Makeup Artist" : this.state.b ? "Blogger" : "",
                "flag": event.target.value
            }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                redirect: 'follow'
            };


            fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/leaderboard_list.php", requestOptions)
                .then(async resp => {

                    const json = await resp.json()
                    console.log("data==>", json)
                    console.log("leaderboard_list>>>>>", resp)
                    if (resp.status == 200) {
                        console.log("data_SignUpApi====>", JSON.stringify(resp))
                        // const newResult = resp.data[5].map(item => { return `https://demo.smileitsolutions.com/kaaf/${item}` })
                        this.setState({
                            commCard: json.list, isLoading: false
                        })
                    }
                    else {
                        this.setState({ isLoading: false }, () => alert("Invalid credentials please try again."))
                    }
                })
        }
        catch (error) {
            console.log("leaderboard_list_catch====>", error);
        }

    }















    press = (i) => {
        // onClick={()=>this.view("member")}
        localStorage.setItem("communityData", JSON.stringify(i))
        this.props.history.push('/viewFreelancer')
    }


    render() {
        return (
            <div>
                <Navbar />

                <section className="freelancer_listing pt-3 pb-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3 col-md-4">
                                <div className="product_filter">
                                    <div className="filter_title">
                                        <h3>Choose Category</h3>
                                        <span><i className="fa fa-filter" aria-hidden="true"></i></span>
                                    </div>
                                    <div className="community_filter_box">
                                        <div className="community_category">
                                            <input type="checkbox"
                                                onClick={() => this.setState({
                                                    fd: false, ph: false, m: !this.state.m, ma: false, b: false, ma: false, i: false

                                                }, () => this.LeaderBoard())}
                                            />
                                            <label for="">Models</label>
                                        </div>
                                        <div className="community_category">
                                            <input type="checkbox"
                                                onClick={() => this.setState({
                                                    fd: false, ph: false, m: false, ma: false, b: !this.state.b, ma: false, i: false

                                                }, () => this.LeaderBoard())}

                                            />
                                            <label for="">Bloggers</label>
                                        </div>
                                        <div className="community_category">
                                            <input type="checkbox"
                                                onClick={() => this.setState({
                                                    fd: !this.state.fd, ph: false, m: false, ma: false, b: false, ma: false, i: false

                                                }, () => this.LeaderBoard())}

                                            />
                                            <label for="">Fashion Designers</label>
                                        </div>
                                        <div className="community_category">
                                            <input type="checkbox"
                                                onClick={() => this.setState({
                                                    fd: false, ph: !this.state.ph, m: false, ma: false, b: false, ma: false, i: false

                                                }, () => this.LeaderBoard())}
                                            />
                                            <label for="">Photographers</label>
                                        </div>
                                        <div className="community_category">
                                            <input type="checkbox" onClick={() => this.setState({
                                                fd: false, ph: false, m: false, ma: false, b: false, ma: false, i: !this.state.i

                                            }, () => this.LeaderBoard())} />
                                            <label for="">Influencers</label>
                                        </div>
                                        <div className="community_category">
                                            <input type="checkbox"
                                                onClick={() => this.setState({
                                                    fd: false, ph: false, m: false, ma: !this.state.ma, b: false, ma: false, i: false

                                                }, () => this.LeaderBoard())}
                                            />
                                            <label for="">Makeup Artists</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='community_resp_search'>
                                    <div className='row'>
                                        <div className='col-8 pr-1'>
                                            <input type="text" placeholder="Search Freelancer" />
                                        </div>
                                        <div className='col-4 pl-1'>
                                            <select name="flag_filter" required=""  onChange={(event) => this.onChangeSort(event)}  >
                                            {
                                                this.state.country_data.map((i)=>{
                                                    return(
                                                        <option selected="" value={i.c_name}>{i.c_name}</option>
                                                    )
                                                })
                                            }   
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className='community_filter'>
                                    <a><button>Models</button></a>
                                    <a><button>Bloggers</button></a>
                                    <a><button>Fashion Designers</button></a>
                                    <a><button>Photographers</button></a>
                                    <a><button>Influencers</button></a>
                                    <a><button>Makeup Artists</button></a>
                                </div>
                            </div>
                            <div className="col-lg-9 col-md-8">
                                <div className="register_community_strip">
                                    <h3>Are you interested to join KAAF Community?<a className="sign_up_community_btn" href="#">Sign up</a></h3>
                                </div>
                                <div className="sorting_community_bar">
                                    <div className='row'>
                                        <div className='col-8 pr-1'>
                                            <input type="text" placeholder="Search Freelancer" />
                                        </div>
                                        <div className='col-4 pl-1'>
                                            <select name="flag_filter" required="">
                                              
                                                <option selected="" value="1">Text1</option>
                                                <option value="0">Text1</option>
                                                <option value="0">Text1</option>
                                                <option value="0">Text1</option>
                                                <option value="0">Text1</option>
                                                <option value="0">Text1</option>
                                                <option value="0">Text1</option>
                                                <option value="1">Text1</option>
                                                <option value="1">Text1</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        this.state.commCard.map((i) => {
                                            var img = i.country ? i.country.split(',') : []
                                            var comName = i.name.trim().replace(/\s+/g, "_");
                                            return (
                                                <Link to={'/' + comName} className="col-lg-6 col-md-11 m-auto pl-2 pr-2">
                                                    <div className="community_card">
                                                        <div className="row">
                                                            <div className="col-lg-5 col-md-4 col-sm-4 col-4 mr-auto">
                                                                <div className='freelancer_card_img_box'>
                                                                    <img className="freelancer_card_img"
                                                                        src={`https://demo.smileitsolutions.com/kaaf/uploads/${i.image}`} alt="" />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-7 col-md-8 col-sm-8 col-8 pl-0">
                                                                <div className="freelancer_rank">
                                                                    <img src={rankBadge} alt="" />
                                                                    <span>{i.rank ? `${i.rank}` : ''}</span>
                                                                </div>
                                                                {
                                                                    i.country != null || i.country != "" ?
                                                                        img.map((i) => {
                                                                            return (
                                                                                <span className="comm_flag_box">
                                                                                    <img src={`https://demo.smileitsolutions.com/kaaf/${i}`} alt="" />
                                                                                </span>
                                                                            )
                                                                        })
                                                                        : null
                                                                }
                                                                <div className="freelancer_name">
                                                                    <h3>
                                                                        <a>{i.name}</a>
                                                                    </h3>
                                                                    <h4>{i.category}</h4>
                                                                    <h4>{i.community_name}</h4>
                                                                    
                                                                </div>
                                                                <a className="freelancer_hire_btn" href="/"><i class="fa fa-phone" aria-hidden="true"></i>Hire now</a>
                                                            </div>
                                                            {/* <div className="col-4">
                                                                
                                                                <div className="freelancer_rank">#{i.rank}</div>
                                                                
                                                            </div> */}
                                                        </div>
                                                        {/* <Link to="/viewProduct"><img src={i.img} alt="" /></Link>

                                                    <span className="wishlist_icon"><i className="fa fa-heart" aria-hidden="true"></i></span>
                                                    <div to="/viewProduct" className="product_price_title">
                                                        <h3>
                                                            <a>{i.name}</a>
                                                        </h3>
                                                        <span className="current_price">{i.currentprice}</span>
                                                        <span className="actual_price">{i.actualprice}</span>
                                                        <span className="percent_price">{i.offprice}</span>
                                                        <div class="product_reviews_stars">

                                                        </div>
                                                    </div> */}
                                                    </div>
                                                </Link>
                                            )
                                        })
                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        )
    }

}