import React, { Component } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SocialLogin from '../components/SocialLogin'
import swal from 'sweetalert';




export default class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phn_cc: "",
            number: ""
        }
    }



    onChangeSort = (event) => {
        // console.log( JSON.parse(event.target.value));
        this.setState({
            phn_cc: JSON.parse(event.target.value),
            // countryName:JSON.parse(event.target.value).name
        })
    }


    loginApi = async () => {
        console.log("data==>", "i")

        try {

            var data = {
                "mobile": this.state.number,
                "mobile_cc": this.state.phn_cc,
            }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                redirect: 'follow'
            };
            console.log("data==>", data)

            fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/login.php", requestOptions)
                .then(async resp => {
                    console.log("data==>", resp)
                    const json = await resp.json()
                    console.log("data==>", resp, json)
                    if (resp.status === 200) {
                        console.log("data==>", resp, json)
                        alert("Login successfully!")
                        this.props.history.push('/')
                    }
                    else {
                        this.setState({ isLoading: false },() => swal({
                            title: "Alert",
                            text: "Something went wrong. Please try after sometime.",
                            icon: "error",
                            button: "Ok",
                          }))
                        // alert("Something went wrong please try again.")
                    }
                }
                )
                .catch(errr => {
                    this.setState({ isLoading: false })
                });




        }
        catch (error) {
            console.log("home_product_lists_catch====>", error);
        }
    }


    LoginSubmitApi = async () => {
        try {
            var data = {
                "mobile": this.state.number,
                "mobile_cc": +971,
            }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                redirect: 'follow',
                mode: 'no-cors'
            };
            console.log("data==>", requestOptions)
            fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/login.php", requestOptions)
                .then(async resp => {
                    console.log("data==>", resp)
                    const json = await resp.json()
                    console.log("data==>", resp, json)
                    if (resp.status === 200) {
                        console.log("data==>200", resp, json)

                    }
                    else {
                        this.setState({ isLoading: false })
                        // alert("Something went wrong please try again.")
                    }
                }
                )
                .catch(errr => {
                    this.setState({ isLoading: false })
                });



        }
        catch (error) {
            console.log("home_product_lists_catch====>", error);
        }
    }




    render() {
        return (
            <div>
                <Navbar />
                <section className="pt-5 pb-5 cart_page login_page_bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-9 m-auto">
                                <div className="login_signup_page">
                                    <h2>Login To Your Account</h2>
                                    <div className="row align-items-center">
                                        {/* <div className="col-md-6">
                                        <SocialLogin login="In hurry? Login with with:" />
                                    </div> */}
                                        <div className="col-md-12">
                                            <div className="login_signup_form">
                                                <div className="row">
                                                    <div className="col-3 pr-0">
                                                        <select name="phone_cc" onChange={this.onChangeSort}
                                                            id="phone_cc" placeholder="Country Code" required="">
                                                            <option selected="" value="91">UAE (+971)</option>

                                                            <option value="968">OM (+968)</option>
                                                            <option value="91">IN (+91)</option>
                                                            <option value="974">QA (+974)</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-9">
                                                        <input type="number" placeholder="Mobile Number" onChange={(text) => this.setState({ number: text.target.value })} />
                                                    </div>
                                                    {/* <div className="col-12">
                                                    <input type="password" placeholder="Password" />
                                                </div> */}

                                                    <div className="col-12">
                                                        <button type="submit" 
                                                            onClick={()=>this.loginApi()}
                                                            // onClick={() => swal({
                                                            //     title: "Alert",
                                                            //     text: "Something went wrong. Please try after sometime.",
                                                            //     icon: "error",
                                                            //     button: "Ok",
                                                            // })}  
                          >Login</button>
                                                        <p>Don't have an account? <a href="/signup">Sign Up</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Footer />
            </div>
        )
    }


}
