export const logoImage = require('../assets/images/website-logo.png').default
export const download = require('../assets/images/download.png').default

export const menuImagetwo = require('../assets/images/menu-ads.png').default

export const menuImage = require('../assets/images/menu-ads2.png').default





export const shoppingOne = require('../assets/images/shopCategory-1.png').default
export const shoppingTwo = require('../assets/images/shopCategory-2.png').default
export const shoppingThree = require('../assets/images/shopCategory-3.png').default
export const shoppingFour = require('../assets/images/shopCategory-4.png').default
export const shoppingFive = require('../assets/images/shopCategory-5.png').default
export const shoppingSix = require('../assets/images/shopCategory-6.png').default



export const featuredBrandOne = require('../assets/images/ft-brand-01.png').default



export const googlePlay = require('../assets/images/google-play-store.png').default
export const appleStore = require('../assets/images/apple-store.png').default
export const paymentMethod = require('../assets/images/payment-gateway.png').default



export const brandOne = require('../assets/images/brand-1.png').default
export const brandTwo = require('../assets/images/brand-2.png').default
export const brandThree = require('../assets/images/brand-3.png').default
export const brandFour = require('../assets/images/brand-5.png').default
export const brandFive = require('../assets/images/brand-6.png').default
export const brandSix = require('../assets/images/brand-7.png').default
export const brandSeven = require('../assets/images/brand-8.png').default
export const brandEight = require('../assets/images/brand-9.png').default


export const seasonSale1 = require('../assets/images/sale-1.png').default
export const seasonSale2 = require('../assets/images/sale-2.png').default


export const dressOne = require('../assets/images/outfit_1.png').default
export const dressTwo = require('../assets/images/outfit_2.png').default
export const dressThree = require('../assets/images/outfit_3.png').default
export const dressFour = require('../assets/images/outfit_4.png').default
export const dressFive = require('../assets/images/outfit_5.png').default
export const dressSix = require('../assets/images/outfit_6.png').default
export const dressSeven = require('../assets/images/outfit_7.png').default
export const dressEight = require('../assets/images/outfit_8.png').default

export const featuredAdvert = require('../assets/images/featured_advertisement.png').default

// export const hireBanner = require('../assets/images/banner_hire_img.png').default
export const hireBanner = require('../assets/images/kaaf-hire-banner.png').default

export const mainBannerDress = require('../assets/images/banner-dress-img.png').default

export const paymentGateway = require('../assets/images/payment-gateway.png').default

export const communityIcon = require('../assets/images/comm-2.png').default

export const redCarpetR = require('../assets/images/red-carpet-2.png').default

export const instagram = require('../assets/images/instagram.png').default
export const facebook = require('../assets/images/facebook.png').default
export const linkedin = require('../assets/images/linkedin.png').default
export const youtube = require('../assets/images/youtube (1).png').default
export const call = require('../assets/images/call.png').default
export const email = require('../assets/images/email.png').default
export const webImg = require('../assets/images/web.png').default
export const location = require('../assets/images/location.png').default

export const mainSliderImg = require('../assets/images/kaaf-slider-img-2.png').default

export const categorySampleOne = require('../assets/images/subcategory-img-1.png').default
export const categorySampleTwo = require('../assets/images/subcategory-img-2.png').default

export const By_Yasmin = require('../assets/images/By_Yasmin.png').default
export const registration = require('../assets/images/registration.png').default
export const rankBadge = require('../assets/images/rank-badge.png').default

export const indiaFlag = require('../assets/images/india.png').default
export const uaeFlag = require('../assets/images/united-arab-emirates.png').default

export const mobMenuOne = require('../assets/images/mob-menu-1.png').default
export const mobMenuTwo = require('../assets/images/mob-menu-2.png').default
export const mobMenuThree = require('../assets/images/mob-menu-3.png').default
export const mobMenuFour = require('../assets/images/mob-menu-4.png').default
export const mobMenuFive = require('../assets/images/mob-menu-5.png').default