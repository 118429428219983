import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'


export default function ForgotPassword() {
    return (
        <div>
            <Navbar/>
            <section className="pt-5 pb-5 cart_page">
        <div className="container">
            <div className="row">
                <div className="col-md-5 m-auto">
                    <div className="forgot_password">
                        <h3>Forgot Password</h3>
                        <input type="email" placeholder="Enter Your Email" />
                       
                        <button type="submit">Reset Password</button>
                        <p>Don't want to reset? <a href="/">Login here</a></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
            <Footer/>
        </div>
    )
}
