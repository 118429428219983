import React, { Component } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {country_states} from '../components/stateData';
import swal from 'sweetalert';
export default class CheckoutAdress extends Component {
constructor(props){
  super(props);
  this.state={
  countryData:[
      {
          "name": "Oman",
          "dialCode": "+968",
          "minLength": 8,
          "maxLength": 13,
      },
      {
          "name": "United Arab Emirates",
          "dialCode": "+971",
          "minLength": 8,
          "maxLength": 13,
      },
      {
          "name": "Qatar",
          "dialCode": "+974",
          "minLength": 8,
          "maxLength": 13,
      },
  ],
  // AllStates:'',
  state_Array:[],
  selectedCountry:"United Arab Emirates",
  stateValue:'',
  code1:'+971',
  code2:'+971'
  }
}




checkoutApi=()=>{
 try{
 var data = {
    "jwt":"eyJpc3MiOiJodHRwOlwvXC9leGFtcGxlLm9yZyIsImF1ZCI6Imh0dHA6XC9cL2V4YW1wbGUuY29tIiwiaWF0IjoxMzU2OTk5NTI0LCJuYmYiOjEzNTcwMDAwMDAsImRhdGEiOnsiaWQiOiIxMTUiLCJ1c2VyX3R5cGUiOm51bGwsIm5hbWUiOm51bGx9fQ",
    "order_amount":"500",
    "cust_name":this.state.name,
    "mobile":this.state.phone1,
    "alternate_mob":this.state.phone2,
    "po_box_no":this.state.poBox,
    "country":this.state.selectedCountry,
    "state":this.state.stateValue,
    "address":this.state.address
  }
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "text/plain");
  var requestOptions = {
    method: 'POST',
    body: JSON.stringify(data),
    redirect: 'follow'
  };

  console.log("data==>", data)
  fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/checkout.php", requestOptions)
    .then(async resp => {
      console.log("Api_resp", resp)
      const json = await resp.json()
      console.log("home_product_lists==>", json)
      if (resp.status == 200) {
          // console.log("data_SignUpApi====>", json)
        // const newResult = resp.data[5].map(item => { return `https://demo.smileitsolutions.com/kaaf/${item}` })

        swal({
          title: "Alert",
          text: "Order Place Successfully.",
          icon: "success",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
              this.props.history.push('/')
          //   swal("Poof! Your imaginary file has been deleted!", {
          //     icon: "success",
          //   });
          } 
        })
        
        
      }

      else if (resp.status == 201) {
        this.setState({
            list: [],
            isLoading: false,
        }, () =>

        swal({
            title: "Alert",
            text: json.message,
            icon: "error",
            button: "Ok",
          }))

    }

    else if (resp.status == 401) {
        this.setState({
            isLoading: false,
        }, () =>
        swal({
            title: "Alert",
            text: "Please login.",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          .then((willDelete) => {
            if (willDelete) {
                this.props.history.push('/login')
            //   swal("Poof! Your imaginary file has been deleted!", {
            //     icon: "success",
            //   });
            } else {
            //   swal("Your imaginary file is safe!");
            }
          }))
    }

      else {
        this.setState({ isLoading: false, openSearch: false }, () => alert("Invalid credentials please try again."))
      }
    })
}
catch (error) {
  console.log("home_product_lists_catch====>", error);
}

}







componentDidMount=()=>{
  let State_array = []
  setTimeout(() => {
    for (var i = 0; i < country_states.length; i++) {
      if (country_states[i].name ==  this.state.selectedCountry) {

        let d = []
        this.setState({ AllStates: d })
        this.state.AllStates.push({ ...country_states[i].states })
        Object.keys(country_states[i].states).map((item1, index1) => {
          return State_array.push(Object.keys(country_states[i].states)[index1])

        })
      }
    }

    this.setState({
      state_Array: State_array,stateValue:State_array[0]
    }, () => console.log("data==>", this.state.state_Array))
  }, 500)
}



handleChange=(event)=> {
  console.log("data==>", event.target.value)

  let State_array = []
  console.log("data==>>", country_states)
  this.setState({ selectedCountry: event.target.value})
  setTimeout(() => {
    for (var i = 0; i < country_states.length; i++) {
      if (country_states[i].name ==  event.target.value) {

        let d = []
        this.setState({ AllStates: d })
        this.state.AllStates.push({ ...country_states[i].states })
        Object.keys(country_states[i].states).map((item1, index1) => {
          return State_array.push(Object.keys(country_states[i].states)[index1])

        })
      }
    }

    this.setState({
      state_Array: State_array
    }, () => console.log("data==>", this.state.state_Array))
  }, 500)


}




  render() {
    return (
      <div>
        <Navbar />
        <section className="pt-5 pb-5 cart_page">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 m-auto">
                <div className="login_signup_page">
                  <h2>Fill Address Details</h2>

                  <div className="row align-items-center">
                    {/* <div className="col-md-6">
                                        <SocialLogin login="Why be old fashioned? Just signup with:" />
                                    </div> */}
                    <div className="col-md-12">
                      <div className="checkout_address_form">
                        <div className="row">
                          <div className="col-md-6 col-12">
                            <select
                              name="user_type"
                              id="user_type"
                              placeholder="Select Country"
                              required=""
                              
                              value={this.state.value} onChange={(event)=>this.handleChange(event)}
                            >
                              {this.state.countryData.map((i)=>{
                                return(
                                  <option selected={i.name} value={i.name}>
                                  {i.name}
                                </option>
                                )
                              })
                              }
                              {/* <option value="India">India</option> */}
                            </select>
                            {/* <input type="text" placeholder="Last Name" /> */}
                          </div>
                          <div className="col-md-6 col-12">
                            <select
                              name="user_type"
                              id="user_type"
                              placeholder="Select State"
                              required=""
                              value={this.state.stateValue} onChange={(event)=>this.setState({stateValue:event.target.value})}
                            >
                                {this.state.state_Array.map((i)=>{
                                return(
                                  <option selected={i} value={i}>
                                  {i}
                                </option>
                                )
                              })
                              }
                            </select>
                            {/* <input type="text" placeholder="Last Name" /> */}
                          </div>
                          <div className="col-md-6 col-12">
                            <input type="text" placeholder="User Name" onChange={(event)=>this.setState({name:event.target.value})} />
                          </div>
                          <div className="col-md-6 col-12">
                            <input type="text" placeholder="PO Box Number" onChange={(event)=>this.setState({poBox:event.target.value})} />
                          </div>
                          <div className="col-12">
                            <input type="text" placeholder="Address" onChange={(event)=>this.setState({address:event.target.value})} />
                          </div>
                          <div className="col-md-6 col-12">
                              <div className="row">
                              <div className="col-4 pr-0">
                            <select
                              name="phone_cc"
                              id="phone_cc"
                              placeholder="Country Code"
                              required
                              required=""
                              value={this.state.code1} onChange={(event)=>this.setState({code1:event.target.value})} 
                            >
                              {/* <option selected=""   >
                                {this.state.code1}
                              </option> */}
                              <option value="+968">+968</option>
                              <option value="+91">+974</option>
                              <option value="+974">+971</option>
                            </select>
                          </div>
                          <div className="col-8">
                            <input
                              type="number"
                              placeholder="Mobile Number"
                              maxlength="11"
                              onChange={(event)=>this.setState({phone1:event.target.value})}
                            />
                          </div>
                              </div>
                          </div>
                          <div className="col-md-6 col-12">
                              <div className="row">
                              <div className="col-4 pr-0">
                            <select
                              name="phone_cc"
                              id="phone_cc"
                              placeholder="Country Code"
                              required
                              required=""
                              value={this.state.code2} onChange={(event)=>this.setState({code2:event.target.value})} 
                            >
                              {/* <option selected=""   >
                                {this.state.code1}
                              </option> */}
                              <option value="+968">+968</option>
                              <option value="+91">+974</option>
                              <option value="+974">+971</option>
                            </select>
                          </div>
                          <div className="col-8">
                            <input
                              type="number"
                              placeholder="Alternate Mobile Number"
                              maxlength="11"
                              onChange={(event)=>this.setState({phone2:event.target.value})}
                            />
                          </div>
                              </div>
                          </div>
                          <div className="col-12 text-center">
                            <button onClick={()=>this.checkoutApi()} type="submit">Submit</button>                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}
