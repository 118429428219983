import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { googlePlay, appleStore, instagram, facebook, linkedin, youtube, call, email, webImg, location } from "../components/images";

export default function ContactUs() {
    return (
        <div>
            <Navbar />

            <section class="contact_outer_container">
                <div class="followus">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h5>DOWNLOAD APP</h5>
                            </div>
                            <div class="col-lg-3 col-sm-6 ml-auto text-center borders">
                                <div class="app">
                                    <a href="/"><img src={appleStore} alt="" class="img-responsive" /></a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6 mr-auto text-center">
                                <div class="app">
                                    <a href="/"><img src={googlePlay} alt="" class="img-responsive" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </section>

            <div class="hr"></div>

            <section class="contact_outer_container">
                <div class="followus">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h5>FOLLOW US ON</h5>
                            </div>
                            <div class="col-lg-3 col-sm-6 borders">
                                <div class="social_box">
                                    <img src={instagram} alt="" />
                                    <h5>Instagram</h5>
                                    <a href="#">/KAAFofficial</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6 borders_responsive">
                                <div class="social_box">
                                    <img src={youtube} alt="" />
                                    <h5>Youtube</h5>
                                    <a href="#">/KAAFofficial</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6 borders">
                                <div class="social_box">
                                    <img src={linkedin} alt="" />
                                    <h5>Linkedin</h5>
                                    <a href="#">/KAAFofficial</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="social_box">
                                    <img src={facebook} alt="" />
                                    <h5>Facebook</h5>
                                    <a href="#">/KAAFofficial</a>
                                </div>
                            </div>
                            {/* <!-- <div class="col-md-3 col-sm-6">
                        <div class="youtube-box">
                            <div class="youtube">
                                <img src="images/youtube (1).png" alt="">
                            </div>
                            <h5>youtube</h5>
                            <a href="#">/KAAFofficial</a>
                        </div>
                    </div> -->
                    <!-- <div class="col-md-3">
                        <div class="linedin-box">
                            <div class="linkedin">
                                <img src="images/linkedin.png" alt="">
                            </div>
                            <h5>linkedin</h5>
                            <a href="#">/KAAFofficial</a>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="facebook-box">
                            <div class="facebook">
                                <img src="images/facebook.png" alt="">
                            </div>
                            <h5>instagram</h5>
                            <a href="#">/KAAFofficial</a>
                        </div>
                    </div> --> */}
                        </div>
                    </div>
                </div>
            </section>

            <div class="hr"></div>


            <section class="contact_outer_container">
                <div class="followus">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <h5>CONTACT INFORMATION</h5>
                            </div>
                            <div class="col-lg-3 col-sm-6 borders">
                                <div class="social_box">
                                    <img src={call} alt="" />
                                    <h5>Call Us</h5>
                                    <a href="#">+971 56 591 3786</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6 borders_responsive">
                                <div class="social_box">
                                    <img src={email} alt="" />
                                    <h5>Email</h5>
                                    <a href="#">info.KAAFofficial@gmail.com</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6 borders">
                                <div class="social_box">
                                    <img src={webImg} alt="" />
                                    <h5>Website</h5>
                                    <a href="#">www.KAAFofficial.com</a>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="social_box">
                                    <img src={location} alt="" />
                                    <h5>Location</h5>
                                    <a href="#">Dubai</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="hr"></div>

            <section class="contact_outer_container">
                <div class="followus">
                    <div class="container">
                        <div class="form">
                            <div class="row">
                                <div class="col-12">
                                    <h5>CONNECT WITH US</h5>
                                </div>
                            </div>

                            <form action="">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group col-md-12">
                                            <input type="NAME" class="form-control" name="username" placeholder="Your Name" />
                                        </div>
                                        <div class="form-group col-md-12">
                                            <input type="NAME" class="form-control" name="username"
                                                placeholder="Phone Number" />
                                        </div>
                                        <div class="form-group col-md-12">
                                            <input type="NAME" class="form-control" name="username" placeholder="Email" />
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="col-12">
                                            <div class="text">

                                                <textarea name="message" cols="40" rows="6"
                                                    class="form-control wpcf7-textarea wpcf7-validates-as-required form-control"
                                                    id="message" aria-required="true" aria-invalid="false"
                                                    placeholder="Message"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">
                                        <button type="submit" class="conatct_submit_btn" name="contact_us">SUBMIT NOW</button>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </div>
    )
}
