import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { hireBanner } from '../components/images'
import SectionTitle from './sectionTitle'
export default function HiringBanner() {
    // const [listArr] = useState({
    //     list: [
    //         { img: featuredBrandOne, name: 'Apoorva' }
    //     ]
    // })
    return (
        <div class="">
            <div class="hiring_banner_box pb-3">
                <div class="hire_banner_bg text-center">
                    <h3 class="hire_title">Do you want to hire Fashion Designers?</h3>
                </div>
                <div class="hire_banner_img_box">
                    <img src={hireBanner} alt="" />
                    <a href="/">
                        <button><i class="fa fa-phone" aria-hidden="true"></i>
                            Hire now</button>
                    </a>
                </div>
            </div>
        </div>
    )
}