import React, { Component } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import SocialLogin from '../components/SocialLogin'
import swal from 'sweetalert';




export default class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phn_cc: "+971",
            number: "",
            modelName: 'Fashion Designer',
            isChecked: false
        }
    }





    SignUpApi = () => {

        try {
            var data = {
                // "mobile_cc":this.state.mobilecode,
                // "mobile":this.state.phoneNumber,
                // // "user_type":this.state.shoptype,
                // "community_name":this.state.shoptype=="Community"? this.state.subusertype:""
                "mobile_cc": this.state.phn_cc,
                "mobile": this.state.mobile,
                // "user_type":this.state.shoptype,
                "community_name": this.state.modelName
            }
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "text/plain");
            var requestOptions = {
                method: 'POST',
                body: JSON.stringify(data),
                redirect: 'follow'
            };
            console.log("data==>", data)


            fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/signup.php", requestOptions)
                .then(async resp => {
                    const json = await resp.json()
                    console.log("data==>", json)

                    if (resp.status == 200) {
                        console.log("data_200>>>>>", resp)
                        swal({
                            title: "Alert",
                            text: "Registration request sent successfully",
                            icon: "success",
                            button: "Ok",
                          })
                        // alert("Registration request sent successfully!")
                        // this.props.history.push('/')
                    }
                    else {
                        swal({
                            title: "Alert",
                            text: "Something went wrong!",
                            icon: "error",
                            button: "Ok",
                          })
                        // alert("Something went wrong!")
                    }

                })
        }
        catch (error) {
            console.log("Signupdata_try_catch====>", error);
        }
    }

    onChangeSort = (event) => {
        console.log("onChangeSort==>", event.target.value)
        this.setState({ phn_cc: event.target.value })
    }

    onChangeModel = (event) => {
        console.log("onChangeSort==>", event.target.value)
        this.setState({ modelName: event.target.value })
    }



    validation = () => {
        if (this.state.isChecked) {
            this.SignUpApi()
        }
        else {
            alert("Please confirm terms and conditions")
        }
    }



    render() {
        return (
            <div>
                <Navbar />
                <section className="pt-5 pb-5 cart_page login_page_bg">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-9 m-auto">


                                <div className="login_signup_page">

                                    <h2>Create An Account</h2>


                                    <div className="row align-items-center">
                                        {/* <div className="col-md-6">
                                        <SocialLogin login="Why be old fashioned? Just signup with:" />
                                    </div> */}
                                        <div className="col-md-12">
                                            <div className="login_signup_form">
                                                <div className="row">
                                                    <div className="col-4 pr-0">
                                                        <select name="phone_cc" id="phone_cc" placeholder="Country Code" required
                                                            onChange={(event) => this.onChangeSort(event)}
                                                            // required=""
                                                            >
                                                            <option selected="" value="+971">UAE (+971)</option>
                                                            <option value="+968">OM (+968)</option>
                                                            <option value="+91">IN (+91)</option>
                                                            <option value="+974">QA (+974)</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-8">
                                                        <input type="number" placeholder="Mobile Number" maxlength="11" onChange={(text) => this.setState({ mobile: text.target.value })} />
                                                    </div>
                                                    <div className="col-12">
                                                        <select name="user_type" id="user_type" placeholder="\f007 Select User Type"
                                                            onChange={(event) => this.onChangeModel(event)}
                                                            required="">

                                                            <option selected="" value="Fashion Designer">Fashion Designer</option>
                                                            <option value="Model">Model</option>
                                                            <option value="Influencer">Influencer</option>
                                                            <option value="Photographer">Photographer</option>
                                                            <option value="Makeup Artist">Makeup Artist</option>

                                                        </select>
                                                        {/* <input type="text" placeholder="Last Name" /> */}
                                                    </div>
                                                    {/* <div className="col-12">
                                                    <input type="email" placeholder="Email" />
                                                </div>
                                                <div className="col-12">
                                                    <input type="password" placeholder="Password" />
                                                </div> */}
                                                    <div className="col-12">
                                                        {/* <input type="password" placeholder="Confirm Password" /> */}
                                                        <div className='signup_display_flex'>
                                                            <input type="checkbox"
                                                                checked={this.state.isChecked}
                                                                onChange={() => this.setState({ isChecked: !this.state.isChecked })}

                                                            />
                                                            <label for="">By creating account you are agree to our <a href="/">Terms &amp; Conditions</a> </label>
                                                        </div>

                                                    </div>
                                                    <div className="col-12">
                                                        <button onClick={() => this.validation()} type="submit">Request For Account</button>
                                                        <p>Already a user? <a href="/login">Login</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </section>
                <Footer />

            </div>
        )
    }
}