import React, { Component } from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
    
export default class Checkout extends Component {
constructor(props){
    super(props);
    this.state={
        productData:[]
    }
}

componentDidMount=async()=>{
    // console.log("data_params==>",this.props.navigation.state.params.userData)
    // paramStatus =  
    
    
            this.ProductList()
            }
            




            ProductList = () => {

                try {
        
        
                    var data = {
                        "all": "all",
                        "search_text":""
                    }
                    var myHeaders = new Headers();
                    myHeaders.append("Content-Type", "text/plain");
                    var requestOptions = {
                        method: 'POST',
                        body: JSON.stringify(data),
                        redirect: 'follow'
                    };
        
        
                    fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/home_product_lists.php", requestOptions)
                        .then(async resp => {
                            const json = await resp.json()
                            console.log("data==>", json)
        
        
                            console.log("product_listing>>>>>", json)
                            if (resp.status == 200) {
                                console.log("data_SignUpApi====>", JSON.stringify(resp))
                                this.setState({
                                    productData: json[2].featured_brands, isLoading: false
                                })
        
                            }
                            else {
                                this.setState({ isLoading: false }, () => alert("Invalid credentials please try again."))
                            }
                        })
                }
                catch (error) {
                    console.log("product_listing_catch====>", error);
                }
            }

            viewBrand=(type,i)=>{
                console.log("data-view=>",type, i)
                if (type == "brand") {
                    localStorage.setItem("viewAll", JSON.stringify(i))
                    this.props.history.push('/Listing' + `/${i.brand}`)
                }
            }
render(){
    return (
        <div>
            <Navbar />
            <section class="shop_categories_copy pt-4 pb-4">

                <div className="container">
                    <div className="row">
                        <div col-12 mb-3>
                            <div class="section_title_copy">
                                <h3>Featured Brand <span></span></h3>
                            </div>

                        </div>
                        {
                           this.state.productData.map((i)=>{
                               return(
                                   <div onClick={() => this.viewBrand("brand",i)}  class="col-lg-4 col-md-4 col-sm-6 col-6">
                                       <div className="home_brands_box_copy">
                                           <img src={`https://demo.smileitsolutions.com/kaaf/uploads/${i.logo}`} alt="" />
                                           {/* <h3> <a href="/"> {i.name} </a></h3> */}
                                       </div>
                                   </div>
                               )
                           }) }
                  
                    </div>

                </div>
            </section>
            <Footer />
        </div>
    )
}
   
}
