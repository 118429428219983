import React, { Component } from 'react'
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { categorySampleOne, categorySampleTwo } from '../components/images'
import { Link } from 'react-router-dom';

export default class ViewCategory extends Component {
    constructor(props){
        super(props);
        this.state={

            productData: [
         

            ]
        }
    }

    componentDidMount=async()=>{
        // console.log("data_params==>",this.props.navigation.state.params.userData)
        // paramStatus =  
        
        
                this.ProductList()
                }
                




                ProductList = () => {

                    try {
            
            
                        var data = {
                            "all": "all",
                            "search_text":""
                        }
                        var myHeaders = new Headers();
                        myHeaders.append("Content-Type", "text/plain");
                        var requestOptions = {
                            method: 'POST',
                            body: JSON.stringify(data),
                            redirect: 'follow'
                        };
            
            
                        fetch("https://demo.smileitsolutions.com/kaaf/api/outlet/home_product_lists.php", requestOptions)
                            .then(async resp => {
                                const json = await resp.json()
                                console.log("data==>", json)
            
            
                                console.log("product_listing>>>>>", json)
                                if (resp.status == 200) {
                                    console.log("data_SignUpApi====>", JSON.stringify(resp))
                                    this.setState({
                                        productData: json[3].categories, isLoading: false
                                    })
            
                                }
                                else {
                                    this.setState({ isLoading: false }, () => alert("Invalid credentials please try again."))
                                }
                            })
                    }
                    catch (error) {
                        console.log("product_listing_catch====>", error);
                    }
                }






moveSub=(i)=>{
console.log("data_sub",i.subCat)
if(i.subCat.length>0){
console.log("dataHere")




}
else{
    console.log("datanothere")
}
}




    render() {
        return (
            <div>
                <Navbar />

                <section className='pt-5 pb-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='subcategory_title'>
                                    <span>Categories</span>
                                    {/* <i class="fa fa-arrow-circle-right" aria-hidden="true"></i> */}
                                    {/* <span>Apparels</span> */}
                                </div>
                            </div>
{
this.state.productData.map((i)=>{
return(<div className='col-12'>
{/* <div className='subcategory_main_page'   */}
{/* // onClick={()=>this.moveSub(i)}
  > */}
<Link  className='subcategory_main_page'  
  to={{
    pathname: "/viewSubcategory",
    data: i // your data array of objects
  }}
>
     <img src={`https://demo.smileitsolutions.com/kaaf/${i.image}`} alt='' />
    <div class="sub_img_bg_dark"></div>
    <h3>{i.name}</h3>
</Link>
  
   
{/* </div> */}
</div>)
})


}

                        

                            {/* <div className='col-12'>
                                <div className='subcategory_main_page'>
                                    <img src={categorySampleTwo} alt='' />
                                    <div class="sub_img_bg_dark"></div>
                                    <h3>Abaya</h3>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='subcategory_main_page'>
                                    <img src={categorySampleOne} alt='' />
                                    <div class="sub_img_bg_dark"></div>
                                    <h3>Abaya</h3>
                                </div>
                            </div>
                            <div className='col-12'>
                                <div className='subcategory_main_page'>
                                    <img src={categorySampleTwo} alt='' />
                                    <div class="sub_img_bg_dark"></div>
                                    <h3>Abaya</h3>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>

                <Footer />
            </div>
        )
    }

}